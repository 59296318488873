@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
.HeaderContactInfo button {
    display: none;
}

.HeaderContactInfo:hover span {
    display: none;
}

.HeaderContactInfo:hover button {
    display: block;
}

/* for Keyword search Div */
.KeySearchDivContent img {
    width: 50px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
    height: 30px;
    object-fit: contain;
}

/* for Keyword search Div */
.KeySearchDivContent {
    padding: 5px 10px;
    background: #fff;
    /* border: 1px solid #f5f5f5; */
}

.KeySearchDivContent b {
    font-weight: 500;
    color: #0f0f0f;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    height: auto;
}

.KeySearchDivContent:hover {
    background: #f5f5f5;
}

.SearchAnything {
    position: relative;
}

.SearchAnythingResult {
    display: none;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 40px;
    z-index: 999999;
    /* max-height: 250px; */
    overflow: auto;
    overflow-x: hidden;
    background: #fff;
    border: 1px solid #c6aad2;
}

@media (max-width: 767px) {
    .SearchAnythingResult {
        border: 0px solid #c6aad2;
        top: 50px;
    }
}

.SearchAnything:hover .SearchAnythingResult {
    display: block;
}

.noksres:empty {
    display: none !important;
}

.SearchAnythingResult:empty {
    display: none !important;
}

.SearchAnythingMobile {
    position: relative;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999999;
    right: 0px;
    bottom: 0px;
    background: #fff;
    padding: 10px;
}

.categoryNanaCont {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 999999;
}

.showOnHover:hover {
    display: block;
}

.showOnHover {
    display: none;
}

.categoryNanaCont .container-fluid .cmc_cont {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(245, 245, 245);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    /* height: max-content; */
}

.categoryNanaCont .appie-single-service {
    position: absolute;
    bottom: 10px;
    left: 0px;
    right: 0px;
    padding: 10px;
}

.categoryNanaCont .appie-single-service a {
    padding: 5px;
    font-size: 15px;
}

.categoryNanaCont .MainDivCont {
    position: relative;
}

.cmc_linkcat {
    font-size: 16px;
    font-weight: 400;
    display: block;
    border-bottom: 1px solid #f5f5f5;
    padding: 7px 10px;
    color: #000;
}

.cmc_linkcat:hover,
.cmc_linkcat_active {
    /* background: #f1e9f7; */
    color: #7f13ab;
}

.cmc_linkcat .pull-right {
    float: right;
}

.ProfileMenuCont {
    display: inline;
    position: relative;
}

.ProfileMenuCont .ProfileMenu {
    display: none;
    position: absolute;
    right: 0px;
    top: 20px;
    background: #fff;
    z-index: 999999;
    border: 1px solid #f5f5f5;
    min-width: 180px;
    padding: 10px;
    word-break: break-all;
}

.ProfileMenuCont a {
    font-size: 14px !important;
    display: block;
    margin-bottom: 10px;
    font-size: 14px !important;
    display: block;
    margin-bottom: 10px;
    font-weight: 700;
    color: #7a3694;
}

.ProfileMenuCont img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    object-fit: cover;
    border-radius: 50%;
    margin-top: -5px;
}

.ProfileMenuCont:hover .ProfileMenu {
    display: block;
}

.btn-docsta-institute {
    color: #fff !important;
    background: #7a3694;
    padding: 5px 10px;
    border-radius: 50px;
    border-bottom: 1px solid #000 !important;
}

.btn-docsta-institute {
    color: #f5f5f5 !important;
}

/* .stickRYMenu .navSection-sjain {
    display: none;
} */

.stickRYMenu .headSection {
    display: none;
}

.showLangOptCont {
    position: relative;
}

.showLangOptCont:hover .showLangOpt {
    display: block;
}

.showLangOpt {
    display: none;
    position: absolute;
    top: 30px;
    z-index: 2147483647;
    background: rgb(255, 255, 255);
    padding: 5px;
    min-width: 100px;
    border: 1px solid rgb(204, 204, 204);
    text-align: center;
    right: -45px;
}
.ryPopUpBack {
    position: fixed;
    top: 0;
    z-index: 9999988;
    background: #0000009e;
    left: 0;
    right: 0;
    bottom: 0;
}
.ryPopUp {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999999;
    background: #fff;
    left: 0;
    right: 0;
    padding: 20px;
    margin: auto;
    max-width: -webkit-max-content;
    max-width: max-content;
    max-height: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: auto;
}
.ryPopUpAct {
    background: #333;
    color: #f5f5f5;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    float: right;
    margin-bottom: 30px;
}
.ryuploadAct:hover {
    background: #333;
    color: #fff;
}
.ryuploadAct {
    border: 1px solid #f5f5f5;
    display: block;
    padding: 10px;
    background: #7f7f7f;
    color: #f5f5f5;
    text-align: center;
}
.crearboth {
    clear: both;
}
.file_inp_cont {
    border: 1px dashed #4f6a97;
    padding: 30px;
    margin: 30px auto;
}

@media (max-width: 1023px) {
    .file_inp_cont {
        border: 0px dashed #000;
        padding: 10px;
        margin: 10px auto;
    }
}

.file_inp_cont .ryfile {
    width: 100%; border: 1px solid #f5f5f5; padding: 10px; margin-bottom: 20px;
}

.whatsapp_us {
    position: fixed;
    left: 0px;
    bottom: 0px;
    z-index: 999999;
    margin-left: 30px;
    max-width: 70px;
    margin-bottom: 30px;
    -webkit-animation: bounce2 3s ease infinite;
            animation: bounce2 3s ease infinite;
}

@-webkit-keyframes bounce2 {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
                transform: translateY(-15px);
    }

    80% {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
    }
}

@keyframes bounce2 {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
                transform: translateY(-15px);
    }

    80% {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
    }
}

.call_us {
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 999999;
    margin-right: 30px;
    max-width: 70px;
    margin-bottom: 30px;
    -webkit-animation: shake 4s ease infinite;
            animation: shake 4s ease infinite;
}

@-webkit-keyframes shake {

    0%,
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
}

@keyframes shake {

    0%,
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
}

@media only screen and (max-width: 600px) {
    .whatsapp_us {
        margin-left: 10px;
        max-width: 44px;
        margin-bottom: 10px;
    }

    .call_us {
        margin-left: 10px;
        max-width: 44px;
        margin-bottom: 10px;
    }
}
.blog_cati_cont {
    border: 1px solid #f5f5f5;
    border-radius: 50px;
    padding: 20px 10px; 
    display: flex;
    text-align: left;
    margin-bottom: 20px;
    padding-left: 30px;
    color: #000;
    font-weight: 600;
    align-items: center;
}
.blog_cati_cont img {
    width: 56px;
    margin-right: 20px;
    object-fit: contain;
    height: 50px;
}
.tableRY td {
    border: 1px solid #f5f5f5;
}
.imagePopUP {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 999997;
    bottom: 100px;
    left: 0px;
    right: 0px;
    object-fit: contain;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.imagePopUPSlider {
    width: 100%;
    position: fixed;
    z-index: 999999;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 0 30px;
    background: grey;
}
.imagePopUPSlider img {
    height: 100px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}
.imagePopUPBox {
    background: #000;
    position: fixed;
    top: 0px;
    z-index: 9999966;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
@media (max-width: 767px) {
    .imagePopUPSlider img {
        height: 50px;
    }
}
.imagePopUPClose {
    position: fixed;
    z-index: 999999;
    color: #fff;
    right: 10px;
    cursor: pointer;
}
.imagePopUPIndex {
    position: fixed;
    z-index: 999999;
    color: #fff;
    left: 10px;
}
.LoginSocialButtons {
    clear: both;
}
.LoginSocialButtons iframe {
    margin: 0px auto !important;
    width: 240px !important;
}
.GoogleLoginBtn, .FacebookLoginBtn, .MobileLoginBtn {
    clear: both;
    text-align: center;
}
.GoogleLoginBtn .cont {
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: 0px auto;
    left: 0;
    right: 0px;
}
.FacebookLoginBtn button, .GoogleLoginBtn button, .MobileLoginBtn button {
    width: 220px;
    cursor: pointer;
    max-width: 100%;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    padding: 10px;
    border: 1px solid #dedede;
    background: transparent;
}
.FacebookLoginBtn img, .GoogleLoginBtn img {
    max-width: 20px;
    margin-right: 10px;
}

.MobileLoginBtn i {
    margin-left: 5px;
    width: 20px;
    margin-right: 5px;
}
.CLB_course_title {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 3em;
}

.CNIMG {
    width: 50px;
    height: 30px;
    object-fit: contain;
}

.cmc_linkcat .CLB_course_title_small {
    /* height: 30px; */
    font-weight: 500;
    color: #000;
}

.cmc_linkcat:hover .CLB_course_title_small {
    color: #0b1125;
}

/* .NavigationActive {
    font-weight: 700 !important;
    color: #0b1125 !important;
    border-bottom: 1px solid #0b1125;
} */
.NVCatiLi span {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    height: 20px;
    width: 20px;
    position: absolute;
    top: 40px;
    background: #fff;
    left: 50%;
}

.CLB_course_title_small {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    color: #333;
}

.CLB_course_description {
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 5em;
}

.contentBox:hover .CLB_course_description {
    display: -webkit-box !important;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 7em;
}

.courseimgsj {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    background: #fff;
}

@media (max-width: 767px) {
    .courseimgsj {
        border: 1px solid #dedede;
        border-radius: 0px;
        background: #f5f5f5;
    }
}

.textCrsDes {
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    /* font-weight: 400;
    font-size: 14px; */
}

.dot {
    height: 4px;
    width: 4px;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #e4a6fb;
    border-radius: 50%;
    display: inline-block;
}

.strike-text {
    color: red;
    text-decoration: line-through;
}

.ListCourse {
    border: 1px solid #dedede;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.ListCourse .contentBox {
    padding: 10px !important;
}

.ListCourse:hover .contentBox,
.ListCourse:hover .imgBox {
    opacity: 1;
}

/* @media (min-width: 767px) {
    .ListCourse:hover .contentBox {
        opacity: 0;
    } 
} */
.ListCourse .backContent {
    background: #fff;
    right: 0px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    position: absolute;
    padding: 10px;
    transition: all 0.6s ease-in-out;
    opacity: 0;
    overflow: auto;
    color: #f5f5f5;
    background: #000000db;
}

.ListCourse .backContent h6 {
    color: #fff;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 20px;
}

.ListCourse .backContent .textBox small {
    margin-bottom: 10px;
    display: block;
}

.ListCourse:hover .backContent {
    opacity: 1;
}

.ListCourse .shopBox {
    display: flex;
    justify-content: space-around;
    /* justify-content: space-between;
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px; */
}

@media (max-width: 767px) {
    .ListCourse .shopBox {
        display: flex;
        position: absolute;
        bottom: 5px;
        left: 5px;
        right: 5px;
    }

    .ListCourse .shopBox .main-btn {
        font-size: 10px;
        padding: 0px 5px
    }
}

.ListCourse:hover .main-btn {
    padding: 5px 20px;
    line-height: 15px;
}

.ListCourse .main-btn:hover {
    /* color: #fff;
    border: 1px solid #fff; */
    /* color: #b087c0;
    border: 1px solid #b087c0; */
    background-color: #7f13ab;
    border: 1px solid #7f13ab;
    color: #f9f9f9;
}

.HomeCourseSliderHere {
    margin-bottom: 15px;
}

.HomeCourseSliderHere .slick-dots {
    bottom: -16px;
}

.HomeCourseSliderHere .slick-dots li {
    width: auto;
    height: auto;
    margin: 0 3px;
}

.HomeCourseSliderHere .slick-dots li.slick-active button {
    background: #0b1125;
}

.HomeCourseSliderHere .slick-dots li button {
    border: 1px solid #0b1125;
    border-radius: 50%;
    width: auto;
    height: auto;
    padding: 2px;
}

.HomeCourseSliderHere .slick-dots li button:before {
    line-height: 7px;
    height: 7px;
    width: 7px;
}

.HomeCourseCategorySlidersafsafa .slick-slide {
    width: -webkit-max-content !important;
    width: max-content !important;
    padding: 0px 15px;
}

.courseIncludes {
    margin-bottom: 10px;
    font-weight: 500;
    color: #2f0043;
    font-size: 15px;
}

.courseIncludes img {
    height: 25px;
    margin-right: 9px;
}

.curpoint {
    cursor: pointer;
}
.rentalDiv {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}
@media (min-width: 769px) {
    .rentalDiv {
        border-right: 1px solid #f9c800;
    }
    .rentalDiv:last-child {
        border-right: 0px solid #f9c800;
    }
}
.rentalDiv:hover, .rentalDiv.active {
    /* border: 1px solid #f9c800; */
    background: #f9f9f9;
}
.rentalDiv:hover {
    background-color: rgba(254, 204, 6,0.2);
}
.join_desc {
    text-align: left;
    padding: 20px;
    margin-top: -45px;
    padding-top: 50px;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    border-radius: 5px;
    min-height: 15rem;
}

.ry_instr_shadow {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 10px;
}

.btn-docsta-instructor {
    background: #7F13AB 0% 0% no-repeat padding-box !important;
    color: #f5f5f5 !important;
    display: block !important;
    width: 100%;
}

.ry_label {
    margin-bottom: 0px;
    font-size: 14px;
}

.ry_instr_menu i {
    padding-right: 5px;
}

.ry_instr_menu {
    padding: 7px 20px;
    display: block;
    color: #000000;
}

.ry_instr_menu:hover {
    color: #7F13AB;
    background: #f5f5f5;
}
.ry_instr_menu.active {
    color: #7F13AB;
    background: #f5f5f5;
}

.ry-pull-left {
    float: left;
}

.ry-pull-right {
    float: right;
}

.ry-table-header {
    background: #0C0F50 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}

.instr_tot_cours {
    background: #A34CC9 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 10px #7f13ab;
}

.instr_tot_cours_updt {
    background: #a8d8f2 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 10px #a8d8f2;
}

.instr_tot_cours_updt .icn {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: #11537e;
}

.instr_tot_std {
    background: #aaf7cb 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 10px #aaf7cb;
}

.icn2 {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #2a7d4d;
}

.instr_tot_webi {
    background: #d6b5ef 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 10px #d6b5ef;
}

.icn3 {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #5d2893;
}

.instr_tot_blog {
    background: #ffe4b2 0% 0% no-repeat padding-box;
    /* border-radius: 18px; */
    text-align: center;
    color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 10px #ffe4b2;
}

.icn4 {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: #8b6d29;
}

/*  student dashboard courses */

.student_dashCard{
    display: flex;
    justify-content: space-around;
}

.stu_dash_course .stu_dash_card {
    border-radius: 5px;
    box-shadow: 0px  1px 2px black;
    /* margin: 4px; */
}

.ry_instr_text {
    color: #7f13ab;
}

div.PhoneInputCountry {
    padding-top: 5px;
}

input.PhoneInputInput {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: 0px;
    width: 80%;
    z-index: 888;
}

.instr_signup_head {
    background: #F4F7FF 0% 0% no-repeat padding-box;
    border: 0.5px solid #f5f5f5;
    border-radius: 20px 20px 0px 0px;
}

.instr_signup_head .col-md-4,
.instr_signup_head .col-md-3 {
    position: relative;
}

.instr_signup_head_step {
    border: 1px solid #4C2CD4;
    border-radius: 50%;
    margin: 20px auto;
    width: 70px;
    height: 70px;
    line-height: 65px;
    text-align: center;
}

.instr_signup_head .active {
    background: #4C2CD4 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}

.instr_signup_head .col-md-4::before {
    content: "";
    position: absolute;
    background: #dddddd;
    top: 50px;
    right: -33%;
    width: 66%;
    height: 2px;
}

.instr_signup_head .col-md-4:last-child::before {
    display: none;
}

.instr_signup_head .col-md-3::before {
    content: "";
    position: absolute;
    background: #dddddd;
    top: 50px;
    right: -33%;
    width: 66%;
    height: 2px;
}

.instr_signup_head .col-md-3:last-child::before {
    display: none;
}

.instr_signup_head .col-md-2::before {
    content: "";
    position: absolute;
    background: #dddddd;
    top: 50px;
    right: -33%;
    width: 66%;
    height: 2px;
}

.instr_signup_head .col-md-2:last-child::before {
    display: none;
}

.step_1_img {
    max-width: 250px;
    display: block;
    margin: 0px auto;
    margin-top: 210px;
}

.step_2_img {
    max-width: 250px;
    display: block;
    margin: 0px auto;
    margin-top: 170px;
}

.step_3_img {
    max-width: 250px;
    display: block;
    margin: 0px auto;
    margin-top: 160px;
}

.instr_profile_pics {
    float: right;
    height: 90px;
    width: 90px;
    max-width: 50%;
    object-fit: cover;
    border-radius: 50%;
}

@media only screen and (min-width: 710px) {
    .instr_payput_type {
        display: flex;
        justify-content: space-between;
    }
}

.instr_payput_type button {
    margin-bottom: 10px;
}

.clear_both {
    clear: both;
}

.instr_webi_help {
    place-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
}

.instr_webi_help .i2 {
    margin-left: 5px;
    height: 25px;
}

.instr_webi_help .i1 {
    margin-left: 5px;
    left: 1395px;
    width: 20px;
    height: 20px;
    background: #4C2CD4 0% 0% no-repeat padding-box;
    border-radius: 50%;
    color: #fff;
    line-height: 20px;
    text-align: center;
}

.std_header_on_page {
    color: #7F13AB;
}
.std_db_counted b.text-dark {
    font-size: 12px;
}

.std_db_counted i {
    font-size: 18px;
}
.CPPrdPrice { float: right; }
.clearBoth { clear: both; }
.CPPrdPriceMobile { display: none; }
@media (max-width: 767px) {
    .CPPrdPrice { display: none !important; }
    .CPPrdPriceMobile { display: block; }
}
@-webkit-keyframes jump {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40% {
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes jump {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40% {
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes rotated {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotated {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotatedHalf {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    50% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

@keyframes rotatedHalf {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    50% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

@-webkit-keyframes rotatedHalfTwo {
    0% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@keyframes rotatedHalfTwo {
    0% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}


@-webkit-keyframes scale-upOne {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
    }
}

@keyframes scale-upOne {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
    }
}

@-webkit-keyframes scale-right {
    0% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    50% {
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
    }

    100% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

@keyframes scale-right {
    0% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    50% {
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
    }

    100% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0.7;
    }

    40% {
        opacity: 1;
    }

    100% {
        opacity: 0.7;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0.7;
    }

    40% {
        opacity: 1;
    }

    100% {
        opacity: 0.7;
    }
}


@-webkit-keyframes hvr-ripple-out {
    0% {
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 1;
    }

    100% {
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
        opacity: 0;
    }
}


@keyframes hvr-ripple-out {
    0% {
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 1;
    }

    100% {
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
        opacity: 0;
    }
}

@-webkit-keyframes hvr-ripple-out-two {
    0% {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
    }

    100% {
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        opacity: 0;
    }
}

@keyframes hvr-ripple-out-two {
    0% {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
    }

    100% {
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        opacity: 0;
    }
}

@-webkit-keyframes scale-up-one {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-one {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes scale-up-two {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    40% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
}

@keyframes scale-up-two {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    40% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
}

@-webkit-keyframes scale-up-three {
    0% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }

    40% {
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
    }

    100% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
}

@keyframes scale-up-three {
    0% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }

    40% {
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
    }

    100% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
}


@keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
                transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
                transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
                transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
                transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
                transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
                transform: translate(0px, 0px) rotate(0deg);
    }
}


@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes animationFramesTwo {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
                transform: translate(0px, 0px) rotate(0deg) scale(1);
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
                transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
                transform: translate(141px, 72px) rotate(72deg) scale(1);
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
                transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
                transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
                transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}


@-webkit-keyframes animationFramesTwo {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}


@keyframes animationFramesThree {
    0% {
        -webkit-transform: translate(165px, -179px);
                transform: translate(165px, -179px);
    }

    100% {
        -webkit-transform: translate(-346px, 617px);
                transform: translate(-346px, 617px);
    }
}


@-webkit-keyframes animationFramesThree {
    0% {
        -webkit-transform: translate(165px, -179px);
    }

    100% {
        -webkit-transform: translate(-346px, 617px);
    }
}


@keyframes animationFramesFour {
    0% {
        -webkit-transform: translate(-300px, 151px) rotate(0deg);
                transform: translate(-300px, 151px) rotate(0deg);
    }

    100% {
        -webkit-transform: translate(251px, -200px) rotate(180deg);
                transform: translate(251px, -200px) rotate(180deg);
    }
}


@-webkit-keyframes animationFramesFour {
    0% {
        -webkit-transform: translate(-300px, 151px) rotate(0deg);
    }

    100% {
        -webkit-transform: translate(251px, -200px) rotate(180deg);
    }
}


@keyframes animationFramesFive {
    0% {
        -webkit-transform: translate(61px, -99px) rotate(0deg);
                transform: translate(61px, -99px) rotate(0deg);
    }

    21% {
        -webkit-transform: translate(4px, -190px) rotate(38deg);
                transform: translate(4px, -190px) rotate(38deg);
    }

    41% {
        -webkit-transform: translate(-139px, -200px) rotate(74deg);
                transform: translate(-139px, -200px) rotate(74deg);
    }

    60% {
        -webkit-transform: translate(-263px, -164px) rotate(108deg);
                transform: translate(-263px, -164px) rotate(108deg);
    }

    80% {
        -webkit-transform: translate(-195px, -49px) rotate(144deg);
                transform: translate(-195px, -49px) rotate(144deg);
    }

    100% {
        -webkit-transform: translate(-1px, 0px) rotate(180deg);
                transform: translate(-1px, 0px) rotate(180deg);
    }
}


@-webkit-keyframes animationFramesFive {
    0% {
        -webkit-transform: translate(61px, -99px) rotate(0deg);
    }

    21% {
        -webkit-transform: translate(4px, -190px) rotate(38deg);
    }

    41% {
        -webkit-transform: translate(-139px, -200px) rotate(74deg);
    }

    60% {
        -webkit-transform: translate(-263px, -164px) rotate(108deg);
    }

    80% {
        -webkit-transform: translate(-195px, -49px) rotate(144deg);
    }

    100% {
        -webkit-transform: translate(-1px, 0px) rotate(180deg);
    }
}

@keyframes gradientBG {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 300% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@-webkit-keyframes gradientBG {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 300% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@-webkit-keyframes imageBgAnim {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 120% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@keyframes imageBgAnim {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 120% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@-webkit-keyframes gradientBG {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 120% 0;
    }

    100% {
        background-position: 0 0;
    }
}
/* Deafult Margin & Padding */
/*-- Margin Top --*/
a {
	text-decoration: none;
	background-color: transparent;
}

.mt-5 {
	margin-top: 5px;
}

.mt-10 {
	margin-top: 10px !important;
}

.mt-15 {
	margin-top: 15px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mt-25 {
	margin-top: 25px !important;
}

.mt-30 {
	margin-top: 30px !important;
}

.mt-35 {
	margin-top: 35px !important;
}

.mt-40 {
	margin-top: 40px !important;
}

.mt-45 {
	margin-top: 45px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.mt-55 {
	margin-top: 55px !important;
}

.mt-60 {
	margin-top: 60px !important;
}

.mt-65 {
	margin-top: 65px !important;
}

.mt-70 {
	margin-top: 70px !important;
}

.mt-75 {
	margin-top: 75px !important;
}

.mt-80 {
	margin-top: 80px !important;
}

.mt-85 {
	margin-top: 85px !important;
}

.mt-90 {
	margin-top: 90px !important;
}

.mt-95 {
	margin-top: 95px !important;
}

.mt-100 {
	margin-top: 100px !important;
}

.mt-105 {
	margin-top: 105px !important;
}

.mt-110 {
	margin-top: 110px !important;
}

.mt-115 {
	margin-top: 115px !important;
}

.mt-120 {
	margin-top: 120px !important;
}

.mt-125 {
	margin-top: 125px !important;
}

.mt-130 {
	margin-top: 130px !important;
}

.mt-135 {
	margin-top: 135px !important;
}

.mt-140 {
	margin-top: 140px !important;
}

.mt-145 {
	margin-top: 145px !important;
}

.mt-150 {
	margin-top: 150px !important;
}

.mt-155 {
	margin-top: 155px !important;
}

.mt-160 {
	margin-top: 160px !important;
}

.mt-165 {
	margin-top: 165px !important;
}

.mt-170 {
	margin-top: 170px !important;
}

.mt-175 {
	margin-top: 175px !important;
}

.mt-180 {
	margin-top: 180px !important;
}

.mt-185 {
	margin-top: 185px !important;
}

.mt-190 {
	margin-top: 190px !important;
}

.mt-195 {
	margin-top: 195px !important;
}

.mt-200 {
	margin-top: 200px !important;
}

/*-- Margin Bottom --*/

.mb-5 {
	margin-bottom: 5px;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-15 {
	margin-bottom: 15px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-25 {
	margin-bottom: 25px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-35 {
	margin-bottom: 35px !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mb-45 {
	margin-bottom: 45px !important;
}

.mb-50 {
	margin-bottom: 50px !important;
}

.mb-55 {
	margin-bottom: 55px !important;
}

.mb-60 {
	margin-bottom: 60px !important;
}

.mb-65 {
	margin-bottom: 65px !important;
}

.mb-70 {
	margin-bottom: 70px !important;
}

.mb-75 {
	margin-bottom: 75px !important;
}

.mb-80 {
	margin-bottom: 80px !important;
}

.mb-85 {
	margin-bottom: 85px !important;
}

.mb-90 {
	margin-bottom: 90px !important;
}

.mb-95 {
	margin-bottom: 95px !important;
}

.mb-100 {
	margin-bottom: 100px !important;
}

.mb-105 {
	margin-bottom: 105px !important;
}

.mb-110 {
	margin-bottom: 110px !important;
}

.mb-115 {
	margin-bottom: 115px !important;
}

.mb-120 {
	margin-bottom: 120px !important;
}

.mb-125 {
	margin-bottom: 125px !important;
}

.mb-130 {
	margin-bottom: 130px !important;
}

.mb-135 {
	margin-bottom: 135px !important;
}

.mb-140 {
	margin-bottom: 140px !important;
}

.mb-145 {
	margin-bottom: 145px !important;
}

.mb-150 {
	margin-bottom: 150px !important;
}

.mb-155 {
	margin-bottom: 155px !important;
}

.mb-160 {
	margin-bottom: 160px !important;
}

.mb-165 {
	margin-bottom: 165px !important;
}

.mb-170 {
	margin-bottom: 170px !important;
}

.mb-175 {
	margin-bottom: 175px !important;
}

.mb-180 {
	margin-bottom: 180px !important;
}

.mb-185 {
	margin-bottom: 185px !important;
}

.mb-190 {
	margin-bottom: 190px !important;
}

.mb-195 {
	margin-bottom: 195px !important;
}

.mb-200 {
	margin-bottom: 200px !important;
}

/*-- margin left --*/
.ml-5 {
	margin-left: 5px;
}

.ml-10 {
	margin-left: 10px !important;
}

.ml-15 {
	margin-left: 15px !important;
}

.ml-20 {
	margin-left: 20px !important;
}

.ml-25 {
	margin-left: 25px !important;
}

.ml-30 {
	margin-left: 30px !important;
}

.ml-35 {
	margin-left: 35px !important;
}

.ml-40 {
	margin-left: 40px !important;
}

.ml-45 {
	margin-left: 45px !important;
}

.ml-50 {
	margin-left: 50px !important;
}

.ml-55 {
	margin-left: 55px !important;
}

.ml-60 {
	margin-left: 60px !important;
}

.ml-65 {
	margin-left: 65px !important;
}

.ml-70 {
	margin-left: 70px !important;
}

.ml-75 {
	margin-left: 75px !important;
}

.ml-80 {
	margin-left: 80px !important;
}

.ml-85 {
	margin-left: 85px !important;
}

.ml-90 {
	margin-left: 90px !important;
}

.ml-95 {
	margin-left: 95px !important;
}

.ml-100 {
	margin-left: 100px !important;
}

.ml-105 {
	margin-left: 105px !important;
}

.ml-110 {
	margin-left: 110px !important;
}

.ml-115 {
	margin-left: 115px !important;
}

.ml-120 {
	margin-left: 120px !important;
}

.ml-125 {
	margin-left: 125px !important;
}

.ml-130 {
	margin-left: 130px !important;
}

.ml-135 {
	margin-left: 135px !important;
}

.ml-140 {
	margin-left: 140px !important;
}

.ml-145 {
	margin-left: 145px !important;
}

.ml-150 {
	margin-left: 150px !important;
}

.ml-155 {
	margin-left: 155px !important;
}

.ml-160 {
	margin-left: 160px !important;
}

.ml-165 {
	margin-left: 165px !important;
}

.ml-170 {
	margin-left: 170px !important;
}

.ml-175 {
	margin-left: 175px !important;
}

.ml-180 {
	margin-left: 180px !important;
}

.ml-185 {
	margin-left: 185px !important;
}

.ml-190 {
	margin-left: 190px !important;
}

.ml-195 {
	margin-left: 195px !important;
}

.ml-200 {
	margin-left: 200px !important;
}

/*-- margin right --*/
.mr-5 {
	margin-right: 5px;
}

.mr-10 {
	margin-right: 10px !important;
}

.mr-15 {
	margin-right: 15px !important;
}

.mr-20 {
	margin-right: 20px !important;
}

.mr-25 {
	margin-right: 25px !important;
}

.mr-30 {
	margin-right: 30px !important;
}

.mr-35 {
	margin-right: 35px !important;
}

.mr-40 {
	margin-right: 40px !important;
}

.mr-45 {
	margin-right: 45px !important;
}

.mr-50 {
	margin-right: 50px !important;
}

.mr-55 {
	margin-right: 55px !important;
}

.mr-60 {
	margin-right: 60px !important;
}

.mr-65 {
	margin-right: 65px !important;
}

.mr-70 {
	margin-right: 70px !important;
}

.mr-75 {
	margin-right: 75px !important;
}

.mr-80 {
	margin-right: 80px !important;
}

.mr-85 {
	margin-right: 85px !important;
}

.mr-90 {
	margin-right: 90px !important;
}

.mr-95 {
	margin-right: 95px !important;
}

.mr-100 {
	margin-right: 100px !important;
}

.mr-105 {
	margin-right: 105px !important;
}

.mr-110 {
	margin-right: 110px !important;
}

.mr-115 {
	margin-right: 115px !important;
}

.mr-120 {
	margin-right: 120px !important;
}

.mr-125 {
	margin-right: 125px !important;
}

.mr-130 {
	margin-right: 130px !important;
}

.mr-135 {
	margin-right: 135px !important;
}

.mr-140 {
	margin-right: 140px !important;
}

.mr-145 {
	margin-right: 145px !important;
}

.mr-150 {
	margin-right: 150px !important;
}

.mr-155 {
	margin-right: 155px !important;
}

.mr-160 {
	margin-right: 160px !important;
}

.mr-165 {
	margin-right: 165px !important;
}

.mr-170 {
	margin-right: 170px !important;
}

.mr-175 {
	margin-right: 175px !important;
}

.mr-180 {
	margin-right: 180px !important;
}

.mr-185 {
	margin-right: 185px !important;
}

.mr-190 {
	margin-right: 190px !important;
}

.mr-195 {
	margin-right: 195px !important;
}

.mr-200 {
	margin-right: 200px !important;
}


/*-- Padding Top --*/

.pt-5 {
	padding-top: 5px;
}

.pt-10 {
	padding-top: 10px !important;
}

.pt-15 {
	padding-top: 15px !important;
}

.pt-20 {
	padding-top: 20px !important;
}

.pt-25 {
	padding-top: 25px !important;
}

.pt-30 {
	padding-top: 30px !important;
}

.pt-35 {
	padding-top: 35px !important;
}

.pt-40 {
	padding-top: 40px !important;
}

.pt-45 {
	padding-top: 45px !important;
}

.pt-50 {
	padding-top: 50px !important;
}

.pt-55 {
	padding-top: 55px !important;
}

.pt-60 {
	padding-top: 60px !important;
}

.pt-65 {
	padding-top: 65px !important;
}

.pt-70 {
	padding-top: 70px !important;
}

.pt-75 {
	padding-top: 75px !important;
}

.pt-80 {
	padding-top: 80px !important;
}

.pt-85 {
	padding-top: 85px !important;
}

.pt-90 {
	padding-top: 90px !important;
}

.pt-95 {
	padding-top: 95px !important;
}

.pt-100 {
	padding-top: 100px !important;
}

.pt-105 {
	padding-top: 105px !important;
}

.pt-110 {
	padding-top: 110px !important;
}

.pt-115 {
	padding-top: 115px !important;
}

.pt-120 {
	padding-top: 120px !important;
}

.pt-125 {
	padding-top: 125px !important;
}

.pt-130 {
	padding-top: 130px !important;
}

.pt-135 {
	padding-top: 135px !important;
}

.pt-140 {
	padding-top: 140px !important;
}

.pt-145 {
	padding-top: 145px !important;
}

.pt-150 {
	padding-top: 150px !important;
}

.pt-155 {
	padding-top: 155px !important;
}

.pt-160 {
	padding-top: 160px !important;
}

.pt-165 {
	padding-top: 165px !important;
}

.pt-170 {
	padding-top: 170px !important;
}

.pt-175 {
	padding-top: 175px !important;
}

.pt-180 {
	padding-top: 180px !important;
}

.pt-185 {
	padding-top: 185px !important;
}

.pt-190 {
	padding-top: 190px !important;
}

.pt-195 {
	padding-top: 195px !important;
}

.pt-200 {
	padding-top: 200px !important;
}

/*-- Padding Bottom --*/

.pb-5 {
	padding-bottom: 5px;
}

.pb-10 {
	padding-bottom: 10px !important;
}

.pb-15 {
	padding-bottom: 15px !important;
}

.pb-20 {
	padding-bottom: 20px !important;
}

.pb-25 {
	padding-bottom: 25px !important;
}

.pb-30 {
	padding-bottom: 30px !important;
}

.pb-35 {
	padding-bottom: 35px !important;
}

.pb-40 {
	padding-bottom: 40px !important;
}

.pb-45 {
	padding-bottom: 45px !important;
}

.pb-50 {
	padding-bottom: 50px !important;
}

.pb-55 {
	padding-bottom: 55px !important;
}

.pb-60 {
	padding-bottom: 60px !important;
}

.pb-65 {
	padding-bottom: 65px !important;
}

.pb-70 {
	padding-bottom: 70px !important;
}

.pb-75 {
	padding-bottom: 75px !important;
}

.pb-80 {
	padding-bottom: 80px !important;
}

.pb-85 {
	padding-bottom: 85px !important;
}

.pb-90 {
	padding-bottom: 90px !important;
}

.pb-95 {
	padding-bottom: 95px !important;
}

.pb-100 {
	padding-bottom: 100px !important;
}

.pb-105 {
	padding-bottom: 105px !important;
}

.pb-110 {
	padding-bottom: 110px !important;
}

.pb-115 {
	padding-bottom: 115px !important;
}

.pb-120 {
	padding-bottom: 120px !important;
}

.pb-125 {
	padding-bottom: 125px !important;
}

.pb-130 {
	padding-bottom: 130px !important;
}

.pb-135 {
	padding-bottom: 135px !important;
}

.pb-140 {
	padding-bottom: 140px !important;
}

.pb-145 {
	padding-bottom: 145px !important;
}

.pb-150 {
	padding-bottom: 150px !important;
}

.pb-155 {
	padding-bottom: 155px !important;
}

.pb-160 {
	padding-bottom: 160px !important;
}

.pb-165 {
	padding-bottom: 165px !important;
}

.pb-170 {
	padding-bottom: 170px !important;
}

.pb-175 {
	padding-bottom: 175px !important;
}

.pb-180 {
	padding-bottom: 180px !important;
}

.pb-185 {
	padding-bottom: 185px !important;
}

.pb-190 {
	padding-bottom: 190px !important;
}

.pb-195 {
	padding-bottom: 195px !important;
}

.pb-200 {
	padding-bottom: 200px !important;
}

/*-- Padding left --*/

.pl-0 {
	padding-left: 0px !important;
}

.pl-5 {
	padding-left: 5px;
}

.pl-10 {
	padding-left: 10px !important;
}

.pl-15 {
	padding-left: 15px !important;
}

.pl-20 {
	padding-left: 20px !important;
}

.pl-25 {
	padding-left: 25px !important;
}

.pl-30 {
	padding-left: 30px !important;
}

.pl-35 {
	padding-left: 35px !important;
}

.pl-40 {
	padding-left: 40px !important;
}

.pl-45 {
	padding-left: 45px !important;
}

.pl-50 {
	padding-left: 50px !important;
}

.pl-55 {
	padding-left: 55px !important;
}

.pl-60 {
	padding-left: 60px !important;
}

.pl-65 {
	padding-left: 65px !important;
}

.pl-70 {
	padding-left: 70px !important;
}

.pl-75 {
	padding-left: 75px !important;
}

.pl-80 {
	padding-left: 80px !important;
}

.pl-85 {
	padding-left: 85px !important;
}

.pl-90 {
	padding-left: 90px !important;
}

.pl-100 {
	padding-left: 100px !important;
}

.pl-105 {
	padding-left: 105px !important;
}

.pl-110 {
	padding-left: 110px !important;
}

.pl-115 {
	padding-left: 115px !important;
}

.pl-120 {
	padding-left: 120px !important;
}

.pl-125 {
	padding-left: 125px !important;
}

/*-- Padding right --*/

.pr-0 {
	padding-right: 0px !important;
}

.pr-5 {
	padding-right: 5px;
}

.pr-10 {
	padding-right: 10px !important;
}

.pr-15 {
	padding-right: 15px !important;
}

.pr-20 {
	padding-right: 20px !important;
}

.pr-25 {
	padding-right: 25px !important;
}

.pr-30 {
	padding-right: 30px !important;
}

.pr-35 {
	padding-right: 35px !important;
}

.pr-40 {
	padding-right: 40px !important;
}

.pr-45 {
	padding-right: 45px !important;
}

.pr-50 {
	padding-right: 50px !important;
}

.pr-55 {
	padding-right: 55px !important;
}

.pr-60 {
	padding-right: 60px !important;
}

.pr-65 {
	padding-right: 65px !important;
}

.pr-70 {
	padding-right: 70px !important;
}

.pr-75 {
	padding-right: 75px !important;
}

.pr-80 {
	padding-right: 80px !important;
}

.pr-85 {
	padding-right: 85px !important;
}

.pr-90 {
	padding-right: 90px !important;
}

.pr-95 {
	padding-right: 95px !important;
}

.pr-100 {
	padding-right: 100px !important;
}

.pr-105 {
	padding-right: 105px !important;
}

/* Background Color */

.gray-bg {
	background: #f6f6f6;
}

.white-bg {
	background: #fff;
}

.black-bg {
	background: #222;
}

/* Color */

.white {
	color: #fff;
}

.black {
	color: #222;
}

/* black overlay */

[data-overlay] {
	position: relative;
}

[data-overlay]::before {
	background: #000 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
}

[data-overlay="3"]::before {
	opacity: 0.3;
}

[data-overlay="4"]::before {
	opacity: 0.4;
}

[data-overlay="5"]::before {
	opacity: 0.5;
}

[data-overlay="6"]::before {
	opacity: 0.6;
}

[data-overlay="7"]::before {
	opacity: 0.7;
}

[data-overlay="8"]::before {
	opacity: 0.8;
}

[data-overlay="9"]::before {
	opacity: 0.9;
}
.hoverChangeContent:hover {	
	background: #f5f5f5;
}
.hideEmpty:empty {
	display: none !important;
}
.cursorHover {
	cursor: pointer;
}
.pull-right {
	float: right;
}
.clearBoth {
	clear: both;
}
/*!
 * Font Awesome Pro 5.8.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
          filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\F26E"; }

.fa-abacus:before {
  content: "\F640"; }

.fa-accessible-icon:before {
  content: "\F368"; }

.fa-accusoft:before {
  content: "\F369"; }

.fa-acorn:before {
  content: "\F6AE"; }

.fa-acquisitions-incorporated:before {
  content: "\F6AF"; }

.fa-ad:before {
  content: "\F641"; }

.fa-address-book:before {
  content: "\F2B9"; }

.fa-address-card:before {
  content: "\F2BB"; }

.fa-adjust:before {
  content: "\F042"; }

.fa-adn:before {
  content: "\F170"; }

.fa-adobe:before {
  content: "\F778"; }

.fa-adversal:before {
  content: "\F36A"; }

.fa-affiliatetheme:before {
  content: "\F36B"; }

.fa-air-freshener:before {
  content: "\F5D0"; }

.fa-airbnb:before {
  content: "\F834"; }

.fa-alarm-clock:before {
  content: "\F34E"; }

.fa-algolia:before {
  content: "\F36C"; }

.fa-alicorn:before {
  content: "\F6B0"; }

.fa-align-center:before {
  content: "\F037"; }

.fa-align-justify:before {
  content: "\F039"; }

.fa-align-left:before {
  content: "\F036"; }

.fa-align-right:before {
  content: "\F038"; }

.fa-alipay:before {
  content: "\F642"; }

.fa-allergies:before {
  content: "\F461"; }

.fa-amazon:before {
  content: "\F270"; }

.fa-amazon-pay:before {
  content: "\F42C"; }

.fa-ambulance:before {
  content: "\F0F9"; }

.fa-american-sign-language-interpreting:before {
  content: "\F2A3"; }

.fa-amilia:before {
  content: "\F36D"; }

.fa-analytics:before {
  content: "\F643"; }

.fa-anchor:before {
  content: "\F13D"; }

.fa-android:before {
  content: "\F17B"; }

.fa-angel:before {
  content: "\F779"; }

.fa-angellist:before {
  content: "\F209"; }

.fa-angle-double-down:before {
  content: "\F103"; }

.fa-angle-double-left:before {
  content: "\F100"; }

.fa-angle-double-right:before {
  content: "\F101"; }

.fa-angle-double-up:before {
  content: "\F102"; }

.fa-angle-down:before {
  content: "\F107"; }

.fa-angle-left:before {
  content: "\F104"; }

.fa-angle-right:before {
  content: "\F105"; }

.fa-angle-up:before {
  content: "\F106"; }

.fa-angry:before {
  content: "\F556"; }

.fa-angrycreative:before {
  content: "\F36E"; }

.fa-angular:before {
  content: "\F420"; }

.fa-ankh:before {
  content: "\F644"; }

.fa-app-store:before {
  content: "\F36F"; }

.fa-app-store-ios:before {
  content: "\F370"; }

.fa-apper:before {
  content: "\F371"; }

.fa-apple:before {
  content: "\F179"; }

.fa-apple-alt:before {
  content: "\F5D1"; }

.fa-apple-crate:before {
  content: "\F6B1"; }

.fa-apple-pay:before {
  content: "\F415"; }

.fa-archive:before {
  content: "\F187"; }

.fa-archway:before {
  content: "\F557"; }

.fa-arrow-alt-circle-down:before {
  content: "\F358"; }

.fa-arrow-alt-circle-left:before {
  content: "\F359"; }

.fa-arrow-alt-circle-right:before {
  content: "\F35A"; }

.fa-arrow-alt-circle-up:before {
  content: "\F35B"; }

.fa-arrow-alt-down:before {
  content: "\F354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\F346"; }

.fa-arrow-alt-from-left:before {
  content: "\F347"; }

.fa-arrow-alt-from-right:before {
  content: "\F348"; }

.fa-arrow-alt-from-top:before {
  content: "\F349"; }

.fa-arrow-alt-left:before {
  content: "\F355"; }

.fa-arrow-alt-right:before {
  content: "\F356"; }

.fa-arrow-alt-square-down:before {
  content: "\F350"; }

.fa-arrow-alt-square-left:before {
  content: "\F351"; }

.fa-arrow-alt-square-right:before {
  content: "\F352"; }

.fa-arrow-alt-square-up:before {
  content: "\F353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\F34A"; }

.fa-arrow-alt-to-left:before {
  content: "\F34B"; }

.fa-arrow-alt-to-right:before {
  content: "\F34C"; }

.fa-arrow-alt-to-top:before {
  content: "\F34D"; }

.fa-arrow-alt-up:before {
  content: "\F357"; }

.fa-arrow-circle-down:before {
  content: "\F0AB"; }

.fa-arrow-circle-left:before {
  content: "\F0A8"; }

.fa-arrow-circle-right:before {
  content: "\F0A9"; }

.fa-arrow-circle-up:before {
  content: "\F0AA"; }

.fa-arrow-down:before {
  content: "\F063"; }

.fa-arrow-from-bottom:before {
  content: "\F342"; }

.fa-arrow-from-left:before {
  content: "\F343"; }

.fa-arrow-from-right:before {
  content: "\F344"; }

.fa-arrow-from-top:before {
  content: "\F345"; }

.fa-arrow-left:before {
  content: "\F060"; }

.fa-arrow-right:before {
  content: "\F061"; }

.fa-arrow-square-down:before {
  content: "\F339"; }

.fa-arrow-square-left:before {
  content: "\F33A"; }

.fa-arrow-square-right:before {
  content: "\F33B"; }

.fa-arrow-square-up:before {
  content: "\F33C"; }

.fa-arrow-to-bottom:before {
  content: "\F33D"; }

.fa-arrow-to-left:before {
  content: "\F33E"; }

.fa-arrow-to-right:before {
  content: "\F340"; }

.fa-arrow-to-top:before {
  content: "\F341"; }

.fa-arrow-up:before {
  content: "\F062"; }

.fa-arrows:before {
  content: "\F047"; }

.fa-arrows-alt:before {
  content: "\F0B2"; }

.fa-arrows-alt-h:before {
  content: "\F337"; }

.fa-arrows-alt-v:before {
  content: "\F338"; }

.fa-arrows-h:before {
  content: "\F07E"; }

.fa-arrows-v:before {
  content: "\F07D"; }

.fa-artstation:before {
  content: "\F77A"; }

.fa-assistive-listening-systems:before {
  content: "\F2A2"; }

.fa-asterisk:before {
  content: "\F069"; }

.fa-asymmetrik:before {
  content: "\F372"; }

.fa-at:before {
  content: "\F1FA"; }

.fa-atlas:before {
  content: "\F558"; }

.fa-atlassian:before {
  content: "\F77B"; }

.fa-atom:before {
  content: "\F5D2"; }

.fa-atom-alt:before {
  content: "\F5D3"; }

.fa-audible:before {
  content: "\F373"; }

.fa-audio-description:before {
  content: "\F29E"; }

.fa-autoprefixer:before {
  content: "\F41C"; }

.fa-avianex:before {
  content: "\F374"; }

.fa-aviato:before {
  content: "\F421"; }

.fa-award:before {
  content: "\F559"; }

.fa-aws:before {
  content: "\F375"; }

.fa-axe:before {
  content: "\F6B2"; }

.fa-axe-battle:before {
  content: "\F6B3"; }

.fa-baby:before {
  content: "\F77C"; }

.fa-baby-carriage:before {
  content: "\F77D"; }

.fa-backpack:before {
  content: "\F5D4"; }

.fa-backspace:before {
  content: "\F55A"; }

.fa-backward:before {
  content: "\F04A"; }

.fa-bacon:before {
  content: "\F7E5"; }

.fa-badge:before {
  content: "\F335"; }

.fa-badge-check:before {
  content: "\F336"; }

.fa-badge-dollar:before {
  content: "\F645"; }

.fa-badge-percent:before {
  content: "\F646"; }

.fa-badger-honey:before {
  content: "\F6B4"; }

.fa-balance-scale:before {
  content: "\F24E"; }

.fa-balance-scale-left:before {
  content: "\F515"; }

.fa-balance-scale-right:before {
  content: "\F516"; }

.fa-ball-pile:before {
  content: "\F77E"; }

.fa-ballot:before {
  content: "\F732"; }

.fa-ballot-check:before {
  content: "\F733"; }

.fa-ban:before {
  content: "\F05E"; }

.fa-band-aid:before {
  content: "\F462"; }

.fa-bandcamp:before {
  content: "\F2D5"; }

.fa-barcode:before {
  content: "\F02A"; }

.fa-barcode-alt:before {
  content: "\F463"; }

.fa-barcode-read:before {
  content: "\F464"; }

.fa-barcode-scan:before {
  content: "\F465"; }

.fa-bars:before {
  content: "\F0C9"; }

.fa-baseball:before {
  content: "\F432"; }

.fa-baseball-ball:before {
  content: "\F433"; }

.fa-basketball-ball:before {
  content: "\F434"; }

.fa-basketball-hoop:before {
  content: "\F435"; }

.fa-bat:before {
  content: "\F6B5"; }

.fa-bath:before {
  content: "\F2CD"; }

.fa-battery-bolt:before {
  content: "\F376"; }

.fa-battery-empty:before {
  content: "\F244"; }

.fa-battery-full:before {
  content: "\F240"; }

.fa-battery-half:before {
  content: "\F242"; }

.fa-battery-quarter:before {
  content: "\F243"; }

.fa-battery-slash:before {
  content: "\F377"; }

.fa-battery-three-quarters:before {
  content: "\F241"; }

.fa-battle-net:before {
  content: "\F835"; }

.fa-bed:before {
  content: "\F236"; }

.fa-beer:before {
  content: "\F0FC"; }

.fa-behance:before {
  content: "\F1B4"; }

.fa-behance-square:before {
  content: "\F1B5"; }

.fa-bell:before {
  content: "\F0F3"; }

.fa-bell-school:before {
  content: "\F5D5"; }

.fa-bell-school-slash:before {
  content: "\F5D6"; }

.fa-bell-slash:before {
  content: "\F1F6"; }

.fa-bells:before {
  content: "\F77F"; }

.fa-bezier-curve:before {
  content: "\F55B"; }

.fa-bible:before {
  content: "\F647"; }

.fa-bicycle:before {
  content: "\F206"; }

.fa-bimobject:before {
  content: "\F378"; }

.fa-binoculars:before {
  content: "\F1E5"; }

.fa-biohazard:before {
  content: "\F780"; }

.fa-birthday-cake:before {
  content: "\F1FD"; }

.fa-bitbucket:before {
  content: "\F171"; }

.fa-bitcoin:before {
  content: "\F379"; }

.fa-bity:before {
  content: "\F37A"; }

.fa-black-tie:before {
  content: "\F27E"; }

.fa-blackberry:before {
  content: "\F37B"; }

.fa-blanket:before {
  content: "\F498"; }

.fa-blender:before {
  content: "\F517"; }

.fa-blender-phone:before {
  content: "\F6B6"; }

.fa-blind:before {
  content: "\F29D"; }

.fa-blog:before {
  content: "\F781"; }

.fa-blogger:before {
  content: "\F37C"; }

.fa-blogger-b:before {
  content: "\F37D"; }

.fa-bluetooth:before {
  content: "\F293"; }

.fa-bluetooth-b:before {
  content: "\F294"; }

.fa-bold:before {
  content: "\F032"; }

.fa-bolt:before {
  content: "\F0E7"; }

.fa-bomb:before {
  content: "\F1E2"; }

.fa-bone:before {
  content: "\F5D7"; }

.fa-bone-break:before {
  content: "\F5D8"; }

.fa-bong:before {
  content: "\F55C"; }

.fa-book:before {
  content: "\F02D"; }

.fa-book-alt:before {
  content: "\F5D9"; }

.fa-book-dead:before {
  content: "\F6B7"; }

.fa-book-heart:before {
  content: "\F499"; }

.fa-book-medical:before {
  content: "\F7E6"; }

.fa-book-open:before {
  content: "\F518"; }

.fa-book-reader:before {
  content: "\F5DA"; }

.fa-book-spells:before {
  content: "\F6B8"; }

.fa-book-user:before {
  content: "\F7E7"; }

.fa-bookmark:before {
  content: "\F02E"; }

.fa-books:before {
  content: "\F5DB"; }

.fa-books-medical:before {
  content: "\F7E8"; }

.fa-boot:before {
  content: "\F782"; }

.fa-booth-curtain:before {
  content: "\F734"; }

.fa-bootstrap:before {
  content: "\F836"; }

.fa-bow-arrow:before {
  content: "\F6B9"; }

.fa-bowling-ball:before {
  content: "\F436"; }

.fa-bowling-pins:before {
  content: "\F437"; }

.fa-box:before {
  content: "\F466"; }

.fa-box-alt:before {
  content: "\F49A"; }

.fa-box-ballot:before {
  content: "\F735"; }

.fa-box-check:before {
  content: "\F467"; }

.fa-box-fragile:before {
  content: "\F49B"; }

.fa-box-full:before {
  content: "\F49C"; }

.fa-box-heart:before {
  content: "\F49D"; }

.fa-box-open:before {
  content: "\F49E"; }

.fa-box-up:before {
  content: "\F49F"; }

.fa-box-usd:before {
  content: "\F4A0"; }

.fa-boxes:before {
  content: "\F468"; }

.fa-boxes-alt:before {
  content: "\F4A1"; }

.fa-boxing-glove:before {
  content: "\F438"; }

.fa-brackets:before {
  content: "\F7E9"; }

.fa-brackets-curly:before {
  content: "\F7EA"; }

.fa-braille:before {
  content: "\F2A1"; }

.fa-brain:before {
  content: "\F5DC"; }

.fa-bread-loaf:before {
  content: "\F7EB"; }

.fa-bread-slice:before {
  content: "\F7EC"; }

.fa-briefcase:before {
  content: "\F0B1"; }

.fa-briefcase-medical:before {
  content: "\F469"; }

.fa-broadcast-tower:before {
  content: "\F519"; }

.fa-broom:before {
  content: "\F51A"; }

.fa-browser:before {
  content: "\F37E"; }

.fa-brush:before {
  content: "\F55D"; }

.fa-btc:before {
  content: "\F15A"; }

.fa-buffer:before {
  content: "\F837"; }

.fa-bug:before {
  content: "\F188"; }

.fa-building:before {
  content: "\F1AD"; }

.fa-bullhorn:before {
  content: "\F0A1"; }

.fa-bullseye:before {
  content: "\F140"; }

.fa-bullseye-arrow:before {
  content: "\F648"; }

.fa-bullseye-pointer:before {
  content: "\F649"; }

.fa-burn:before {
  content: "\F46A"; }

.fa-buromobelexperte:before {
  content: "\F37F"; }

.fa-burrito:before {
  content: "\F7ED"; }

.fa-bus:before {
  content: "\F207"; }

.fa-bus-alt:before {
  content: "\F55E"; }

.fa-bus-school:before {
  content: "\F5DD"; }

.fa-business-time:before {
  content: "\F64A"; }

.fa-buysellads:before {
  content: "\F20D"; }

.fa-cabinet-filing:before {
  content: "\F64B"; }

.fa-calculator:before {
  content: "\F1EC"; }

.fa-calculator-alt:before {
  content: "\F64C"; }

.fa-calendar:before {
  content: "\F133"; }

.fa-calendar-alt:before {
  content: "\F073"; }

.fa-calendar-check:before {
  content: "\F274"; }

.fa-calendar-day:before {
  content: "\F783"; }

.fa-calendar-edit:before {
  content: "\F333"; }

.fa-calendar-exclamation:before {
  content: "\F334"; }

.fa-calendar-minus:before {
  content: "\F272"; }

.fa-calendar-plus:before {
  content: "\F271"; }

.fa-calendar-star:before {
  content: "\F736"; }

.fa-calendar-times:before {
  content: "\F273"; }

.fa-calendar-week:before {
  content: "\F784"; }

.fa-camera:before {
  content: "\F030"; }

.fa-camera-alt:before {
  content: "\F332"; }

.fa-camera-retro:before {
  content: "\F083"; }

.fa-campfire:before {
  content: "\F6BA"; }

.fa-campground:before {
  content: "\F6BB"; }

.fa-canadian-maple-leaf:before {
  content: "\F785"; }

.fa-candle-holder:before {
  content: "\F6BC"; }

.fa-candy-cane:before {
  content: "\F786"; }

.fa-candy-corn:before {
  content: "\F6BD"; }

.fa-cannabis:before {
  content: "\F55F"; }

.fa-capsules:before {
  content: "\F46B"; }

.fa-car:before {
  content: "\F1B9"; }

.fa-car-alt:before {
  content: "\F5DE"; }

.fa-car-battery:before {
  content: "\F5DF"; }

.fa-car-bump:before {
  content: "\F5E0"; }

.fa-car-crash:before {
  content: "\F5E1"; }

.fa-car-garage:before {
  content: "\F5E2"; }

.fa-car-mechanic:before {
  content: "\F5E3"; }

.fa-car-side:before {
  content: "\F5E4"; }

.fa-car-tilt:before {
  content: "\F5E5"; }

.fa-car-wash:before {
  content: "\F5E6"; }

.fa-caret-circle-down:before {
  content: "\F32D"; }

.fa-caret-circle-left:before {
  content: "\F32E"; }

.fa-caret-circle-right:before {
  content: "\F330"; }

.fa-caret-circle-up:before {
  content: "\F331"; }

.fa-caret-down:before {
  content: "\F0D7"; }

.fa-caret-left:before {
  content: "\F0D9"; }

.fa-caret-right:before {
  content: "\F0DA"; }

.fa-caret-square-down:before {
  content: "\F150"; }

.fa-caret-square-left:before {
  content: "\F191"; }

.fa-caret-square-right:before {
  content: "\F152"; }

.fa-caret-square-up:before {
  content: "\F151"; }

.fa-caret-up:before {
  content: "\F0D8"; }

.fa-carrot:before {
  content: "\F787"; }

.fa-cart-arrow-down:before {
  content: "\F218"; }

.fa-cart-plus:before {
  content: "\F217"; }

.fa-cash-register:before {
  content: "\F788"; }

.fa-cat:before {
  content: "\F6BE"; }

.fa-cauldron:before {
  content: "\F6BF"; }

.fa-cc-amazon-pay:before {
  content: "\F42D"; }

.fa-cc-amex:before {
  content: "\F1F3"; }

.fa-cc-apple-pay:before {
  content: "\F416"; }

.fa-cc-diners-club:before {
  content: "\F24C"; }

.fa-cc-discover:before {
  content: "\F1F2"; }

.fa-cc-jcb:before {
  content: "\F24B"; }

.fa-cc-mastercard:before {
  content: "\F1F1"; }

.fa-cc-paypal:before {
  content: "\F1F4"; }

.fa-cc-stripe:before {
  content: "\F1F5"; }

.fa-cc-visa:before {
  content: "\F1F0"; }

.fa-centercode:before {
  content: "\F380"; }

.fa-centos:before {
  content: "\F789"; }

.fa-certificate:before {
  content: "\F0A3"; }

.fa-chair:before {
  content: "\F6C0"; }

.fa-chair-office:before {
  content: "\F6C1"; }

.fa-chalkboard:before {
  content: "\F51B"; }

.fa-chalkboard-teacher:before {
  content: "\F51C"; }

.fa-charging-station:before {
  content: "\F5E7"; }

.fa-chart-area:before {
  content: "\F1FE"; }

.fa-chart-bar:before {
  content: "\F080"; }

.fa-chart-line:before {
  content: "\F201"; }

.fa-chart-line-down:before {
  content: "\F64D"; }

.fa-chart-network:before {
  content: "\F78A"; }

.fa-chart-pie:before {
  content: "\F200"; }

.fa-chart-pie-alt:before {
  content: "\F64E"; }

.fa-chart-scatter:before {
  content: "\F7EE"; }

.fa-check:before {
  content: "\F00C"; }

.fa-check-circle:before {
  content: "\F058"; }

.fa-check-double:before {
  content: "\F560"; }

.fa-check-square:before {
  content: "\F14A"; }

.fa-cheese:before {
  content: "\F7EF"; }

.fa-cheese-swiss:before {
  content: "\F7F0"; }

.fa-cheeseburger:before {
  content: "\F7F1"; }

.fa-chess:before {
  content: "\F439"; }

.fa-chess-bishop:before {
  content: "\F43A"; }

.fa-chess-bishop-alt:before {
  content: "\F43B"; }

.fa-chess-board:before {
  content: "\F43C"; }

.fa-chess-clock:before {
  content: "\F43D"; }

.fa-chess-clock-alt:before {
  content: "\F43E"; }

.fa-chess-king:before {
  content: "\F43F"; }

.fa-chess-king-alt:before {
  content: "\F440"; }

.fa-chess-knight:before {
  content: "\F441"; }

.fa-chess-knight-alt:before {
  content: "\F442"; }

.fa-chess-pawn:before {
  content: "\F443"; }

.fa-chess-pawn-alt:before {
  content: "\F444"; }

.fa-chess-queen:before {
  content: "\F445"; }

.fa-chess-queen-alt:before {
  content: "\F446"; }

.fa-chess-rook:before {
  content: "\F447"; }

.fa-chess-rook-alt:before {
  content: "\F448"; }

.fa-chevron-circle-down:before {
  content: "\F13A"; }

.fa-chevron-circle-left:before {
  content: "\F137"; }

.fa-chevron-circle-right:before {
  content: "\F138"; }

.fa-chevron-circle-up:before {
  content: "\F139"; }

.fa-chevron-double-down:before {
  content: "\F322"; }

.fa-chevron-double-left:before {
  content: "\F323"; }

.fa-chevron-double-right:before {
  content: "\F324"; }

.fa-chevron-double-up:before {
  content: "\F325"; }

.fa-chevron-down:before {
  content: "\F078"; }

.fa-chevron-left:before {
  content: "\F053"; }

.fa-chevron-right:before {
  content: "\F054"; }

.fa-chevron-square-down:before {
  content: "\F329"; }

.fa-chevron-square-left:before {
  content: "\F32A"; }

.fa-chevron-square-right:before {
  content: "\F32B"; }

.fa-chevron-square-up:before {
  content: "\F32C"; }

.fa-chevron-up:before {
  content: "\F077"; }

.fa-child:before {
  content: "\F1AE"; }

.fa-chimney:before {
  content: "\F78B"; }

.fa-chrome:before {
  content: "\F268"; }

.fa-chromecast:before {
  content: "\F838"; }

.fa-church:before {
  content: "\F51D"; }

.fa-circle:before {
  content: "\F111"; }

.fa-circle-notch:before {
  content: "\F1CE"; }

.fa-city:before {
  content: "\F64F"; }

.fa-claw-marks:before {
  content: "\F6C2"; }

.fa-clinic-medical:before {
  content: "\F7F2"; }

.fa-clipboard:before {
  content: "\F328"; }

.fa-clipboard-check:before {
  content: "\F46C"; }

.fa-clipboard-list:before {
  content: "\F46D"; }

.fa-clipboard-list-check:before {
  content: "\F737"; }

.fa-clipboard-prescription:before {
  content: "\F5E8"; }

.fa-clipboard-user:before {
  content: "\F7F3"; }

.fa-clock:before {
  content: "\F017"; }

.fa-clone:before {
  content: "\F24D"; }

.fa-closed-captioning:before {
  content: "\F20A"; }

.fa-cloud:before {
  content: "\F0C2"; }

.fa-cloud-download:before {
  content: "\F0ED"; }

.fa-cloud-download-alt:before {
  content: "\F381"; }

.fa-cloud-drizzle:before {
  content: "\F738"; }

.fa-cloud-hail:before {
  content: "\F739"; }

.fa-cloud-hail-mixed:before {
  content: "\F73A"; }

.fa-cloud-meatball:before {
  content: "\F73B"; }

.fa-cloud-moon:before {
  content: "\F6C3"; }

.fa-cloud-moon-rain:before {
  content: "\F73C"; }

.fa-cloud-rain:before {
  content: "\F73D"; }

.fa-cloud-rainbow:before {
  content: "\F73E"; }

.fa-cloud-showers:before {
  content: "\F73F"; }

.fa-cloud-showers-heavy:before {
  content: "\F740"; }

.fa-cloud-sleet:before {
  content: "\F741"; }

.fa-cloud-snow:before {
  content: "\F742"; }

.fa-cloud-sun:before {
  content: "\F6C4"; }

.fa-cloud-sun-rain:before {
  content: "\F743"; }

.fa-cloud-upload:before {
  content: "\F0EE"; }

.fa-cloud-upload-alt:before {
  content: "\F382"; }

.fa-clouds:before {
  content: "\F744"; }

.fa-clouds-moon:before {
  content: "\F745"; }

.fa-clouds-sun:before {
  content: "\F746"; }

.fa-cloudscale:before {
  content: "\F383"; }

.fa-cloudsmith:before {
  content: "\F384"; }

.fa-cloudversify:before {
  content: "\F385"; }

.fa-club:before {
  content: "\F327"; }

.fa-cocktail:before {
  content: "\F561"; }

.fa-code:before {
  content: "\F121"; }

.fa-code-branch:before {
  content: "\F126"; }

.fa-code-commit:before {
  content: "\F386"; }

.fa-code-merge:before {
  content: "\F387"; }

.fa-codepen:before {
  content: "\F1CB"; }

.fa-codiepie:before {
  content: "\F284"; }

.fa-coffee:before {
  content: "\F0F4"; }

.fa-coffee-togo:before {
  content: "\F6C5"; }

.fa-coffin:before {
  content: "\F6C6"; }

.fa-cog:before {
  content: "\F013"; }

.fa-cogs:before {
  content: "\F085"; }

.fa-coins:before {
  content: "\F51E"; }

.fa-columns:before {
  content: "\F0DB"; }

.fa-comment:before {
  content: "\F075"; }

.fa-comment-alt:before {
  content: "\F27A"; }

.fa-comment-alt-check:before {
  content: "\F4A2"; }

.fa-comment-alt-dollar:before {
  content: "\F650"; }

.fa-comment-alt-dots:before {
  content: "\F4A3"; }

.fa-comment-alt-edit:before {
  content: "\F4A4"; }

.fa-comment-alt-exclamation:before {
  content: "\F4A5"; }

.fa-comment-alt-lines:before {
  content: "\F4A6"; }

.fa-comment-alt-medical:before {
  content: "\F7F4"; }

.fa-comment-alt-minus:before {
  content: "\F4A7"; }

.fa-comment-alt-plus:before {
  content: "\F4A8"; }

.fa-comment-alt-slash:before {
  content: "\F4A9"; }

.fa-comment-alt-smile:before {
  content: "\F4AA"; }

.fa-comment-alt-times:before {
  content: "\F4AB"; }

.fa-comment-check:before {
  content: "\F4AC"; }

.fa-comment-dollar:before {
  content: "\F651"; }

.fa-comment-dots:before {
  content: "\F4AD"; }

.fa-comment-edit:before {
  content: "\F4AE"; }

.fa-comment-exclamation:before {
  content: "\F4AF"; }

.fa-comment-lines:before {
  content: "\F4B0"; }

.fa-comment-medical:before {
  content: "\F7F5"; }

.fa-comment-minus:before {
  content: "\F4B1"; }

.fa-comment-plus:before {
  content: "\F4B2"; }

.fa-comment-slash:before {
  content: "\F4B3"; }

.fa-comment-smile:before {
  content: "\F4B4"; }

.fa-comment-times:before {
  content: "\F4B5"; }

.fa-comments:before {
  content: "\F086"; }

.fa-comments-alt:before {
  content: "\F4B6"; }

.fa-comments-alt-dollar:before {
  content: "\F652"; }

.fa-comments-dollar:before {
  content: "\F653"; }

.fa-compact-disc:before {
  content: "\F51F"; }

.fa-compass:before {
  content: "\F14E"; }

.fa-compass-slash:before {
  content: "\F5E9"; }

.fa-compress:before {
  content: "\F066"; }

.fa-compress-alt:before {
  content: "\F422"; }

.fa-compress-arrows-alt:before {
  content: "\F78C"; }

.fa-compress-wide:before {
  content: "\F326"; }

.fa-concierge-bell:before {
  content: "\F562"; }

.fa-confluence:before {
  content: "\F78D"; }

.fa-connectdevelop:before {
  content: "\F20E"; }

.fa  er-container-storage:before {
  content: "\F4B7"; }

.fa-contao:before {
  content: "\F26D"; }

.fa-conveyor-belt:before {
  content: "\F46E"; }

.fa-conveyor-belt-alt:before {
  content: "\F46F"; }

.fa-cookie:before {
  content: "\F563"; }

.fa-cookie-bite:before {
  content: "\F564"; }

.fa-copy:before {
  content: "\F0C5"; }

.fa-copyright:before {
  content: "\F1F9"; }

.fa-corn:before {
  content: "\F6C7"; }

.fa-couch:before {
  content: "\F4B8"; }

.fa-cow:before {
  content: "\F6C8"; }

.fa-cpanel:before {
  content: "\F388"; }

.fa-creative-commons:before {
  content: "\F25E"; }

.fa-creative-commons-by:before {
  content: "\F4E7"; }

.fa-creative-commons-nc:before {
  content: "\F4E8"; }

.fa-creative-commons-nc-eu:before {
  content: "\F4E9"; }

.fa-creative-commons-nc-jp:before {
  content: "\F4EA"; }

.fa-creative-commons-nd:before {
  content: "\F4EB"; }

.fa-creative-commons-pd:before {
  content: "\F4EC"; }

.fa-creative-commons-pd-alt:before {
  content: "\F4ED"; }

.fa-creative-commons-remix:before {
  content: "\F4EE"; }

.fa-creative-commons-sa:before {
  content: "\F4EF"; }

.fa-creative-commons-sampling:before {
  content: "\F4F0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\F4F1"; }

.fa-creative-commons-share:before {
  content: "\F4F2"; }

.fa-creative-commons-zero:before {
  content: "\F4F3"; }

.fa-credit-card:before {
  content: "\F09D"; }

.fa-credit-card-blank:before {
  content: "\F389"; }

.fa-credit-card-front:before {
  content: "\F38A"; }

.fa-cricket:before {
  content: "\F449"; }

.fa-critical-role:before {
  content: "\F6C9"; }

.fa-croissant:before {
  content: "\F7F6"; }

.fa-crop:before {
  content: "\F125"; }

.fa-crop-alt:before {
  content: "\F565"; }

.fa-cross:before {
  content: "\F654"; }

.fa-crosshairs:before {
  content: "\F05B"; }

.fa-crow:before {
  content: "\F520"; }

.fa-crown:before {
  content: "\F521"; }

.fa-crutch:before {
  content: "\F7F7"; }

.fa-crutches:before {
  content: "\F7F8"; }

.fa-css3:before {
  content: "\F13C"; }

.fa-css3-alt:before {
  content: "\F38B"; }

.fa-cube:before {
  content: "\F1B2"; }

.fa-cubes:before {
  content: "\F1B3"; }

.fa-curling:before {
  content: "\F44A"; }

.fa-cut:before {
  content: "\F0C4"; }

.fa-cuttlefish:before {
  content: "\F38C"; }

.fa-d-and-d:before {
  content: "\F38D"; }

.fa-d-and-d-beyond:before {
  content: "\F6CA"; }

.fa-dagger:before {
  content: "\F6CB"; }

.fa-dashcube:before {
  content: "\F210"; }

.fa-database:before {
  content: "\F1C0"; }

.fa-deaf:before {
  content: "\F2A4"; }

.fa-debug:before {
  content: "\F7F9"; }

.fa-deer:before {
  content: "\F78E"; }

.fa-deer-rudolph:before {
  content: "\F78F"; }

.fa-delicious:before {
  content: "\F1A5"; }

.fa-democrat:before {
  content: "\F747"; }

.fa-deploydog:before {
  content: "\F38E"; }

.fa-deskpro:before {
  content: "\F38F"; }

.fa-desktop:before {
  content: "\F108"; }

.fa-desktop-alt:before {
  content: "\F390"; }

.fa-dev:before {
  content: "\F6CC"; }

.fa-deviantart:before {
  content: "\F1BD"; }

.fa-dewpoint:before {
  content: "\F748"; }

.fa-dharmachakra:before {
  content: "\F655"; }

.fa-dhl:before {
  content: "\F790"; }

.fa-diagnoses:before {
  content: "\F470"; }

.fa-diamond:before {
  content: "\F219"; }

.fa-diaspora:before {
  content: "\F791"; }

.fa-dice:before {
  content: "\F522"; }

.fa-dice-d10:before {
  content: "\F6CD"; }

.fa-dice-d12:before {
  content: "\F6CE"; }

.fa-dice-d20:before {
  content: "\F6CF"; }

.fa-dice-d4:before {
  content: "\F6D0"; }

.fa-dice-d6:before {
  content: "\F6D1"; }

.fa-dice-d8:before {
  content: "\F6D2"; }

.fa-dice-five:before {
  content: "\F523"; }

.fa-dice-four:before {
  content: "\F524"; }

.fa-dice-one:before {
  content: "\F525"; }

.fa-dice-six:before {
  content: "\F526"; }

.fa-dice-three:before {
  content: "\F527"; }

.fa-dice-two:before {
  content: "\F528"; }

.fa-digg:before {
  content: "\F1A6"; }

.fa-digital-ocean:before {
  content: "\F391"; }

.fa-digital-tachograph:before {
  content: "\F566"; }

.fa-diploma:before {
  content: "\F5EA"; }

.fa-directions:before {
  content: "\F5EB"; }

.fa-discord:before {
  content: "\F392"; }

.fa-discourse:before {
  content: "\F393"; }

.fa-disease:before {
  content: "\F7FA"; }

.fa-divide:before {
  content: "\F529"; }

.fa-dizzy:before {
  content: "\F567"; }

.fa-dna:before {
  content: "\F471"; }

.fa-do-not-enter:before {
  content: "\F5EC"; }

.fa-dochub:before {
  content: "\F394"; }

.fa-docker:before {
  content: "\F395"; }

.fa-dog:before {
  content: "\F6D3"; }

.fa-dog-leashed:before {
  content: "\F6D4"; }

.fa-dollar-sign:before {
  content: "\F155"; }

.fa-dolly:before {
  content: "\F472"; }

.fa-dolly-empty:before {
  content: "\F473"; }

.fa-dolly-flatbed:before {
  content: "\F474"; }

.fa-dolly-flatbed-alt:before {
  content: "\F475"; }

.fa-dolly-flatbed-empty:before {
  content: "\F476"; }

.fa-donate:before {
  content: "\F4B9"; }

.fa-door-closed:before {
  content: "\F52A"; }

.fa-door-open:before {
  content: "\F52B"; }

.fa-dot-circle:before {
  content: "\F192"; }

.fa-dove:before {
  content: "\F4BA"; }

.fa-download:before {
  content: "\F019"; }

.fa-draft2digital:before {
  content: "\F396"; }

.fa-drafting-compass:before {
  content: "\F568"; }

.fa-dragon:before {
  content: "\F6D5"; }

.fa-draw-circle:before {
  content: "\F5ED"; }

.fa-draw-polygon:before {
  content: "\F5EE"; }

.fa-draw-square:before {
  content: "\F5EF"; }

.fa-dreidel:before {
  content: "\F792"; }

.fa-dribbble:before {
  content: "\F17D"; }

.fa-dribbble-square:before {
  content: "\F397"; }

.fa-dropbox:before {
  content: "\F16B"; }

.fa-drum:before {
  content: "\F569"; }

.fa-drum-steelpan:before {
  content: "\F56A"; }

.fa-drumstick:before {
  content: "\F6D6"; }

.fa-drumstick-bite:before {
  content: "\F6D7"; }

.fa-drupal:before {
  content: "\F1A9"; }

.fa-duck:before {
  content: "\F6D8"; }

.fa-dumbbell:before {
  content: "\F44B"; }

.fa-dumpster:before {
  content: "\F793"; }

.fa-dumpster-fire:before {
  content: "\F794"; }

.fa-dungeon:before {
  content: "\F6D9"; }

.fa-dyalog:before {
  content: "\F399"; }

.fa-ear:before {
  content: "\F5F0"; }

.fa-ear-muffs:before {
  content: "\F795"; }

.fa-earlybirds:before {
  content: "\F39A"; }

.fa-ebay:before {
  content: "\F4F4"; }

.fa-eclipse:before {
  content: "\F749"; }

.fa-eclipse-alt:before {
  content: "\F74A"; }

.fa-edge:before {
  content: "\F282"; }

.fa-edit:before {
  content: "\F044"; }

.fa-egg:before {
  content: "\F7FB"; }

.fa-egg-fried:before {
  content: "\F7FC"; }

.fa-eject:before {
  content: "\F052"; }

.fa-elementor:before {
  content: "\F430"; }

.fa-elephant:before {
  content: "\F6DA"; }

.fa-ellipsis-h:before {
  content: "\F141"; }

.fa-ellipsis-h-alt:before {
  content: "\F39B"; }

.fa-ellipsis-v:before {
  content: "\F142"; }

.fa-ellipsis-v-alt:before {
  content: "\F39C"; }

.fa-ello:before {
  content: "\F5F1"; }

.fa-ember:before {
  content: "\F423"; }

.fa-empire:before {
  content: "\F1D1"; }

.fa-empty-set:before {
  content: "\F656"; }

.fa-engine-warning:before {
  content: "\F5F2"; }

.fa-envelope:before {
  content: "\F0E0"; }

.fa-envelope-open:before {
  content: "\F2B6"; }

.fa-envelope-open-dollar:before {
  content: "\F657"; }

.fa-envelope-open-text:before {
  content: "\F658"; }

.fa-envelope-square:before {
  content: "\F199"; }

.fa-envira:before {
  content: "\F299"; }

.fa-equals:before {
  content: "\F52C"; }

.fa-eraser:before {
  content: "\F12D"; }

.fa-erlang:before {
  content: "\F39D"; }

.fa-ethereum:before {
  content: "\F42E"; }

.fa-ethernet:before {
  content: "\F796"; }

.fa-etsy:before {
  content: "\F2D7"; }

.fa-euro-sign:before {
  content: "\F153"; }

.fa-evernote:before {
  content: "\F839"; }

.fa-exchange:before {
  content: "\F0EC"; }

.fa-exchange-alt:before {
  content: "\F362"; }

.fa-exclamation:before {
  content: "\F12A"; }

.fa-exclamation-circle:before {
  content: "\F06A"; }

.fa-exclamation-square:before {
  content: "\F321"; }

.fa-exclamation-triangle:before {
  content: "\F071"; }

.fa-expand:before {
  content: "\F065"; }

.fa-expand-alt:before {
  content: "\F424"; }

.fa-expand-arrows:before {
  content: "\F31D"; }

.fa-expand-arrows-alt:before {
  content: "\F31E"; }

.fa-expand-wide:before {
  content: "\F320"; }

.fa-expeditedssl:before {
  content: "\F23E"; }

.fa-external-link:before {
  content: "\F08E"; }

.fa-external-link-alt:before {
  content: "\F35D"; }

.fa-external-link-square:before {
  content: "\F14C"; }

.fa-external-link-square-alt:before {
  content: "\F360"; }

.fa-eye:before {
  content: "\F06E"; }

.fa-eye-dropper:before {
  content: "\F1FB"; }

.fa-eye-evil:before {
  content: "\F6DB"; }

.fa-eye-slash:before {
  content: "\F070"; }

.fa-facebook:before {
  content: "\F09A"; }

.fa-facebook-f:before {
  content: "\F39E"; }

.fa-facebook-messenger:before {
  content: "\F39F"; }

.fa-facebook-square:before {
  content: "\F082"; }

.fa-fantasy-flight-games:before {
  content: "\F6DC"; }

.fa-fast-backward:before {
  content: "\F049"; }

.fa-fast-forward:before {
  content: "\F050"; }

.fa-fax:before {
  content: "\F1AC"; }

.fa-feather:before {
  content: "\F52D"; }

.fa-feather-alt:before {
  content: "\F56B"; }

.fa-fedex:before {
  content: "\F797"; }

.fa-fedora:before {
  content: "\F798"; }

.fa-female:before {
  content: "\F182"; }

.fa-field-hockey:before {
  content: "\F44C"; }

.fa-fighter-jet:before {
  content: "\F0FB"; }

.fa-figma:before {
  content: "\F799"; }

.fa-file:before {
  content: "\F15B"; }

.fa-file-alt:before {
  content: "\F15C"; }

.fa-file-archive:before {
  content: "\F1C6"; }

.fa-file-audio:before {
  content: "\F1C7"; }

.fa-file-certificate:before {
  content: "\F5F3"; }

.fa-file-chart-line:before {
  content: "\F659"; }

.fa-file-chart-pie:before {
  content: "\F65A"; }

.fa-file-check:before {
  content: "\F316"; }

.fa-file-code:before {
  content: "\F1C9"; }

.fa-file-contract:before {
  content: "\F56C"; }

.fa-file-csv:before {
  content: "\F6DD"; }

.fa-file-download:before {
  content: "\F56D"; }

.fa-file-edit:before {
  content: "\F31C"; }

.fa-file-excel:before {
  content: "\F1C3"; }

.fa-file-exclamation:before {
  content: "\F31A"; }

.fa-file-export:before {
  content: "\F56E"; }

.fa-file-image:before {
  content: "\F1C5"; }

.fa-file-import:before {
  content: "\F56F"; }

.fa-file-invoice:before {
  content: "\F570"; }

.fa-file-invoice-dollar:before {
  content: "\F571"; }

.fa-file-medical:before {
  content: "\F477"; }

.fa-file-medical-alt:before {
  content: "\F478"; }

.fa-file-minus:before {
  content: "\F318"; }

.fa-file-pdf:before {
  content: "\F1C1"; }

.fa-file-plus:before {
  content: "\F319"; }

.fa-file-powerpoint:before {
  content: "\F1C4"; }

.fa-file-prescription:before {
  content: "\F572"; }

.fa-file-signature:before {
  content: "\F573"; }

.fa-file-spreadsheet:before {
  content: "\F65B"; }

.fa-file-times:before {
  content: "\F317"; }

.fa-file-upload:before {
  content: "\F574"; }

.fa-file-user:before {
  content: "\F65C"; }

.fa-file-video:before {
  content: "\F1C8"; }

.fa-file-word:before {
  content: "\F1C2"; }

.fa-files-medical:before {
  content: "\F7FD"; }

.fa-fill:before {
  content: "\F575"; }

.fa-fill-drip:before {
  content: "\F576"; }

.fa-film:before {
  content: "\F008"; }

.fa-film-alt:before {
  content: "\F3A0"; }

.fa-filter:before {
  content: "\F0B0"; }

.fa-fingerprint:before {
  content: "\F577"; }

.fa-fire:before {
  content: "\F06D"; }

.fa-fire-alt:before {
  content: "\F7E4"; }

.fa-fire-extinguisher:before {
  content: "\F134"; }

.fa-fire-smoke:before {
  content: "\F74B"; }

.fa-firefox:before {
  content: "\F269"; }

.fa-fireplace:before {
  content: "\F79A"; }

.fa-first-aid:before {
  content: "\F479"; }

.fa-first-order:before {
  content: "\F2B0"; }

.fa-first-order-alt:before {
  content: "\F50A"; }

.fa-firstdraft:before {
  content: "\F3A1"; }

.fa-fish:before {
  content: "\F578"; }

.fa-fish-cooked:before {
  content: "\F7FE"; }

.fa-fist-raised:before {
  content: "\F6DE"; }

.fa-flag:before {
  content: "\F024"; }

.fa-flag-alt:before {
  content: "\F74C"; }

.fa-flag-checkered:before {
  content: "\F11E"; }

.fa-flag-usa:before {
  content: "\F74D"; }

.fa-flame:before {
  content: "\F6DF"; }

.fa-flask:before {
  content: "\F0C3"; }

.fa-flask-poison:before {
  content: "\F6E0"; }

.fa-flask-potion:before {
  content: "\F6E1"; }

.fa-flickr:before {
  content: "\F16E"; }

.fa-flipboard:before {
  content: "\F44D"; }

.fa-flower:before {
  content: "\F7FF"; }

.fa-flower-daffodil:before {
  content: "\F800"; }

.fa-flower-tulip:before {
  content: "\F801"; }

.fa-flushed:before {
  content: "\F579"; }

.fa-fly:before {
  content: "\F417"; }

.fa-fog:before {
  content: "\F74E"; }

.fa-folder:before {
  content: "\F07B"; }

.fa-folder-minus:before {
  content: "\F65D"; }

.fa-folder-open:before {
  content: "\F07C"; }

.fa-folder-plus:before {
  content: "\F65E"; }

.fa-folder-times:before {
  content: "\F65F"; }

.fa-folder-tree:before {
  content: "\F802"; }

.fa-folders:before {
  content: "\F660"; }

.fa-font:before {
  content: "\F031"; }

.fa-font-awesome:before {
  content: "\F2B4"; }

.fa-font-awesome-alt:before {
  content: "\F35C"; }

.fa-font-awesome-flag:before {
  content: "\F425"; }

.fa-font-awesome-logo-full:before {
  content: "\F4E6"; }

.fa-fonticons:before {
  content: "\F280"; }

.fa-fonticons-fi:before {
  content: "\F3A2"; }

.fa-football-ball:before {
  content: "\F44E"; }

.fa-football-helmet:before {
  content: "\F44F"; }

.fa-forklift:before {
  content: "\F47A"; }

.fa-fort-awesome:before {
  content: "\F286"; }

.fa-fort-awesome-alt:before {
  content: "\F3A3"; }

.fa-forumbee:before {
  content: "\F211"; }

.fa-forward:before {
  content: "\F04E"; }

.fa-foursquare:before {
  content: "\F180"; }

.fa-fragile:before {
  content: "\F4BB"; }

.fa-free-code-camp:before {
  content: "\F2C5"; }

.fa-freebsd:before {
  content: "\F3A4"; }

.fa-french-fries:before {
  content: "\F803"; }

.fa-frog:before {
  content: "\F52E"; }

.fa-frosty-head:before {
  content: "\F79B"; }

.fa-frown:before {
  content: "\F119"; }

.fa-frown-open:before {
  content: "\F57A"; }

.fa-fulcrum:before {
  content: "\F50B"; }

.fa-function:before {
  content: "\F661"; }

.fa-funnel-dollar:before {
  content: "\F662"; }

.fa-futbol:before {
  content: "\F1E3"; }

.fa-galactic-republic:before {
  content: "\F50C"; }

.fa-galactic-senate:before {
  content: "\F50D"; }

.fa-gamepad:before {
  content: "\F11B"; }

.fa-gas-pump:before {
  content: "\F52F"; }

.fa-gas-pump-slash:before {
  content: "\F5F4"; }

.fa-gavel:before {
  content: "\F0E3"; }

.fa-gem:before {
  content: "\F3A5"; }

.fa-genderless:before {
  content: "\F22D"; }

.fa-get-pocket:before {
  content: "\F265"; }

.fa-gg:before {
  content: "\F260"; }

.fa-gg-circle:before {
  content: "\F261"; }

.fa-ghost:before {
  content: "\F6E2"; }

.fa-gift:before {
  content: "\F06B"; }

.fa-gift-card:before {
  content: "\F663"; }

.fa-gifts:before {
  content: "\F79C"; }

.fa-gingerbread-man:before {
  content: "\F79D"; }

.fa-git:before {
  content: "\F1D3"; }

.fa-git-square:before {
  content: "\F1D2"; }

.fa-github:before {
  content: "\F09B"; }

.fa-github-alt:before {
  content: "\F113"; }

.fa-github-square:before {
  content: "\F092"; }

.fa-gitkraken:before {
  content: "\F3A6"; }

.fa-gitlab:before {
  content: "\F296"; }

.fa-gitter:before {
  content: "\F426"; }

.fa-glass:before {
  content: "\F804"; }

.fa-glass-champagne:before {
  content: "\F79E"; }

.fa-glass-cheers:before {
  content: "\F79F"; }

.fa-glass-martini:before {
  content: "\F000"; }

.fa-glass-martini-alt:before {
  content: "\F57B"; }

.fa-glass-whiskey:before {
  content: "\F7A0"; }

.fa-glass-whiskey-rocks:before {
  content: "\F7A1"; }

.fa-glasses:before {
  content: "\F530"; }

.fa-glasses-alt:before {
  content: "\F5F5"; }

.fa-glide:before {
  content: "\F2A5"; }

.fa-glide-g:before {
  content: "\F2A6"; }

.fa-globe:before {
  content: "\F0AC"; }

.fa-globe-africa:before {
  content: "\F57C"; }

.fa-globe-americas:before {
  content: "\F57D"; }

.fa-globe-asia:before {
  content: "\F57E"; }

.fa-globe-europe:before {
  content: "\F7A2"; }

.fa-globe-snow:before {
  content: "\F7A3"; }

.fa-globe-stand:before {
  content: "\F5F6"; }

.fa-gofore:before {
  content: "\F3A7"; }

.fa-golf-ball:before {
  content: "\F450"; }

.fa-golf-club:before {
  content: "\F451"; }

.fa-goodreads:before {
  content: "\F3A8"; }

.fa-goodreads-g:before {
  content: "\F3A9"; }

.fa-google:before {
  content: "\F1A0"; }

.fa-google-drive:before {
  content: "\F3AA"; }

.fa-google-play:before {
  content: "\F3AB"; }

.fa-google-plus:before {
  content: "\F2B3"; }

.fa-google-plus-g:before {
  content: "\F0D5"; }

.fa-google-plus-square:before {
  content: "\F0D4"; }

.fa-google-wallet:before {
  content: "\F1EE"; }

.fa-gopuram:before {
  content: "\F664"; }

.fa-graduation-cap:before {
  content: "\F19D"; }

.fa-gratipay:before {
  content: "\F184"; }

.fa-grav:before {
  content: "\F2D6"; }

.fa-greater-than:before {
  content: "\F531"; }

.fa-greater-than-equal:before {
  content: "\F532"; }

.fa-grimace:before {
  content: "\F57F"; }

.fa-grin:before {
  content: "\F580"; }

.fa-grin-alt:before {
  content: "\F581"; }

.fa-grin-beam:before {
  content: "\F582"; }

.fa-grin-beam-sweat:before {
  content: "\F583"; }

.fa-grin-hearts:before {
  content: "\F584"; }

.fa-grin-squint:before {
  content: "\F585"; }

.fa-grin-squint-tears:before {
  content: "\F586"; }

.fa-grin-stars:before {
  content: "\F587"; }

.fa-grin-tears:before {
  content: "\F588"; }

.fa-grin-tongue:before {
  content: "\F589"; }

.fa-grin-tongue-squint:before {
  content: "\F58A"; }

.fa-grin-tongue-wink:before {
  content: "\F58B"; }

.fa-grin-wink:before {
  content: "\F58C"; }

.fa-grip-horizontal:before {
  content: "\F58D"; }

.fa-grip-lines:before {
  content: "\F7A4"; }

.fa-grip-lines-vertical:before {
  content: "\F7A5"; }

.fa-grip-vertical:before {
  content: "\F58E"; }

.fa-gripfire:before {
  content: "\F3AC"; }

.fa-grunt:before {
  content: "\F3AD"; }

.fa-guitar:before {
  content: "\F7A6"; }

.fa-gulp:before {
  content: "\F3AE"; }

.fa-h-square:before {
  content: "\F0FD"; }

.fa-h1:before {
  content: "\F313"; }

.fa-h2:before {
  content: "\F314"; }

.fa-h3:before {
  content: "\F315"; }

.fa-hacker-news:before {
  content: "\F1D4"; }

.fa-hacker-news-square:before {
  content: "\F3AF"; }

.fa-hackerrank:before {
  content: "\F5F7"; }

.fa-hamburger:before {
  content: "\F805"; }

.fa-hammer:before {
  content: "\F6E3"; }

.fa-hammer-war:before {
  content: "\F6E4"; }

.fa-hamsa:before {
  content: "\F665"; }

.fa-hand-heart:before {
  content: "\F4BC"; }

.fa-hand-holding:before {
  content: "\F4BD"; }

.fa-hand-holding-box:before {
  content: "\F47B"; }

.fa-hand-holding-heart:before {
  content: "\F4BE"; }

.fa-hand-holding-magic:before {
  content: "\F6E5"; }

.fa-hand-holding-seedling:before {
  content: "\F4BF"; }

.fa-hand-holding-usd:before {
  content: "\F4C0"; }

.fa-hand-holding-water:before {
  content: "\F4C1"; }

.fa-hand-lizard:before {
  content: "\F258"; }

.fa-hand-middle-finger:before {
  content: "\F806"; }

.fa-hand-paper:before {
  content: "\F256"; }

.fa-hand-peace:before {
  content: "\F25B"; }

.fa-hand-point-down:before {
  content: "\F0A7"; }

.fa-hand-point-left:before {
  content: "\F0A5"; }

.fa-hand-point-right:before {
  content: "\F0A4"; }

.fa-hand-point-up:before {
  content: "\F0A6"; }

.fa-hand-pointer:before {
  content: "\F25A"; }

.fa-hand-receiving:before {
  content: "\F47C"; }

.fa-hand-rock:before {
  content: "\F255"; }

.fa-hand-scissors:before {
  content: "\F257"; }

.fa-hand-spock:before {
  content: "\F259"; }

.fa-hands:before {
  content: "\F4C2"; }

.fa-hands-heart:before {
  content: "\F4C3"; }

.fa-hands-helping:before {
  content: "\F4C4"; }

.fa-hands-usd:before {
  content: "\F4C5"; }

.fa-handshake:before {
  content: "\F2B5"; }

.fa-handshake-alt:before {
  content: "\F4C6"; }

.fa-hanukiah:before {
  content: "\F6E6"; }

.fa-hard-hat:before {
  content: "\F807"; }

.fa-hashtag:before {
  content: "\F292"; }

.fa-hat-santa:before {
  content: "\F7A7"; }

.fa-hat-winter:before {
  content: "\F7A8"; }

.fa-hat-witch:before {
  content: "\F6E7"; }

.fa-hat-wizard:before {
  content: "\F6E8"; }

.fa-haykal:before {
  content: "\F666"; }

.fa-hdd:before {
  content: "\F0A0"; }

.fa-head-side:before {
  content: "\F6E9"; }

.fa-head-side-brain:before {
  content: "\F808"; }

.fa-head-side-medical:before {
  content: "\F809"; }

.fa-head-vr:before {
  content: "\F6EA"; }

.fa-heading:before {
  content: "\F1DC"; }

.fa-headphones:before {
  content: "\F025"; }

.fa-headphones-alt:before {
  content: "\F58F"; }

.fa-headset:before {
  content: "\F590"; }

.fa-heart:before {
  content: "\F004"; }

.fa-heart-broken:before {
  content: "\F7A9"; }

.fa-heart-circle:before {
  content: "\F4C7"; }

.fa-heart-rate:before {
  content: "\F5F8"; }

.fa-heart-square:before {
  content: "\F4C8"; }

.fa-heartbeat:before {
  content: "\F21E"; }

.fa-helicopter:before {
  content: "\F533"; }

.fa-helmet-battle:before {
  content: "\F6EB"; }

.fa-hexagon:before {
  content: "\F312"; }

.fa-highlighter:before {
  content: "\F591"; }

.fa-hiking:before {
  content: "\F6EC"; }

.fa-hippo:before {
  content: "\F6ED"; }

.fa-hips:before {
  content: "\F452"; }

.fa-hire-a-helper:before {
  content: "\F3B0"; }

.fa-history:before {
  content: "\F1DA"; }

.fa-hockey-mask:before {
  content: "\F6EE"; }

.fa-hockey-puck:before {
  content: "\F453"; }

.fa-hockey-sticks:before {
  content: "\F454"; }

.fa-holly-berry:before {
  content: "\F7AA"; }

.fa-home:before {
  content: "\F015"; }

.fa-home-alt:before {
  content: "\F80A"; }

.fa-home-heart:before {
  content: "\F4C9"; }

.fa-home-lg:before {
  content: "\F80B"; }

.fa-home-lg-alt:before {
  content: "\F80C"; }

.fa-hood-cloak:before {
  content: "\F6EF"; }

.fa-hooli:before {
  content: "\F427"; }

.fa-hornbill:before {
  content: "\F592"; }

.fa-horse:before {
  content: "\F6F0"; }

.fa-horse-head:before {
  content: "\F7AB"; }

.fa-hospital:before {
  content: "\F0F8"; }

.fa-hospital-alt:before {
  content: "\F47D"; }

.fa-hospital-symbol:before {
  content: "\F47E"; }

.fa-hospital-user:before {
  content: "\F80D"; }

.fa-hospitals:before {
  content: "\F80E"; }

.fa-hot-tub:before {
  content: "\F593"; }

.fa-hotdog:before {
  content: "\F80F"; }

.fa-hotel:before {
  content: "\F594"; }

.fa-hotjar:before {
  content: "\F3B1"; }

.fa-hourglass:before {
  content: "\F254"; }

.fa-hourglass-end:before {
  content: "\F253"; }

.fa-hourglass-half:before {
  content: "\F252"; }

.fa-hourglass-start:before {
  content: "\F251"; }

.fa-house-damage:before {
  content: "\F6F1"; }

.fa-house-flood:before {
  content: "\F74F"; }

.fa-houzz:before {
  content: "\F27C"; }

.fa-hryvnia:before {
  content: "\F6F2"; }

.fa-html5:before {
  content: "\F13B"; }

.fa-hubspot:before {
  content: "\F3B2"; }

.fa-humidity:before {
  content: "\F750"; }

.fa-hurricane:before {
  content: "\F751"; }

.fa-i-cursor:before {
  content: "\F246"; }

.fa-ice-cream:before {
  content: "\F810"; }

.fa-ice-skate:before {
  content: "\F7AC"; }

.fa-icicles:before {
  content: "\F7AD"; }

.fa-id-badge:before {
  content: "\F2C1"; }

.fa-id-card:before {
  content: "\F2C2"; }

.fa-id-card-alt:before {
  content: "\F47F"; }

.fa-igloo:before {
  content: "\F7AE"; }

.fa-image:before {
  content: "\F03E"; }

.fa-images:before {
  content: "\F302"; }

.fa-imdb:before {
  content: "\F2D8"; }

.fa-inbox:before {
  content: "\F01C"; }

.fa-inbox-in:before {
  content: "\F310"; }

.fa-inbox-out:before {
  content: "\F311"; }

.fa-indent:before {
  content: "\F03C"; }

.fa-industry:before {
  content: "\F275"; }

.fa-industry-alt:before {
  content: "\F3B3"; }

.fa-infinity:before {
  content: "\F534"; }

.fa-info:before {
  content: "\F129"; }

.fa-info-circle:before {
  content: "\F05A"; }

.fa-info-square:before {
  content: "\F30F"; }

.fa-inhaler:before {
  content: "\F5F9"; }

.fa-instagram:before {
  content: "\F16D"; }

.fa-integral:before {
  content: "\F667"; }

.fa-intercom:before {
  content: "\F7AF"; }

.fa-internet-explorer:before {
  content: "\F26B"; }

.fa-intersection:before {
  content: "\F668"; }

.fa-inventory:before {
  content: "\F480"; }

.fa-invision:before {
  content: "\F7B0"; }

.fa-ioxhost:before {
  content: "\F208"; }

.fa-island-tropical:before {
  content: "\F811"; }

.fa-italic:before {
  content: "\F033"; }

.fa-itch-io:before {
  content: "\F83A"; }

.fa-itunes:before {
  content: "\F3B4"; }

.fa-itunes-note:before {
  content: "\F3B5"; }

.fa-jack-o-lantern:before {
  content: "\F30E"; }

.fa-java:before {
  content: "\F4E4"; }

.fa-jedi:before {
  content: "\F669"; }

.fa-jedi-order:before {
  content: "\F50E"; }

.fa-jenkins:before {
  content: "\F3B6"; }

.fa-jira:before {
  content: "\F7B1"; }

.fa-joget:before {
  content: "\F3B7"; }

.fa-joint:before {
  content: "\F595"; }

.fa-joomla:before {
  content: "\F1AA"; }

.fa-journal-whills:before {
  content: "\F66A"; }

.fa-js:before {
  content: "\F3B8"; }

.fa-js-square:before {
  content: "\F3B9"; }

.fa-jsfiddle:before {
  content: "\F1CC"; }

.fa-kaaba:before {
  content: "\F66B"; }

.fa-kaggle:before {
  content: "\F5FA"; }

.fa-key:before {
  content: "\F084"; }

.fa-key-skeleton:before {
  content: "\F6F3"; }

.fa-keybase:before {
  content: "\F4F5"; }

.fa-keyboard:before {
  content: "\F11C"; }

.fa-keycdn:before {
  content: "\F3BA"; }

.fa-keynote:before {
  content: "\F66C"; }

.fa-khanda:before {
  content: "\F66D"; }

.fa-kickstarter:before {
  content: "\F3BB"; }

.fa-kickstarter-k:before {
  content: "\F3BC"; }

.fa-kidneys:before {
  content: "\F5FB"; }

.fa-kiss:before {
  content: "\F596"; }

.fa-kiss-beam:before {
  content: "\F597"; }

.fa-kiss-wink-heart:before {
  content: "\F598"; }

.fa-kite:before {
  content: "\F6F4"; }

.fa-kiwi-bird:before {
  content: "\F535"; }

.fa-knife-kitchen:before {
  content: "\F6F5"; }

.fa-korvue:before {
  content: "\F42F"; }

.fa-lambda:before {
  content: "\F66E"; }

.fa-lamp:before {
  content: "\F4CA"; }

.fa-landmark:before {
  content: "\F66F"; }

.fa-landmark-alt:before {
  content: "\F752"; }

.fa-language:before {
  content: "\F1AB"; }

.fa-laptop:before {
  content: "\F109"; }

.fa-laptop-code:before {
  content: "\F5FC"; }

.fa-laptop-medical:before {
  content: "\F812"; }

.fa-laravel:before {
  content: "\F3BD"; }

.fa-lastfm:before {
  content: "\F202"; }

.fa-lastfm-square:before {
  content: "\F203"; }

.fa-laugh:before {
  content: "\F599"; }

.fa-laugh-beam:before {
  content: "\F59A"; }

.fa-laugh-squint:before {
  content: "\F59B"; }

.fa-laugh-wink:before {
  content: "\F59C"; }

.fa-layer-group:before {
  content: "\F5FD"; }

.fa-layer-minus:before {
  content: "\F5FE"; }

.fa-layer-plus:before {
  content: "\F5FF"; }

.fa-leaf:before {
  content: "\F06C"; }

.fa-leaf-heart:before {
  content: "\F4CB"; }

.fa-leaf-maple:before {
  content: "\F6F6"; }

.fa-leaf-oak:before {
  content: "\F6F7"; }

.fa-leanpub:before {
  content: "\F212"; }

.fa-lemon:before {
  content: "\F094"; }

.fa-less:before {
  content: "\F41D"; }

.fa-less-than:before {
  content: "\F536"; }

.fa-less-than-equal:before {
  content: "\F537"; }

.fa-level-down:before {
  content: "\F149"; }

.fa-level-down-alt:before {
  content: "\F3BE"; }

.fa-level-up:before {
  content: "\F148"; }

.fa-level-up-alt:before {
  content: "\F3BF"; }

.fa-life-ring:before {
  content: "\F1CD"; }

.fa-lightbulb:before {
  content: "\F0EB"; }

.fa-lightbulb-dollar:before {
  content: "\F670"; }

.fa-lightbulb-exclamation:before {
  content: "\F671"; }

.fa-lightbulb-on:before {
  content: "\F672"; }

.fa-lightbulb-slash:before {
  content: "\F673"; }

.fa-lights-holiday:before {
  content: "\F7B2"; }

.fa-line:before {
  content: "\F3C0"; }

.fa-link:before {
  content: "\F0C1"; }

.fa-linkedin:before {
  content: "\F08C"; }

.fa-linkedin-in:before {
  content: "\F0E1"; }

.fa-linode:before {
  content: "\F2B8"; }

.fa-linux:before {
  content: "\F17C"; }

.fa-lips:before {
  content: "\F600"; }

.fa-lira-sign:before {
  content: "\F195"; }

.fa-list:before {
  content: "\F03A"; }

.fa-list-alt:before {
  content: "\F022"; }

.fa-list-ol:before {
  content: "\F0CB"; }

.fa-list-ul:before {
  content: "\F0CA"; }

.fa-location:before {
  content: "\F601"; }

.fa-location-arrow:before {
  content: "\F124"; }

.fa-location-circle:before {
  content: "\F602"; }

.fa-location-slash:before {
  content: "\F603"; }

.fa-lock:before {
  content: "\F023"; }

.fa-lock-alt:before {
  content: "\F30D"; }

.fa-lock-open:before {
  content: "\F3C1"; }

.fa-lock-open-alt:before {
  content: "\F3C2"; }

.fa-long-arrow-alt-down:before {
  content: "\F309"; }

.fa-long-arrow-alt-left:before {
  content: "\F30A"; }

.fa-long-arrow-alt-right:before {
  content: "\F30B"; }

.fa-long-arrow-alt-up:before {
  content: "\F30C"; }

.fa-long-arrow-down:before {
  content: "\F175"; }

.fa-long-arrow-left:before {
  content: "\F177"; }

.fa-long-arrow-right:before {
  content: "\F178"; }

.fa-long-arrow-up:before {
  content: "\F176"; }

.fa-loveseat:before {
  content: "\F4CC"; }

.fa-low-vision:before {
  content: "\F2A8"; }

.fa-luchador:before {
  content: "\F455"; }

.fa-luggage-cart:before {
  content: "\F59D"; }

.fa-lungs:before {
  content: "\F604"; }

.fa-lyft:before {
  content: "\F3C3"; }

.fa-mace:before {
  content: "\F6F8"; }

.fa-magento:before {
  content: "\F3C4"; }

.fa-magic:before {
  content: "\F0D0"; }

.fa-magnet:before {
  content: "\F076"; }

.fa-mail-bulk:before {
  content: "\F674"; }

.fa-mailbox:before {
  content: "\F813"; }

.fa-mailchimp:before {
  content: "\F59E"; }

.fa-male:before {
  content: "\F183"; }

.fa-mandalorian:before {
  content: "\F50F"; }

.fa-mandolin:before {
  content: "\F6F9"; }

.fa-map:before {
  content: "\F279"; }

.fa-map-marked:before {
  content: "\F59F"; }

.fa-map-marked-alt:before {
  content: "\F5A0"; }

.fa-map-marker:before {
  content: "\F041"; }

.fa-map-marker-alt:before {
  content: "\F3C5"; }

.fa-map-marker-alt-slash:before {
  content: "\F605"; }

.fa-map-marker-check:before {
  content: "\F606"; }

.fa-map-marker-edit:before {
  content: "\F607"; }

.fa-map-marker-exclamation:before {
  content: "\F608"; }

.fa-map-marker-minus:before {
  content: "\F609"; }

.fa-map-marker-plus:before {
  content: "\F60A"; }

.fa-map-marker-question:before {
  content: "\F60B"; }

.fa-map-marker-slash:before {
  content: "\F60C"; }

.fa-map-marker-smile:before {
  content: "\F60D"; }

.fa-map-marker-times:before {
  content: "\F60E"; }

.fa-map-pin:before {
  content: "\F276"; }

.fa-map-signs:before {
  content: "\F277"; }

.fa-markdown:before {
  content: "\F60F"; }

.fa-marker:before {
  content: "\F5A1"; }

.fa-mars:before {
  content: "\F222"; }

.fa-mars-double:before {
  content: "\F227"; }

.fa-mars-stroke:before {
  content: "\F229"; }

.fa-mars-stroke-h:before {
  content: "\F22B"; }

.fa-mars-stroke-v:before {
  content: "\F22A"; }

.fa-mask:before {
  content: "\F6FA"; }

.fa-mastodon:before {
  content: "\F4F6"; }

.fa-maxcdn:before {
  content: "\F136"; }

.fa-meat:before {
  content: "\F814"; }

.fa-medal:before {
  content: "\F5A2"; }

.fa-medapps:before {
  content: "\F3C6"; }

.fa-medium:before {
  content: "\F23A"; }

.fa-medium-m:before {
  content: "\F3C7"; }

.fa-medkit:before {
  content: "\F0FA"; }

.fa-medrt:before {
  content: "\F3C8"; }

.fa-meetup:before {
  content: "\F2E0"; }

.fa-megaphone:before {
  content: "\F675"; }

.fa-megaport:before {
  content: "\F5A3"; }

.fa-meh:before {
  content: "\F11A"; }

.fa-meh-blank:before {
  content: "\F5A4"; }

.fa-meh-rolling-eyes:before {
  content: "\F5A5"; }

.fa-memory:before {
  content: "\F538"; }

.fa-mendeley:before {
  content: "\F7B3"; }

.fa-menorah:before {
  content: "\F676"; }

.fa-mercury:before {
  content: "\F223"; }

.fa-meteor:before {
  content: "\F753"; }

.fa-microchip:before {
  content: "\F2DB"; }

.fa-microphone:before {
  content: "\F130"; }

.fa-microphone-alt:before {
  content: "\F3C9"; }

.fa-microphone-alt-slash:before {
  content: "\F539"; }

.fa-microphone-slash:before {
  content: "\F131"; }

.fa-microscope:before {
  content: "\F610"; }

.fa-microsoft:before {
  content: "\F3CA"; }

.fa-mind-share:before {
  content: "\F677"; }

.fa-minus:before {
  content: "\F068"; }

.fa-minus-circle:before {
  content: "\F056"; }

.fa-minus-hexagon:before {
  content: "\F307"; }

.fa-minus-octagon:before {
  content: "\F308"; }

.fa-minus-square:before {
  content: "\F146"; }

.fa-mistletoe:before {
  content: "\F7B4"; }

.fa-mitten:before {
  content: "\F7B5"; }

.fa-mix:before {
  content: "\F3CB"; }

.fa-mixcloud:before {
  content: "\F289"; }

.fa-mizuni:before {
  content: "\F3CC"; }

.fa-mobile:before {
  content: "\F10B"; }

.fa-mobile-alt:before {
  content: "\F3CD"; }

.fa-mobile-android:before {
  content: "\F3CE"; }

.fa-mobile-android-alt:before {
  content: "\F3CF"; }

.fa-modx:before {
  content: "\F285"; }

.fa-monero:before {
  content: "\F3D0"; }

.fa-money-bill:before {
  content: "\F0D6"; }

.fa-money-bill-alt:before {
  content: "\F3D1"; }

.fa-money-bill-wave:before {
  content: "\F53A"; }

.fa-money-bill-wave-alt:before {
  content: "\F53B"; }

.fa-money-check:before {
  content: "\F53C"; }

.fa-money-check-alt:before {
  content: "\F53D"; }

.fa-monitor-heart-rate:before {
  content: "\F611"; }

.fa-monkey:before {
  content: "\F6FB"; }

.fa-monument:before {
  content: "\F5A6"; }

.fa-moon:before {
  content: "\F186"; }

.fa-moon-cloud:before {
  content: "\F754"; }

.fa-moon-stars:before {
  content: "\F755"; }

.fa-mortar-pestle:before {
  content: "\F5A7"; }

.fa-mosque:before {
  content: "\F678"; }

.fa-motorcycle:before {
  content: "\F21C"; }

.fa-mountain:before {
  content: "\F6FC"; }

.fa-mountains:before {
  content: "\F6FD"; }

.fa-mouse-pointer:before {
  content: "\F245"; }

.fa-mug-hot:before {
  content: "\F7B6"; }

.fa-mug-marshmallows:before {
  content: "\F7B7"; }

.fa-music:before {
  content: "\F001"; }

.fa-napster:before {
  content: "\F3D2"; }

.fa-narwhal:before {
  content: "\F6FE"; }

.fa-neos:before {
  content: "\F612"; }

.fa-network-wired:before {
  content: "\F6FF"; }

.fa-neuter:before {
  content: "\F22C"; }

.fa-newspaper:before {
  content: "\F1EA"; }

.fa-nimblr:before {
  content: "\F5A8"; }

.fa-nintendo-switch:before {
  content: "\F418"; }

.fa-node:before {
  content: "\F419"; }

.fa-node-js:before {
  content: "\F3D3"; }

.fa-not-equal:before {
  content: "\F53E"; }

.fa-notes-medical:before {
  content: "\F481"; }

.fa-npm:before {
  content: "\F3D4"; }

.fa-ns8:before {
  content: "\F3D5"; }

.fa-nutritionix:before {
  content: "\F3D6"; }

.fa-object-group:before {
  content: "\F247"; }

.fa-object-ungroup:before {
  content: "\F248"; }

.fa-octagon:before {
  content: "\F306"; }

.fa-odnoklassniki:before {
  content: "\F263"; }

.fa-odnoklassniki-square:before {
  content: "\F264"; }

.fa-oil-can:before {
  content: "\F613"; }

.fa-oil-temp:before {
  content: "\F614"; }

.fa-old-republic:before {
  content: "\F510"; }

.fa-om:before {
  content: "\F679"; }

.fa-omega:before {
  content: "\F67A"; }

.fa-opencart:before {
  content: "\F23D"; }

.fa-openid:before {
  content: "\F19B"; }

.fa-opera:before {
  content: "\F26A"; }

.fa-optin-monster:before {
  content: "\F23C"; }

.fa-ornament:before {
  content: "\F7B8"; }

.fa-osi:before {
  content: "\F41A"; }

.fa-otter:before {
  content: "\F700"; }

.fa-outdent:before {
  content: "\F03B"; }

.fa-page4:before {
  content: "\F3D7"; }

.fa-pagelines:before {
  content: "\F18C"; }

.fa-pager:before {
  content: "\F815"; }

.fa-paint-brush:before {
  content: "\F1FC"; }

.fa-paint-brush-alt:before {
  content: "\F5A9"; }

.fa-paint-roller:before {
  content: "\F5AA"; }

.fa-palette:before {
  content: "\F53F"; }

.fa-palfed:before {
  content: "\F3D8"; }

.fa-pallet:before {
  content: "\F482"; }

.fa-pallet-alt:before {
  content: "\F483"; }

.fa-paper-plane:before {
  content: "\F1D8"; }

.fa-paperclip:before {
  content: "\F0C6"; }

.fa-parachute-box:before {
  content: "\F4CD"; }

.fa-paragraph:before {
  content: "\F1DD"; }

.fa-parking:before {
  content: "\F540"; }

.fa-parking-circle:before {
  content: "\F615"; }

.fa-parking-circle-slash:before {
  content: "\F616"; }

.fa-parking-slash:before {
  content: "\F617"; }

.fa-passport:before {
  content: "\F5AB"; }

.fa-pastafarianism:before {
  content: "\F67B"; }

.fa-paste:before {
  content: "\F0EA"; }

.fa-patreon:before {
  content: "\F3D9"; }

.fa-pause:before {
  content: "\F04C"; }

.fa-pause-circle:before {
  content: "\F28B"; }

.fa-paw:before {
  content: "\F1B0"; }

.fa-paw-alt:before {
  content: "\F701"; }

.fa-paw-claws:before {
  content: "\F702"; }

.fa-paypal:before {
  content: "\F1ED"; }

.fa-peace:before {
  content: "\F67C"; }

.fa-pegasus:before {
  content: "\F703"; }

.fa-pen:before {
  content: "\F304"; }

.fa-pen-alt:before {
  content: "\F305"; }

.fa-pen-fancy:before {
  content: "\F5AC"; }

.fa-pen-nib:before {
  content: "\F5AD"; }

.fa-pen-square:before {
  content: "\F14B"; }

.fa-pencil:before {
  content: "\F040"; }

.fa-pencil-alt:before {
  content: "\F303"; }

.fa-pencil-paintbrush:before {
  content: "\F618"; }

.fa-pencil-ruler:before {
  content: "\F5AE"; }

.fa-pennant:before {
  content: "\F456"; }

.fa-penny-arcade:before {
  content: "\F704"; }

.fa-people-carry:before {
  content: "\F4CE"; }

.fa-pepper-hot:before {
  content: "\F816"; }

.fa-percent:before {
  content: "\F295"; }

.fa-percentage:before {
  content: "\F541"; }

.fa-periscope:before {
  content: "\F3DA"; }

.fa-person-booth:before {
  content: "\F756"; }

.fa-person-carry:before {
  content: "\F4CF"; }

.fa-person-dolly:before {
  content: "\F4D0"; }

.fa-person-dolly-empty:before {
  content: "\F4D1"; }

.fa-person-sign:before {
  content: "\F757"; }

.fa-phabricator:before {
  content: "\F3DB"; }

.fa-phoenix-framework:before {
  content: "\F3DC"; }

.fa-phoenix-squadron:before {
  content: "\F511"; }

.fa-phone:before {
  content: "\F095"; }

.fa-phone-office:before {
  content: "\F67D"; }

.fa-phone-plus:before {
  content: "\F4D2"; }

.fa-phone-slash:before {
  content: "\F3DD"; }

.fa-phone-square:before {
  content: "\F098"; }

.fa-phone-volume:before {
  content: "\F2A0"; }

.fa-php:before {
  content: "\F457"; }

.fa-pi:before {
  content: "\F67E"; }

.fa-pie:before {
  content: "\F705"; }

.fa-pied-piper:before {
  content: "\F2AE"; }

.fa-pied-piper-alt:before {
  content: "\F1A8"; }

.fa-pied-piper-hat:before {
  content: "\F4E5"; }

.fa-pied-piper-pp:before {
  content: "\F1A7"; }

.fa-pig:before {
  content: "\F706"; }

.fa-piggy-bank:before {
  content: "\F4D3"; }

.fa-pills:before {
  content: "\F484"; }

.fa-pinterest:before {
  content: "\F0D2"; }

.fa-pinterest-p:before {
  content: "\F231"; }

.fa-pinterest-square:before {
  content: "\F0D3"; }

.fa-pizza:before {
  content: "\F817"; }

.fa-pizza-slice:before {
  content: "\F818"; }

.fa-place-of-worship:before {
  content: "\F67F"; }

.fa-plane:before {
  content: "\F072"; }

.fa-plane-alt:before {
  content: "\F3DE"; }

.fa-plane-arrival:before {
  content: "\F5AF"; }

.fa-plane-departure:before {
  content: "\F5B0"; }

.fa-play:before {
  content: "\F04B"; }

.fa-play-circle:before {
  content: "\F144"; }

.fa-playstation:before {
  content: "\F3DF"; }

.fa-plug:before {
  content: "\F1E6"; }

.fa-plus:before {
  content: "\F067"; }

.fa-plus-circle:before {
  content: "\F055"; }

.fa-plus-hexagon:before {
  content: "\F300"; }

.fa-plus-octagon:before {
  content: "\F301"; }

.fa-plus-square:before {
  content: "\F0FE"; }

.fa-podcast:before {
  content: "\F2CE"; }

.fa-podium:before {
  content: "\F680"; }

.fa-podium-star:before {
  content: "\F758"; }

.fa-poll:before {
  content: "\F681"; }

.fa-poll-h:before {
  content: "\F682"; }

.fa-poll-people:before {
  content: "\F759"; }

.fa-poo:before {
  content: "\F2FE"; }

.fa-poo-storm:before {
  content: "\F75A"; }

.fa-poop:before {
  content: "\F619"; }

.fa-popcorn:before {
  content: "\F819"; }

.fa-portrait:before {
  content: "\F3E0"; }

.fa-pound-sign:before {
  content: "\F154"; }

.fa-power-off:before {
  content: "\F011"; }

.fa-pray:before {
  content: "\F683"; }

.fa-praying-hands:before {
  content: "\F684"; }

.fa-prescription:before {
  content: "\F5B1"; }

.fa-prescription-bottle:before {
  content: "\F485"; }

.fa-prescription-bottle-alt:before {
  content: "\F486"; }

.fa-presentation:before {
  content: "\F685"; }

.fa-print:before {
  content: "\F02F"; }

.fa-print-search:before {
  content: "\F81A"; }

.fa-print-slash:before {
  content: "\F686"; }

.fa-procedures:before {
  content: "\F487"; }

.fa-product-hunt:before {
  content: "\F288"; }

.fa-project-diagram:before {
  content: "\F542"; }

.fa-pumpkin:before {
  content: "\F707"; }

.fa-pushed:before {
  content: "\F3E1"; }

.fa-puzzle-piece:before {
  content: "\F12E"; }

.fa-python:before {
  content: "\F3E2"; }

.fa-qq:before {
  content: "\F1D6"; }

.fa-qrcode:before {
  content: "\F029"; }

.fa-question:before {
  content: "\F128"; }

.fa-question-circle:before {
  content: "\F059"; }

.fa-question-square:before {
  content: "\F2FD"; }

.fa-quidditch:before {
  content: "\F458"; }

.fa-quinscape:before {
  content: "\F459"; }

.fa-quora:before {
  content: "\F2C4"; }

.fa-quote-left:before {
  content: "\F10D"; }

.fa-quote-right:before {
  content: "\F10E"; }

.fa-quran:before {
  content: "\F687"; }

.fa-r-project:before {
  content: "\F4F7"; }

.fa-rabbit:before {
  content: "\F708"; }

.fa-rabbit-fast:before {
  content: "\F709"; }

.fa-racquet:before {
  content: "\F45A"; }

.fa-radiation:before {
  content: "\F7B9"; }

.fa-radiation-alt:before {
  content: "\F7BA"; }

.fa-rainbow:before {
  content: "\F75B"; }

.fa-raindrops:before {
  content: "\F75C"; }

.fa-ram:before {
  content: "\F70A"; }

.fa-ramp-loading:before {
  content: "\F4D4"; }

.fa-random:before {
  content: "\F074"; }

.fa-raspberry-pi:before {
  content: "\F7BB"; }

.fa-ravelry:before {
  content: "\F2D9"; }

.fa-react:before {
  content: "\F41B"; }

.fa-reacteurope:before {
  content: "\F75D"; }

.fa-readme:before {
  content: "\F4D5"; }

.fa-rebel:before {
  content: "\F1D0"; }

.fa-receipt:before {
  content: "\F543"; }

.fa-rectangle-landscape:before {
  content: "\F2FA"; }

.fa-rectangle-portrait:before {
  content: "\F2FB"; }

.fa-rectangle-wide:before {
  content: "\F2FC"; }

.fa-recycle:before {
  content: "\F1B8"; }

.fa-red-river:before {
  content: "\F3E3"; }

.fa-reddit:before {
  content: "\F1A1"; }

.fa-reddit-alien:before {
  content: "\F281"; }

.fa-reddit-square:before {
  content: "\F1A2"; }

.fa-redhat:before {
  content: "\F7BC"; }

.fa-redo:before {
  content: "\F01E"; }

.fa-redo-alt:before {
  content: "\F2F9"; }

.fa-registered:before {
  content: "\F25D"; }

.fa-renren:before {
  content: "\F18B"; }

.fa-repeat:before {
  content: "\F363"; }

.fa-repeat-1:before {
  content: "\F365"; }

.fa-repeat-1-alt:before {
  content: "\F366"; }

.fa-repeat-alt:before {
  content: "\F364"; }

.fa-reply:before {
  content: "\F3E5"; }

.fa-reply-all:before {
  content: "\F122"; }

.fa-replyd:before {
  content: "\F3E6"; }

.fa-republican:before {
  content: "\F75E"; }

.fa-researchgate:before {
  content: "\F4F8"; }

.fa-resolving:before {
  content: "\F3E7"; }

.fa-restroom:before {
  content: "\F7BD"; }

.fa-retweet:before {
  content: "\F079"; }

.fa-retweet-alt:before {
  content: "\F361"; }

.fa-rev:before {
  content: "\F5B2"; }

.fa-ribbon:before {
  content: "\F4D6"; }

.fa-ring:before {
  content: "\F70B"; }

.fa-rings-wedding:before {
  content: "\F81B"; }

.fa-road:before {
  content: "\F018"; }

.fa-robot:before {
  content: "\F544"; }

.fa-rocket:before {
  content: "\F135"; }

.fa-rocketchat:before {
  content: "\F3E8"; }

.fa-rockrms:before {
  content: "\F3E9"; }

.fa-route:before {
  content: "\F4D7"; }

.fa-route-highway:before {
  content: "\F61A"; }

.fa-route-interstate:before {
  content: "\F61B"; }

.fa-rss:before {
  content: "\F09E"; }

.fa-rss-square:before {
  content: "\F143"; }

.fa-ruble-sign:before {
  content: "\F158"; }

.fa-ruler:before {
  content: "\F545"; }

.fa-ruler-combined:before {
  content: "\F546"; }

.fa-ruler-horizontal:before {
  content: "\F547"; }

.fa-ruler-triangle:before {
  content: "\F61C"; }

.fa-ruler-vertical:before {
  content: "\F548"; }

.fa-running:before {
  content: "\F70C"; }

.fa-rupee-sign:before {
  content: "\F156"; }

.fa-rv:before {
  content: "\F7BE"; }

.fa-sack:before {
  content: "\F81C"; }

.fa-sack-dollar:before {
  content: "\F81D"; }

.fa-sad-cry:before {
  content: "\F5B3"; }

.fa-sad-tear:before {
  content: "\F5B4"; }

.fa-safari:before {
  content: "\F267"; }

.fa-salad:before {
  content: "\F81E"; }

.fa-salesforce:before {
  content: "\F83B"; }

.fa-sandwich:before {
  content: "\F81F"; }

.fa-sass:before {
  content: "\F41E"; }

.fa-satellite:before {
  content: "\F7BF"; }

.fa-satellite-dish:before {
  content: "\F7C0"; }

.fa-sausage:before {
  content: "\F820"; }

.fa-save:before {
  content: "\F0C7"; }

.fa-scalpel:before {
  content: "\F61D"; }

.fa-scalpel-path:before {
  content: "\F61E"; }

.fa-scanner:before {
  content: "\F488"; }

.fa-scanner-keyboard:before {
  content: "\F489"; }

.fa-scanner-touchscreen:before {
  content: "\F48A"; }

.fa-scarecrow:before {
  content: "\F70D"; }

.fa-scarf:before {
  content: "\F7C1"; }

.fa-schlix:before {
  content: "\F3EA"; }

.fa-school:before {
  content: "\F549"; }

.fa-screwdriver:before {
  content: "\F54A"; }

.fa-scribd:before {
  content: "\F28A"; }

.fa-scroll:before {
  content: "\F70E"; }

.fa-scroll-old:before {
  content: "\F70F"; }

.fa-scrubber:before {
  content: "\F2F8"; }

.fa-scythe:before {
  content: "\F710"; }

.fa-sd-card:before {
  content: "\F7C2"; }

.fa-search:before {
  content: "\F002"; }

.fa-search-dollar:before {
  content: "\F688"; }

.fa-search-location:before {
  content: "\F689"; }

.fa-search-minus:before {
  content: "\F010"; }

.fa-search-plus:before {
  content: "\F00E"; }

.fa-searchengin:before {
  content: "\F3EB"; }

.fa-seedling:before {
  content: "\F4D8"; }

.fa-sellcast:before {
  content: "\F2DA"; }

.fa-sellsy:before {
  content: "\F213"; }

.fa-server:before {
  content: "\F233"; }

.fa-servicestack:before {
  content: "\F3EC"; }

.fa-shapes:before {
  content: "\F61F"; }

.fa-share:before {
  content: "\F064"; }

.fa-share-all:before {
  content: "\F367"; }

.fa-share-alt:before {
  content: "\F1E0"; }

.fa-share-alt-square:before {
  content: "\F1E1"; }

.fa-share-square:before {
  content: "\F14D"; }

.fa-sheep:before {
  content: "\F711"; }

.fa-shekel-sign:before {
  content: "\F20B"; }

.fa-shield:before {
  content: "\F132"; }

.fa-shield-alt:before {
  content: "\F3ED"; }

.fa-shield-check:before {
  content: "\F2F7"; }

.fa-shield-cross:before {
  content: "\F712"; }

.fa-ship:before {
  content: "\F21A"; }

.fa-shipping-fast:before {
  content: "\F48B"; }

.fa-shipping-timed:before {
  content: "\F48C"; }

.fa-shirtsinbulk:before {
  content: "\F214"; }

.fa-shish-kebab:before {
  content: "\F821"; }

.fa-shoe-prints:before {
  content: "\F54B"; }

.fa-shopping-bag:before {
  content: "\F290"; }

.fa-shopping-basket:before {
  content: "\F291"; }

.fa-shopping-cart:before {
  content: "\F07A"; }

.fa-shopware:before {
  content: "\F5B5"; }

.fa-shovel:before {
  content: "\F713"; }

.fa-shovel-snow:before {
  content: "\F7C3"; }

.fa-shower:before {
  content: "\F2CC"; }

.fa-shredder:before {
  content: "\F68A"; }

.fa-shuttle-van:before {
  content: "\F5B6"; }

.fa-shuttlecock:before {
  content: "\F45B"; }

.fa-sickle:before {
  content: "\F822"; }

.fa-sigma:before {
  content: "\F68B"; }

.fa-sign:before {
  content: "\F4D9"; }

.fa-sign-in:before {
  content: "\F090"; }

.fa-sign-in-alt:before {
  content: "\F2F6"; }

.fa-sign-language:before {
  content: "\F2A7"; }

.fa-sign-out:before {
  content: "\F08B"; }

.fa-sign-out-alt:before {
  content: "\F2F5"; }

.fa-signal:before {
  content: "\F012"; }

.fa-signal-1:before {
  content: "\F68C"; }

.fa-signal-2:before {
  content: "\F68D"; }

.fa-signal-3:before {
  content: "\F68E"; }

.fa-signal-4:before {
  content: "\F68F"; }

.fa-signal-alt:before {
  content: "\F690"; }

.fa-signal-alt-1:before {
  content: "\F691"; }

.fa-signal-alt-2:before {
  content: "\F692"; }

.fa-signal-alt-3:before {
  content: "\F693"; }

.fa-signal-alt-slash:before {
  content: "\F694"; }

.fa-signal-slash:before {
  content: "\F695"; }

.fa-signature:before {
  content: "\F5B7"; }

.fa-sim-card:before {
  content: "\F7C4"; }

.fa-simplybuilt:before {
  content: "\F215"; }

.fa-sistrix:before {
  content: "\F3EE"; }

.fa-sitemap:before {
  content: "\F0E8"; }

.fa-sith:before {
  content: "\F512"; }

.fa-skating:before {
  content: "\F7C5"; }

.fa-skeleton:before {
  content: "\F620"; }

.fa-sketch:before {
  content: "\F7C6"; }

.fa-ski-jump:before {
  content: "\F7C7"; }

.fa-ski-lift:before {
  content: "\F7C8"; }

.fa-skiing:before {
  content: "\F7C9"; }

.fa-skiing-nordic:before {
  content: "\F7CA"; }

.fa-skull:before {
  content: "\F54C"; }

.fa-skull-crossbones:before {
  content: "\F714"; }

.fa-skyatlas:before {
  content: "\F216"; }

.fa-skype:before {
  content: "\F17E"; }

.fa-slack:before {
  content: "\F198"; }

.fa-slack-hash:before {
  content: "\F3EF"; }

.fa-slash:before {
  content: "\F715"; }

.fa-sledding:before {
  content: "\F7CB"; }

.fa-sleigh:before {
  content: "\F7CC"; }

.fa-sliders-h:before {
  content: "\F1DE"; }

.fa-sliders-h-square:before {
  content: "\F3F0"; }

.fa-sliders-v:before {
  content: "\F3F1"; }

.fa-sliders-v-square:before {
  content: "\F3F2"; }

.fa-slideshare:before {
  content: "\F1E7"; }

.fa-smile:before {
  content: "\F118"; }

.fa-smile-beam:before {
  content: "\F5B8"; }

.fa-smile-plus:before {
  content: "\F5B9"; }

.fa-smile-wink:before {
  content: "\F4DA"; }

.fa-smog:before {
  content: "\F75F"; }

.fa-smoke:before {
  content: "\F760"; }

.fa-smoking:before {
  content: "\F48D"; }

.fa-smoking-ban:before {
  content: "\F54D"; }

.fa-sms:before {
  content: "\F7CD"; }

.fa-snake:before {
  content: "\F716"; }

.fa-snapchat:before {
  content: "\F2AB"; }

.fa-snapchat-ghost:before {
  content: "\F2AC"; }

.fa-snapchat-square:before {
  content: "\F2AD"; }

.fa-snow-blowing:before {
  content: "\F761"; }

.fa-snowboarding:before {
  content: "\F7CE"; }

.fa-snowflake:before {
  content: "\F2DC"; }

.fa-snowflakes:before {
  content: "\F7CF"; }

.fa-snowman:before {
  content: "\F7D0"; }

.fa-snowmobile:before {
  content: "\F7D1"; }

.fa-snowplow:before {
  content: "\F7D2"; }

.fa-socks:before {
  content: "\F696"; }

.fa-solar-panel:before {
  content: "\F5BA"; }

.fa-sort:before {
  content: "\F0DC"; }

.fa-sort-alpha-down:before {
  content: "\F15D"; }

.fa-sort-alpha-up:before {
  content: "\F15E"; }

.fa-sort-amount-down:before {
  content: "\F160"; }

.fa-sort-amount-up:before {
  content: "\F161"; }

.fa-sort-down:before {
  content: "\F0DD"; }

.fa-sort-numeric-down:before {
  content: "\F162"; }

.fa-sort-numeric-up:before {
  content: "\F163"; }

.fa-sort-up:before {
  content: "\F0DE"; }

.fa-soundcloud:before {
  content: "\F1BE"; }

.fa-soup:before {
  content: "\F823"; }

.fa-sourcetree:before {
  content: "\F7D3"; }

.fa-spa:before {
  content: "\F5BB"; }

.fa-space-shuttle:before {
  content: "\F197"; }

.fa-spade:before {
  content: "\F2F4"; }

.fa-speakap:before {
  content: "\F3F3"; }

.fa-speaker-deck:before {
  content: "\F83C"; }

.fa-spider:before {
  content: "\F717"; }

.fa-spider-black-widow:before {
  content: "\F718"; }

.fa-spider-web:before {
  content: "\F719"; }

.fa-spinner:before {
  content: "\F110"; }

.fa-spinner-third:before {
  content: "\F3F4"; }

.fa-splotch:before {
  content: "\F5BC"; }

.fa-spotify:before {
  content: "\F1BC"; }

.fa-spray-can:before {
  content: "\F5BD"; }

.fa-square:before {
  content: "\F0C8"; }

.fa-square-full:before {
  content: "\F45C"; }

.fa-square-root:before {
  content: "\F697"; }

.fa-square-root-alt:before {
  content: "\F698"; }

.fa-squarespace:before {
  content: "\F5BE"; }

.fa-squirrel:before {
  content: "\F71A"; }

.fa-stack-exchange:before {
  content: "\F18D"; }

.fa-stack-overflow:before {
  content: "\F16C"; }

.fa-staff:before {
  content: "\F71B"; }

.fa-stamp:before {
  content: "\F5BF"; }

.fa-star:before {
  content: "\F005"; }

.fa-star-and-crescent:before {
  content: "\F699"; }

.fa-star-christmas:before {
  content: "\F7D4"; }

.fa-star-exclamation:before {
  content: "\F2F3"; }

.fa-star-half:before {
  content: "\F089"; }

.fa-star-half-alt:before {
  content: "\F5C0"; }

.fa-star-of-david:before {
  content: "\F69A"; }

.fa-star-of-life:before {
  content: "\F621"; }

.fa-stars:before {
  content: "\F762"; }

.fa-staylinked:before {
  content: "\F3F5"; }

.fa-steak:before {
  content: "\F824"; }

.fa-steam:before {
  content: "\F1B6"; }

.fa-steam-square:before {
  content: "\F1B7"; }

.fa-steam-symbol:before {
  content: "\F3F6"; }

.fa-steering-wheel:before {
  content: "\F622"; }

.fa-step-backward:before {
  content: "\F048"; }

.fa-step-forward:before {
  content: "\F051"; }

.fa-stethoscope:before {
  content: "\F0F1"; }

.fa-sticker-mule:before {
  content: "\F3F7"; }

.fa-sticky-note:before {
  content: "\F249"; }

.fa-stocking:before {
  content: "\F7D5"; }

.fa-stomach:before {
  content: "\F623"; }

.fa-stop:before {
  content: "\F04D"; }

.fa-stop-circle:before {
  content: "\F28D"; }

.fa-stopwatch:before {
  content: "\F2F2"; }

.fa-store:before {
  content: "\F54E"; }

.fa-store-alt:before {
  content: "\F54F"; }

.fa-strava:before {
  content: "\F428"; }

.fa-stream:before {
  content: "\F550"; }

.fa-street-view:before {
  content: "\F21D"; }

.fa-stretcher:before {
  content: "\F825"; }

.fa-strikethrough:before {
  content: "\F0CC"; }

.fa-stripe:before {
  content: "\F429"; }

.fa-stripe-s:before {
  content: "\F42A"; }

.fa-stroopwafel:before {
  content: "\F551"; }

.fa-studiovinari:before {
  content: "\F3F8"; }

.fa-stumbleupon:before {
  content: "\F1A4"; }

.fa-stumbleupon-circle:before {
  content: "\F1A3"; }

.fa-subscript:before {
  content: "\F12C"; }

.fa-subway:before {
  content: "\F239"; }

.fa-suitcase:before {
  content: "\F0F2"; }

.fa-suitcase-rolling:before {
  content: "\F5C1"; }

.fa-sun:before {
  content: "\F185"; }

.fa-sun-cloud:before {
  content: "\F763"; }

.fa-sun-dust:before {
  content: "\F764"; }

.fa-sun-haze:before {
  content: "\F765"; }

.fa-sunrise:before {
  content: "\F766"; }

.fa-sunset:before {
  content: "\F767"; }

.fa-superpowers:before {
  content: "\F2DD"; }

.fa-superscript:before {
  content: "\F12B"; }

.fa-supple:before {
  content: "\F3F9"; }

.fa-surprise:before {
  content: "\F5C2"; }

.fa-suse:before {
  content: "\F7D6"; }

.fa-swatchbook:before {
  content: "\F5C3"; }

.fa-swimmer:before {
  content: "\F5C4"; }

.fa-swimming-pool:before {
  content: "\F5C5"; }

.fa-sword:before {
  content: "\F71C"; }

.fa-swords:before {
  content: "\F71D"; }

.fa-symfony:before {
  content: "\F83D"; }

.fa-synagogue:before {
  content: "\F69B"; }

.fa-sync:before {
  content: "\F021"; }

.fa-sync-alt:before {
  content: "\F2F1"; }

.fa-syringe:before {
  content: "\F48E"; }

.fa-table:before {
  content: "\F0CE"; }

.fa-table-tennis:before {
  content: "\F45D"; }

.fa-tablet:before {
  content: "\F10A"; }

.fa-tablet-alt:before {
  content: "\F3FA"; }

.fa-tablet-android:before {
  content: "\F3FB"; }

.fa-tablet-android-alt:before {
  content: "\F3FC"; }

.fa-tablet-rugged:before {
  content: "\F48F"; }

.fa-tablets:before {
  content: "\F490"; }

.fa-tachometer:before {
  content: "\F0E4"; }

.fa-tachometer-alt:before {
  content: "\F3FD"; }

.fa-tachometer-alt-average:before {
  content: "\F624"; }

.fa-tachometer-alt-fast:before {
  content: "\F625"; }

.fa-tachometer-alt-fastest:before {
  content: "\F626"; }

.fa-tachometer-alt-slow:before {
  content: "\F627"; }

.fa-tachometer-alt-slowest:before {
  content: "\F628"; }

.fa-tachometer-average:before {
  content: "\F629"; }

.fa-tachometer-fast:before {
  content: "\F62A"; }

.fa-tachometer-fastest:before {
  content: "\F62B"; }

.fa-tachometer-slow:before {
  content: "\F62C"; }

.fa-tachometer-slowest:before {
  content: "\F62D"; }

.fa-taco:before {
  content: "\F826"; }

.fa-tag:before {
  content: "\F02B"; }

.fa-tags:before {
  content: "\F02C"; }

.fa-tally:before {
  content: "\F69C"; }

.fa-tanakh:before {
  content: "\F827"; }

.fa-tape:before {
  content: "\F4DB"; }

.fa-tasks:before {
  content: "\F0AE"; }

.fa-tasks-alt:before {
  content: "\F828"; }

.fa-taxi:before {
  content: "\F1BA"; }

.fa-teamspeak:before {
  content: "\F4F9"; }

.fa-teeth:before {
  content: "\F62E"; }

.fa-teeth-open:before {
  content: "\F62F"; }

.fa-telegram:before {
  content: "\F2C6"; }

.fa-telegram-plane:before {
  content: "\F3FE"; }

.fa-temperature-frigid:before {
  content: "\F768"; }

.fa-temperature-high:before {
  content: "\F769"; }

.fa-temperature-hot:before {
  content: "\F76A"; }

.fa-temperature-low:before {
  content: "\F76B"; }

.fa-tencent-weibo:before {
  content: "\F1D5"; }

.fa-tenge:before {
  content: "\F7D7"; }

.fa-tennis-ball:before {
  content: "\F45E"; }

.fa-terminal:before {
  content: "\F120"; }

.fa-text-height:before {
  content: "\F034"; }

.fa-text-width:before {
  content: "\F035"; }

.fa-th:before {
  content: "\F00A"; }

.fa-th-large:before {
  content: "\F009"; }

.fa-th-list:before {
  content: "\F00B"; }

.fa-the-red-yeti:before {
  content: "\F69D"; }

.fa-theater-masks:before {
  content: "\F630"; }

.fa-themeco:before {
  content: "\F5C6"; }

.fa-themeisle:before {
  content: "\F2B2"; }

.fa-thermometer:before {
  content: "\F491"; }

.fa-thermometer-empty:before {
  content: "\F2CB"; }

.fa-thermometer-full:before {
  content: "\F2C7"; }

.fa-thermometer-half:before {
  content: "\F2C9"; }

.fa-thermometer-quarter:before {
  content: "\F2CA"; }

.fa-thermometer-three-quarters:before {
  content: "\F2C8"; }

.fa-theta:before {
  content: "\F69E"; }

.fa-think-peaks:before {
  content: "\F731"; }

.fa-thumbs-down:before {
  content: "\F165"; }

.fa-thumbs-up:before {
  content: "\F164"; }

.fa-thumbtack:before {
  content: "\F08D"; }

.fa-thunderstorm:before {
  content: "\F76C"; }

.fa-thunderstorm-moon:before {
  content: "\F76D"; }

.fa-thunderstorm-sun:before {
  content: "\F76E"; }

.fa-ticket:before {
  content: "\F145"; }

.fa-ticket-alt:before {
  content: "\F3FF"; }

.fa-tilde:before {
  content: "\F69F"; }

.fa-times:before {
  content: "\F00D"; }

.fa-times-circle:before {
  content: "\F057"; }

.fa-times-hexagon:before {
  content: "\F2EE"; }

.fa-times-octagon:before {
  content: "\F2F0"; }

.fa-times-square:before {
  content: "\F2D3"; }

.fa-tint:before {
  content: "\F043"; }

.fa-tint-slash:before {
  content: "\F5C7"; }

.fa-tire:before {
  content: "\F631"; }

.fa-tire-flat:before {
  content: "\F632"; }

.fa-tire-pressure-warning:before {
  content: "\F633"; }

.fa-tire-rugged:before {
  content: "\F634"; }

.fa-tired:before {
  content: "\F5C8"; }

.fa-toggle-off:before {
  content: "\F204"; }

.fa-toggle-on:before {
  content: "\F205"; }

.fa-toilet:before {
  content: "\F7D8"; }

.fa-toilet-paper:before {
  content: "\F71E"; }

.fa-toilet-paper-alt:before {
  content: "\F71F"; }

.fa-tombstone:before {
  content: "\F720"; }

.fa-tombstone-alt:before {
  content: "\F721"; }

.fa-toolbox:before {
  content: "\F552"; }

.fa-tools:before {
  content: "\F7D9"; }

.fa-tooth:before {
  content: "\F5C9"; }

.fa-toothbrush:before {
  content: "\F635"; }

.fa-torah:before {
  content: "\F6A0"; }

.fa-torii-gate:before {
  content: "\F6A1"; }

.fa-tornado:before {
  content: "\F76F"; }

.fa-tractor:before {
  content: "\F722"; }

.fa-trade-federation:before {
  content: "\F513"; }

.fa-trademark:before {
  content: "\F25C"; }

.fa-traffic-cone:before {
  content: "\F636"; }

.fa-traffic-light:before {
  content: "\F637"; }

.fa-traffic-light-go:before {
  content: "\F638"; }

.fa-traffic-light-slow:before {
  content: "\F639"; }

.fa-traffic-light-stop:before {
  content: "\F63A"; }

.fa-train:before {
  content: "\F238"; }

.fa-tram:before {
  content: "\F7DA"; }

.fa-transgender:before {
  content: "\F224"; }

.fa-transgender-alt:before {
  content: "\F225"; }

.fa-trash:before {
  content: "\F1F8"; }

.fa-trash-alt:before {
  content: "\F2ED"; }

.fa-trash-restore:before {
  content: "\F829"; }

.fa-trash-restore-alt:before {
  content: "\F82A"; }

.fa-treasure-chest:before {
  content: "\F723"; }

.fa-tree:before {
  content: "\F1BB"; }

.fa-tree-alt:before {
  content: "\F400"; }

.fa-tree-christmas:before {
  content: "\F7DB"; }

.fa-tree-decorated:before {
  content: "\F7DC"; }

.fa-tree-large:before {
  content: "\F7DD"; }

.fa-tree-palm:before {
  content: "\F82B"; }

.fa-trees:before {
  content: "\F724"; }

.fa-trello:before {
  content: "\F181"; }

.fa-triangle:before {
  content: "\F2EC"; }

.fa-tripadvisor:before {
  content: "\F262"; }

.fa-trophy:before {
  content: "\F091"; }

.fa-trophy-alt:before {
  content: "\F2EB"; }

.fa-truck:before {
  content: "\F0D1"; }

.fa-truck  er-container:before {
  content: "\F4DC"; }

.fa-truck-couch:before {
  content: "\F4DD"; }

.fa-truck-loading:before {
  content: "\F4DE"; }

.fa-truck-monster:before {
  content: "\F63B"; }

.fa-truck-moving:before {
  content: "\F4DF"; }

.fa-truck-pickup:before {
  content: "\F63C"; }

.fa-truck-plow:before {
  content: "\F7DE"; }

.fa-truck-ramp:before {
  content: "\F4E0"; }

.fa-tshirt:before {
  content: "\F553"; }

.fa-tty:before {
  content: "\F1E4"; }

.fa-tumblr:before {
  content: "\F173"; }

.fa-tumblr-square:before {
  content: "\F174"; }

.fa-turkey:before {
  content: "\F725"; }

.fa-turtle:before {
  content: "\F726"; }

.fa-tv:before {
  content: "\F26C"; }

.fa-tv-retro:before {
  content: "\F401"; }

.fa-twitch:before {
  content: "\F1E8"; }

.fa-twitter:before {
  content: "\F099"; }

.fa-twitter-square:before {
  content: "\F081"; }

.fa-typo3:before {
  content: "\F42B"; }

.fa-uber:before {
  content: "\F402"; }

.fa-ubuntu:before {
  content: "\F7DF"; }

.fa-uikit:before {
  content: "\F403"; }

.fa-umbrella:before {
  content: "\F0E9"; }

.fa-umbrella-beach:before {
  content: "\F5CA"; }

.fa-underline:before {
  content: "\F0CD"; }

.fa-undo:before {
  content: "\F0E2"; }

.fa-undo-alt:before {
  content: "\F2EA"; }

.fa-unicorn:before {
  content: "\F727"; }

.fa-union:before {
  content: "\F6A2"; }

.fa-uniregistry:before {
  content: "\F404"; }

.fa-universal-access:before {
  content: "\F29A"; }

.fa-university:before {
  content: "\F19C"; }

.fa-unlink:before {
  content: "\F127"; }

.fa-unlock:before {
  content: "\F09C"; }

.fa-unlock-alt:before {
  content: "\F13E"; }

.fa-untappd:before {
  content: "\F405"; }

.fa-upload:before {
  content: "\F093"; }

.fa-ups:before {
  content: "\F7E0"; }

.fa-usb:before {
  content: "\F287"; }

.fa-usd-circle:before {
  content: "\F2E8"; }

.fa-usd-square:before {
  content: "\F2E9"; }

.fa-user:before {
  content: "\F007"; }

.fa-user-alt:before {
  content: "\F406"; }

.fa-user-alt-slash:before {
  content: "\F4FA"; }

.fa-user-astronaut:before {
  content: "\F4FB"; }

.fa-user-chart:before {
  content: "\F6A3"; }

.fa-user-check:before {
  content: "\F4FC"; }

.fa-user-circle:before {
  content: "\F2BD"; }

.fa-user-clock:before {
  content: "\F4FD"; }

.fa-user-cog:before {
  content: "\F4FE"; }

.fa-user-crown:before {
  content: "\F6A4"; }

.fa-user-edit:before {
  content: "\F4FF"; }

.fa-user-friends:before {
  content: "\F500"; }

.fa-user-graduate:before {
  content: "\F501"; }

.fa-user-hard-hat:before {
  content: "\F82C"; }

.fa-user-headset:before {
  content: "\F82D"; }

.fa-user-injured:before {
  content: "\F728"; }

.fa-user-lock:before {
  content: "\F502"; }

.fa-user-md:before {
  content: "\F0F0"; }

.fa-user-md-chat:before {
  content: "\F82E"; }

.fa-user-minus:before {
  content: "\F503"; }

.fa-user-ninja:before {
  content: "\F504"; }

.fa-user-nurse:before {
  content: "\F82F"; }

.fa-user-plus:before {
  content: "\F234"; }

.fa-user-secret:before {
  content: "\F21B"; }

.fa-user-shield:before {
  content: "\F505"; }

.fa-user-slash:before {
  content: "\F506"; }

.fa-user-tag:before {
  content: "\F507"; }

.fa-user-tie:before {
  content: "\F508"; }

.fa-user-times:before {
  content: "\F235"; }

.fa-users:before {
  content: "\F0C0"; }

.fa-users-class:before {
  content: "\F63D"; }

.fa-users-cog:before {
  content: "\F509"; }

.fa-users-crown:before {
  content: "\F6A5"; }

.fa-users-medical:before {
  content: "\F830"; }

.fa-usps:before {
  content: "\F7E1"; }

.fa-ussunnah:before {
  content: "\F407"; }

.fa-utensil-fork:before {
  content: "\F2E3"; }

.fa-utensil-knife:before {
  content: "\F2E4"; }

.fa-utensil-spoon:before {
  content: "\F2E5"; }

.fa-utensils:before {
  content: "\F2E7"; }

.fa-utensils-alt:before {
  content: "\F2E6"; }

.fa-vaadin:before {
  content: "\F408"; }

.fa-value-absolute:before {
  content: "\F6A6"; }

.fa-vector-square:before {
  content: "\F5CB"; }

.fa-venus:before {
  content: "\F221"; }

.fa-venus-double:before {
  content: "\F226"; }

.fa-venus-mars:before {
  content: "\F228"; }

.fa-viacoin:before {
  content: "\F237"; }

.fa-viadeo:before {
  content: "\F2A9"; }

.fa-viadeo-square:before {
  content: "\F2AA"; }

.fa-vial:before {
  content: "\F492"; }

.fa-vials:before {
  content: "\F493"; }

.fa-viber:before {
  content: "\F409"; }

.fa-video:before {
  content: "\F03D"; }

.fa-video-plus:before {
  content: "\F4E1"; }

.fa-video-slash:before {
  content: "\F4E2"; }

.fa-vihara:before {
  content: "\F6A7"; }

.fa-vimeo:before {
  content: "\F40A"; }

.fa-vimeo-square:before {
  content: "\F194"; }

.fa-vimeo-v:before {
  content: "\F27D"; }

.fa-vine:before {
  content: "\F1CA"; }

.fa-vk:before {
  content: "\F189"; }

.fa-vnv:before {
  content: "\F40B"; }

.fa-volcano:before {
  content: "\F770"; }

.fa-volleyball-ball:before {
  content: "\F45F"; }

.fa-volume:before {
  content: "\F6A8"; }

.fa-volume-down:before {
  content: "\F027"; }

.fa-volume-mute:before {
  content: "\F6A9"; }

.fa-volume-off:before {
  content: "\F026"; }

.fa-volume-slash:before {
  content: "\F2E2"; }

.fa-volume-up:before {
  content: "\F028"; }

.fa-vote-nay:before {
  content: "\F771"; }

.fa-vote-yea:before {
  content: "\F772"; }

.fa-vr-cardboard:before {
  content: "\F729"; }

.fa-vuejs:before {
  content: "\F41F"; }

.fa-walker:before {
  content: "\F831"; }

.fa-walking:before {
  content: "\F554"; }

.fa-wallet:before {
  content: "\F555"; }

.fa-wand:before {
  content: "\F72A"; }

.fa-wand-magic:before {
  content: "\F72B"; }

.fa-warehouse:before {
  content: "\F494"; }

.fa-warehouse-alt:before {
  content: "\F495"; }

.fa-watch:before {
  content: "\F2E1"; }

.fa-watch-fitness:before {
  content: "\F63E"; }

.fa-water:before {
  content: "\F773"; }

.fa-water-lower:before {
  content: "\F774"; }

.fa-water-rise:before {
  content: "\F775"; }

.fa-wave-square:before {
  content: "\F83E"; }

.fa-waze:before {
  content: "\F83F"; }

.fa-webcam:before {
  content: "\F832"; }

.fa-webcam-slash:before {
  content: "\F833"; }

.fa-weebly:before {
  content: "\F5CC"; }

.fa-weibo:before {
  content: "\F18A"; }

.fa-weight:before {
  content: "\F496"; }

.fa-weight-hanging:before {
  content: "\F5CD"; }

.fa-weixin:before {
  content: "\F1D7"; }

.fa-whale:before {
  content: "\F72C"; }

.fa-whatsapp:before {
  content: "\F232"; }

.fa-whatsapp-square:before {
  content: "\F40C"; }

.fa-wheat:before {
  content: "\F72D"; }

.fa-wheelchair:before {
  content: "\F193"; }

.fa-whistle:before {
  content: "\F460"; }

.fa-whmcs:before {
  content: "\F40D"; }

.fa-wifi:before {
  content: "\F1EB"; }

.fa-wifi-1:before {
  content: "\F6AA"; }

.fa-wifi-2:before {
  content: "\F6AB"; }

.fa-wifi-slash:before {
  content: "\F6AC"; }

.fa-wikipedia-w:before {
  content: "\F266"; }

.fa-wind:before {
  content: "\F72E"; }

.fa-wind-warning:before {
  content: "\F776"; }

.fa-window:before {
  content: "\F40E"; }

.fa-window-alt:before {
  content: "\F40F"; }

.fa-window-close:before {
  content: "\F410"; }

.fa-window-maximize:before {
  content: "\F2D0"; }

.fa-window-minimize:before {
  content: "\F2D1"; }

.fa-window-restore:before {
  content: "\F2D2"; }

.fa-windows:before {
  content: "\F17A"; }

.fa-windsock:before {
  content: "\F777"; }

.fa-wine-bottle:before {
  content: "\F72F"; }

.fa-wine-glass:before {
  content: "\F4E3"; }

.fa-wine-glass-alt:before {
  content: "\F5CE"; }

.fa-wix:before {
  content: "\F5CF"; }

.fa-wizards-of-the-coast:before {
  content: "\F730"; }

.fa-wolf-pack-battalion:before {
  content: "\F514"; }

.fa-won-sign:before {
  content: "\F159"; }

.fa-wordpress:before {
  content: "\F19A"; }

.fa-wordpress-simple:before {
  content: "\F411"; }

.fa-wpbeginner:before {
  content: "\F297"; }

.fa-wpexplorer:before {
  content: "\F2DE"; }

.fa-wpforms:before {
  content: "\F298"; }

.fa-wpressr:before {
  content: "\F3E4"; }

.fa-wreath:before {
  content: "\F7E2"; }

.fa-wrench:before {
  content: "\F0AD"; }

.fa-x-ray:before {
  content: "\F497"; }

.fa-xbox:before {
  content: "\F412"; }

.fa-xing:before {
  content: "\F168"; }

.fa-xing-square:before {
  content: "\F169"; }

.fa-y-combinator:before {
  content: "\F23B"; }

.fa-yahoo:before {
  content: "\F19E"; }

.fa-yammer:before {
  content: "\F840"; }

.fa-yandex:before {
  content: "\F413"; }

.fa-yandex-international:before {
  content: "\F414"; }

.fa-yarn:before {
  content: "\F7E3"; }

.fa-yelp:before {
  content: "\F1E9"; }

.fa-yen-sign:before {
  content: "\F157"; }

.fa-yin-yang:before {
  content: "\F6AD"; }

.fa-yoast:before {
  content: "\F2B1"; }

.fa-youtube:before {
  content: "\F167"; }

.fa-youtube-square:before {
  content: "\F431"; }

.fa-zhihu:before {
  content: "\F63F"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url(/static/media/fa-brands-400.3bdfbaeb.eot);
  /* src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg"); } */
  src: url(/static/media/fa-brands-400.3bdfbaeb.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-brands-400.dc83ddbb.woff2) format("woff2"), url(/static/media/fa-brands-400.88985513.woff) format("woff"), url(/static/media/fa-brands-400.4005f889.ttf) format("truetype")}

.fab {
  font-family: 'Font Awesome 5 Brands'; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(/static/media/fa-light-300.c72987d0.eot);
  src: url(/static/media/fa-light-300.c72987d0.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-light-300.6b1c4bcb.woff2) format("woff2"), url(/static/media/fa-light-300.a726cedb.woff) format("woff"), url(/static/media/fa-light-300.1b7d9530.ttf) format("truetype")}
  /* src: url("../fonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.woff") format("woff"), url("../fonts/fa-light-300.ttf") format("truetype"), url("../fonts/fa-light-300.svg#fontawesome") format("svg"); } */

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(/static/media/fa-regular-400.3593cd95.eot);
  /* src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg"); } */
  src: url(/static/media/fa-regular-400.3593cd95.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-regular-400.935ae23b.woff2) format("woff2"), url(/static/media/fa-regular-400.23458a3f.woff) format("woff"), url(/static/media/fa-regular-400.e6070157.ttf) format("truetype")}

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(/static/media/fa-solid-900.6628fa0a.eot);
  /* src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg"); } */
  src: url(/static/media/fa-solid-900.6628fa0a.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-solid-900.525bd4ae.woff2) format("woff2"), url(/static/media/fa-solid-900.ae249d48.woff) format("woff"), url(/static/media/fa-solid-900.7a24caca.ttf) format("truetype")}

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before,
  .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    font-family: "Lato", sans-serif;
    font-weight: 700;
}

.react-calendar {
    width: 100%;
    background: transparent;
    border: none;
}

.react-calendar__navigation .react-calendar__navigation__label {
    font-size: 22px;
}

.react-calendar__navigation button {
    font-size: 28px;
    font-weight: 500;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}

.react-calendar__month-view__days__day--weekend {
    color: #000000;
}

.react-calendar__tile--now {
    background: #D3D3D3;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #D3D3D3;
}

.react-calendar__tile--active {
    background: #7f13ab;
    color: #ffffff;
}


.react-calendar__month-view__days__day--neighboringMonth {
    color: #D3D3D3;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #7f13ab;
}

abbr[data-original-title],
abbr[title] {
    text-decoration: none;
    cursor: default;
}

.react-datepicker__portal {
    z-index: 999;
}
/*-----------------------------------------------------------------------------------

  Version: 1.0

-----------------------------------------------------------------------------------

  CSS INDEX
  ===================

  01. Theme default CSS
	02. Header
  03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/
/*===========================
    1. COMMON css 
===========================*/

body {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  color: #42464f;
  overflow-x: hidden;
}



a {
  text-decoration: none !important;

}

/* ==========================================vue */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  outline: none;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accesories:hover {
  transition: 0.3s ease;
  background-color: #F9C800;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  color: #111;
}

img {
  max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
  transition: all linear 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
  color: #0b1125;
  margin: 0px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  margin: 0px;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.slick-slide {
  outline: 0;
}

.text-theme {
  color: #7f13ab !important;
}

/*===== All Button Style =====*/
.buynow-btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  color: #7f13ab;
  border: 1px solid #7f13ab !important;
  padding: 0 30px;
  font-size: 15px;
  line-height: 35px;
  border-radius: 6px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-out 0s;
}

.buynow-btn:hover {
  background-color: #7f13ab !important;
  color: #f5f5f5 !important;
  border: 1px solid #f5f5f5 !important;
}

.CourseListBlockFull:hover .buynow-btn {
  background-color: #7f13ab;
  color: #ffffff;
}

.CourseListBlockFull:hover {
  background-color: #f5f5f5;
}

.main-btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0b1125;
  border: 1px solid #0b1125;
  color: #ffffff;
  padding: 0 30px;
  font-size: 15px;
  line-height: 35px;
  border-radius: 6px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-out 0s;
}

@media only screen and (min-width: 10px) and (max-width: 991px) {
  .main-btn {
    padding: 5px;
    font-size: 12px;
    padding: 7px;
    font-size: 21px;
    line-height: unset;
    border-radius: 0px;
  }
}

.main-btn:hover {
  background-color: #f9c800;
  border-color: #f9c800;
  color: #ffffff;
}

.main-btn.main-btn-2 {
  background-color: transparent;
  color: #7f13ab;
  border-color: #7f13ab;
}

.main-btn.main-btn-2:hover {
  background-color: #7f13ab;
  border-color: #7f13ab;
  color: #fff;
}

.appie-section-title {
  text-align: center;
  margin-bottom: 50px;
}

.appie-section-title .appie-title {
  font-size: 38px;
}

@media (max-width: 767px) {
  .appie-section-title {
    margin-bottom: 30px;
  }

  .appie-section-title .appie-title {
    font-size: 28px;
  }
}

.appie-section-title .main-btn i {
  margin-left: 10px;
}

.appie-section-title .btnBox {
  display: flex;
  align-items: center;
}

.appie-section-title .btnBox .main-btn {
  border: 1px solid #000000;
  background-color: transparent;
  color: #000000;
  padding: 0px 20px;
}

.appie-section-title .btnBox .main-btn:hover {
  border: 1px solid #7f13ab;
  color: #7f13ab;
}

.appie-section-title .btnBox .main-btn i {
  margin-left: 0px;
  margin-right: 10px;
}

.appie-section-title .btnBox select {
  width: auto;
  padding: 0px 20px;
  height: 47px;
  margin-left: 10px;
  border: 1px solid #000000;
  background-color: transparent;
  color: #000000;
}

.appie-section-title .input-box {
  position: relative;
}

.appie-section-title .input-box input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #dddddd;
  padding-left: 10px;
}

.appie-section-title .input-box button {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  border-radius: 50%;
  border: 0;
  color: #000000;
}

.appie-section-title .input-box button:hover {
  color: #7f13ab;
}

.appie-section-title.appie-section-title-2 .appie-title {
  color: #fff;
}

.appie-section-title.appie-section-title-2 p {
  color: #fff;
}

.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 999;
  display: none;
}

.back-to-top a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background: #f9c800;
  border: 2px solid #f9c800;
  border-radius: 6px;
  color: #fff;
}

.back-to-top a:hover {
  background: transparent;
  color: #f9c800;
}

.back-to-top.back-to-top-2 a {
  background: #ff3e66;
}

.back-to-top.back-to-top-3 a {
  background: #ff6b58;
}

.back-to-top.back-to-top-6 a {
  background: #9b2cfa;
}

.back-to-top.back-to-top-5 a {
  background: #801f82;
}

.back-to-top.back-to-top-8 a {
  background: #db0f30;
}

.off_canvars_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9998;
  opacity: 0;
  visibility: hidden;
  cursor: crosshair;
  background: #232323;
  top: 0;
  transition: all linear 0.3s;
}

.off_canvars_overlay.active {
  opacity: 0.5;
  visibility: visible;
}

.offcanvas_menu {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas_menu {
    display: block;
  }
}

@media (max-width: 767px) {
  .offcanvas_menu {
    display: block;
  }
}

.offcanvas_menu_wrapper {
  width: 300px;
  position: fixed;
  background: #fff;
  z-index: 999999;
  top: 0;
  height: 100%;
  transition: 0.5s;
  left: 0;
  margin-left: -300px;
  /* padding: 40px 15px 30px; */
  padding: 20px;
  overflow-y: scroll;
}

.offcanvas_menu_wrapper.active {
  margin-left: 0;
}

.offcanvas_menu_wrapper .slinky-theme-default {
  background: inherit;
  min-height: 300px;
  overflow-y: auto;
}

.offcanvas_menu_wrapper .header-btn {
  margin-bottom: 30px;
}

.offcanvas_menu_wrapper .header-btn a {
  color: #222;
}

.offcanvas_menu_wrapper .header-btn a:hover {
  color: #222;
}

.offcanvas_main_menu li {
  position: relative;
}

.offcanvas_main_menu li:last-child {
  margin: 0;
}

.offcanvas_main_menu li span.menu-expand {
  position: absolute;
  right: 0;
}

.offcanvas_main_menu li a {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ededed;
  color: #222;
  transition: all 0.3s ease-out 0s;
}

.offcanvas_main_menu li a:hover {
  color: #7f13ab;
}

.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20px;
}

.offcanvas_footer {
  padding-bottom: 50px;
  text-align: center;
}

.offcanvas_footer span a {
  font-size: 14px;
  color: #222;
  transition: all 0.3s ease-out 0s;
}

.offcanvas_footer span a:hover {
  color: #7f13ab;
}

.offcanvas_menu_wrapper.active .canvas_close {
  opacity: 1;
  visibility: visible;
}

.canvas_close {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
}

.canvas_close a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  width: 35px;
  height: 35px;
  display: block;
  text-align: center;
  line-height: 35px;
  border: 0px solid #7f13ab;
  border-radius: 6px;
  background: transparent;
  color: #000;
  transition: all 0.3s ease-out 0s;
}

.canvas_close a:hover {
  background: #000000;
  border-color: #000000;
  color: #fff;
}

.offcanvas-brand {
  width: 180px;
}

.canvas_open a {
  font-size: 26px;
  width: 50px;
  height: 42px;
  display: block;
  line-height: 39px;
  text-align: center;
  border: 1px solid #232323;
  color: #222;
  transition: all 0.3s ease-out 0s;
}

.canvas_open a:hover {
  color: #7f13ab;
  border-color: #7f13ab;
}

.offcanvas-social {
  margin-bottom: 40px;
  margin-top: 40px;
}

.offcanvas-social ul li {
  display: inline-block;
}

.offcanvas-social ul li a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  color: #7f13ab;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  margin: 0 5px;
  transition: all 0.3s ease-out 0s;
}

.offcanvas-social ul li a:hover {
  background: #7f13ab;
  border-color: #7f13ab;
  color: #fff;
}

.toggle-btn {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #000000;
}

.canvas_open {
  left: -10px;
  right: unset;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(/static/media/preloader.d88021b3.svg);
}

.preloader-close {
  position: fixed;
  z-index: 999999;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  right: 40px;
  bottom: 40px;
  font-weight: 600;
  line-height: 60px;
  background: #7f13ab;
  padding: 0 15px;
  border-radius: 5px;
}

.loader-wrap {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

.loader-wrap .layer-one {
  position: absolute;
  left: 0%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-two {
  position: absolute;
  left: 33.3333%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-three {
  position: absolute;
  left: 66.6666%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f4f9ff;
}

/*===========================
    SEARCH css 
===========================*/
.search-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999999;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  transition: all 0.6s ease-out 0s;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
}

.search-box .search-header .search-title {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

.search-box .search-header .search-close button {
  background: none;
  border: 0;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  padding-right: 35px;
  position: relative;
}

.search-box .search-header .search-close button span {
  width: 21px;
  height: 2px;
  background-color: #fff;
  display: block;
  position: absolute;
  right: 0;
}

.search-box .search-header .search-close button span:nth-child(1) {
  top: 13px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.search-box .search-header .search-close button span:nth-child(2) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 13px;
}

.search-box .search-body {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.search-box .search-body .search-form {
  position: relative;
}

.search-box .search-body .search-form input {
  width: 100%;
  border: 0;
  height: 80px;
  padding-left: 30px;
  border: 2px solid #fff;
  border-radius: 50px;
  font-size: 20px;
  color: #707070;
}

.search-box .search-body .search-form input::-webkit-input-placeholder {
  color: #707070;
  opacity: 1;
}

.search-box .search-body .search-form input::placeholder {
  color: #707070;
  opacity: 1;
}

.search-box .search-body .search-form button {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 60px;
  font-size: 36px;
  color: #707070;
  background: none;
  border: 0;
}

.search-box .search-footer {
  padding-bottom: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.search-box .search-footer .search-footer-content h4 {
  color: #707070;
  font-size: 24px;
}

.search-box .search-footer .search-footer-content p {
  color: #222;
  font-size: 16px;
}

.search-box.open {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/*===========================
    SHOPPING CART css 
===========================*/
.amm-shopping-cart-wrapper .overlay::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #222;
  opacity: 0;
  z-index: 99999;
  visibility: visible;
  transition: all 0.2s ease-out 0s;
  visibility: hidden;
}

.amm-shopping-cart-wrapper .overlay.open::before {
  opacity: 0.5;
  visibility: visible;
}

.amm-shopping-cart-canvas {
  position: fixed;
  width: 400px;
  height: 100%;
  background-color: #fff;
  z-index: 99999999;
  top: 0;
  right: -400px;
  padding: 40px 40px;
  transition: all 0.4s ease-out 0s;
}

@media (max-width: 767px) {
  .amm-shopping-cart-canvas {
    width: 320px;
  }
}

.amm-shopping-cart-canvas.open {
  right: 0;
}

.amm-shopping-cart-canvas .amm-shopping_cart {
  position: relative;
  height: 100%;
  width: 100%;
}

.amm-shopping-cart-canvas.amm-shopping-cart-leftbar {
  right: auto;
  left: -400px;
}

.amm-shopping-cart-canvas.amm-shopping-cart-leftbar.open {
  left: 0;
}

.amm-shopping_cart-list-items {
  overflow-y: auto;
  width: 100%;
  height: 50%;
}

.amm-shopping_cart-list-items ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.amm-shopping_cart-list-items ul li {
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
}

.amm-shopping_cart-list-items ul li:last-child {
  border-bottom: 0;
}

.amm-single-shopping-cart {
  display: flex;
}

.amm-single-shopping-cart .cart-content {
  position: relative;
  padding-right: 30px;
}

.amm-single-shopping-cart .cart-content h6 a {
  font-size: 16px;
  font-weight: 700;
  color: #222;
}

.amm-single-shopping-cart .cart-content .quality {
  font-size: 14px;
  color: #545454;
  display: block;
}

.amm-single-shopping-cart .cart-content .price {
  font-size: 14px;
  color: #7f13ab;
}

.amm-single-shopping-cart .cart-content .price-discount {
  font-size: 14px;
  color: #7f13ab;
}

.amm-single-shopping-cart .cart-content .price-close {
  font-size: 12px;
  color: #545454;
  position: relative;
}

.amm-single-shopping-cart .cart-content .price-close::before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #545454;
}

.amm-single-shopping-cart .cart-content .remove {
  font-size: 14px;
  color: #ccc;
  position: absolute;
  top: 0;
  right: 0;
}

.amm-shopping_cart-top-bar h6 {
  font-size: 24px;
  font-weight: 600;
  color: #222;
}

.amm-shopping_cart-top-bar button {
  width: 30px;
  height: 30px;
  padding: 0;
  border: 0;
  font-size: 30px;
  background: none;
}

.amm-shopping_cart-btn {
  border-top: 1px solid #ccc;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 99;
}

.amm-shopping_cart-btn .tota h5 {
  font-size: 18px;
  font-weight: 400;
  color: #222;
}

.amm-shopping_cart-btn .tota p {
  font-size: 18px;
  color: #7f13ab;
}

.amm-shopping_cart-btn .cart-btn a {
  margin: 5px 0;
  width: 100%;
}

/*===========================
    2.HEADER css 
===========================*/
.appie-sticky.sticky {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1010;
  background: #fff;
  background: rgba(255, 255, 255, 0.88);
  -webkit-animation: sticky 1.2s;
          animation: sticky 1.2s;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.appie-header-area {
  position: relative;
}

.appie-header-area .headSection {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 0px;
  position: relative;
  /* background: #f9c800; */
}

/* .appie-header-area .headSection .container-fluid {
  padding: 0px 200px;
} */

@media only screen and (max-width: 1700px) {
  .appie-header-area .headSection .container-fluid {
    padding: 0px 30px;
  }
}

@media (max-width: 767px) {
  .appie-header-area .headSection .container-fluid {
    padding: 0px 15px;
  }
}

.appie-header-area .navSection {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 0px 10px 0px;
}

@media (max-width: 767px) {
  .appie-header-area .navSection {
    display: none;
  }
}

.appie-header-area.appie-header-2-area .appie-btn-box .main-btn {
  background: #ff3e66;
  border-radius: 30px;
  border-color: #ff3e66;
}

.appie-header-area.appie-header-2-area .appie-btn-box .main-btn:hover {
  background: transparent;
  color: #ff3e66;
}

.appie-header-area.appie-header-3-area.appie-sticky.sticky {
  background: #000000;
}

.appie-header-area.appie-header-4-area.appie-sticky.sticky {
  background: linear-gradient(90deg, #6b1fcd 0%, #374ede 100%);
}

.appie-header-area.appie-header-page-area.appie-sticky.sticky {
  background: #0a44b9;
}

.appie-header-area.appie-header-7-area.appie-sticky.sticky {
  background: #290645;
}

.appie-header-area.appie-header-error-page {
  box-shadow: 0px 10px 20px 0px rgba(14, 17, 51, 0.1);
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-nav-box .headFull {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: space-around;
}

.header-nav-box .appie-logo-box {
  /* position: relative; */
  flex-basis: 100%;
}

@media (max-width: 767px) {
  .header-nav-box .appie-logo-box {
    flex-basis: auto;
  }

  .header-nav-box .appie-logo-box img {
    max-width: 150px !important;
    margin-left: 0px;
  }

  .appie-header-area .headSection {
    padding: 10px 0;
  }
}


.header-nav-box .appie-search-box {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1;
  margin: 0px;
  margin-left: 30px;
}

@media (max-width: 767px) {
  .header-nav-box .appie-search-box {
    display: none;
  }
}

.header-nav-box .appie-search-box span {
  font-weight: 700;
  font-size: 15px;
  margin-right: 15px;
}

.header-nav-box .appie-search-box span a {
  color: #000000;
}

.header-nav-box .appie-search-box span a:hover {
  color: #7f13ab;
}

.header-nav-box .appie-search-box span .sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: auto;
  min-width: 250px;
  max-width: 250px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
  z-index: 99;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 6px;
  text-align: left;
  display: inline-block;
}

.header-nav-box .appie-search-box span:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.header-nav-box .appie-search-box span .sub-menu a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;
  padding: 15px 25px;
  color: #000000;
  background: #ffffff;
  position: relative;
}

.header-nav-box .appie-search-box span .sub-menu a:hover {
  color: #7f13ab;
  background: #f4eef8;
}

.header-nav-box .appie-search-box span .sub-menu a .sub-cat {
  position: absolute;
  left: 100%;
  top: 0;
  width: auto;
  min-width: 250px;
  max-width: 250px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
  z-index: 99;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 6px;
  text-align: left;
  display: inline-block;
}

.header-nav-box .appie-search-box span .sub-menu a:hover .sub-cat {
  opacity: 1;
  visibility: visible;
  left: 100%;
}

.header-nav-box .appie-search-box span .sub-menu .sub-cat a .course-list {
  position: absolute;
  left: 100%;
  top: 0;
  width: auto;
  min-width: 350px;
  max-width: 350px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
  z-index: 99;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 6px;
  text-align: left;
  display: inline-block;
}

.header-nav-box .appie-search-box span .sub-menu .sub-cat a:hover .course-list {
  opacity: 1;
  visibility: visible;
  left: 100%;
}

.header-nav-box .appie-search-box span .sub-menu .sub-cat a .course-list img {
  width: 80px;
  border-radius: 6px;
}

.header-nav-box .appie-search-box span .sub-menu .sub-cat a .course-list div {
  font-size: 12px;
  margin-left: 15px;
}

.header-nav-box .appie-search-box span .sub-menu .sub-cat a .course-list div b {
  display: block;
  font-size: 13px;
}

.header-nav-box .appie-search-box form {
  width: 100%;
}

.header-nav-box .appie-search-box input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  border: 0;
  border: 1px solid #c9c9c9;
  padding-left: 35px;
  /* box-shadow: 2px 0px 0px 1px rgba(0, 0, 0, 0.1); */
}

.header-nav-box .appie-search-box button {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 30px;
  width: 40px;
  text-align: center;
  line-height: 30px;
  background: #fff;
  border-radius: 50%;
  border: 0;
  color: #000000;
}

.header-nav-box .appie-search-box button:hover {
  color: #7f13ab;
}

.header-nav-box .appie-btn-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .header-nav-box .appie-btn-box {
    display: none;
  }
}

.header-nav-box .appie-btn-box span {
  font-size: 15px;
  font-weight: 700;
  margin-right: 15px;
}

.header-nav-box .appie-btn-box .login-btn {
  display: inline-block;
  text-align: center;
  border: 2px solid #0b1125;
  background-color: #ffffff;
  color: #0b1125;
  border-radius: 6px;
  padding: 0 25px;
  font-size: 15px;
  font-weight: 700;
  line-height: 40px;
  vertical-align: middle;
  transition: all 0.4s ease-out 0s;
}

.header-nav-box .appie-btn-box .login-btn:hover {
  background-color: #0b1125;
  color: #ffffff;
}

.header-nav-box .appie-btn-box .signup-btn {
  display: inline-block;
  text-align: center;
  border: 2px solid #0b1125;
  background-color: #0b1125;
  color: #ffffff;
  border-radius: 6px;
  padding: 0 25px;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 700;
  line-height: 40px;
  vertical-align: middle;
  transition: all 0.4s ease-out 0s;
}

.header-nav-box .appie-btn-box .signup-btn:hover {
  background-color: #ffffff;
  color: #0b1125;
  border-color: #0b1125;
}

.header-nav-box .appie-btn-box .appie-icon-box {
  position: relative;
  margin-left: 15px;
}

@media (max-width: 767px) {
  .header-nav-box .appie-btn-box .appie-icon-box {
    display: none;
  }
}

.header-nav-box .appie-btn-box .appie-icon-box a {
  color: #000000;
  font-size: 20px;
  margin-left: 15px;
}

.header-nav-box .appie-btn-box .appie-icon-box a:hover {
  color: #7f13ab;
}


.header-nav-box.header-nav-box-3 .appie-header-main-menu ul>li>a {
  color: #fff;
}

.header-nav-box.header-nav-box-3 .appie-header-main-menu ul>li .sub-menu li a {
  color: #000000;
}

.header-nav-box.header-nav-box-3 .appie-btn-box .login-btn {
  color: #fff;
}

.header-nav-box.header-nav-box-3 .appie-btn-box .main-btn {
  background: #ff6b58;
  border-color: #ff6b58;
}

.header-nav-box.header-nav-box-3 .appie-btn-box .main-btn:hover {
  background: transparent;
  color: #ff6b58;
}

.header-nav-box.header-nav-box-3 .toggle-btn {
  color: #fff;
  font-size: 20px;
}

.header-nav-box.header-nav-box-3.header-nav-box-inner-page .appie-btn-box .main-btn {
  border-color: #fff;
  background: transparent;
}

.header-nav-box.header-nav-box-3.header-nav-box-inner-page .appie-btn-box .main-btn:hover {
  background: #fff;
  color: #7f13ab;
}

.header-nav-box.header-nav-box-5 .appie-header-main-menu ul>li>a {
  color: #fff;
}

.header-nav-box.header-nav-box-5 .appie-header-main-menu ul>li .sub-menu li a {
  color: #000000;
}

.header-nav-box.header-nav-box-5 .appie-btn-box .login-btn {
  color: #fff;
}

.header-nav-box.header-nav-box-5 .appie-btn-box .main-btn {
  background: #f84a6e;
  border-color: #f84a6e;
}

.header-nav-box.header-nav-box-5 .appie-btn-box .main-btn:hover {
  background: transparent;
  color: #f84a6e;
}

.header-nav-box.header-nav-box-5 .toggle-btn {
  color: #fff;
  font-size: 20px;
}

.header-nav-box.header-nav-4-box .appie-header-main-menu ul>li>a:hover {
  color: #801f82;
}

.header-nav-box.header-nav-4-box .appie-btn-box .main-btn {
  background: #801f82;
  border-color: #801f82;
}

.header-nav-box.header-nav-4-box .appie-btn-box .main-btn:hover {
  background: transparent;
  color: #801f82;
}

@media (max-width: 767px) {
  .header-nav-box.header-nav-4-box .appie-btn-box ul {
    margin-right: 10px;
  }
}

.header-nav-box.header-nav-4-box .appie-btn-box ul li {
  display: inline-block;
}

.header-nav-box.header-nav-4-box .appie-btn-box ul li a {
  font-size: 18px;
  color: #000000;
  margin-left: 15px;
}

.header-nav-box.header-nav-4-box .appie-btn-box ul li a.cart-btn {
  position: relative;
}

.header-nav-box.header-nav-4-box .appie-btn-box ul li a.cart-btn span {
  position: absolute;
  top: -7px;
  display: inline-block;
  right: -12px;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-size: 11px;
  background: #801f82;
  border-radius: 50%;
}

.header-nav-box.header-nav-box-6 .main-btn {
  background: #9b2cfa;
  border-color: #9b2cfa;
}

.header-nav-box.header-nav-box-6 .main-btn:hover {
  background: transparent;
  color: #9b2cfa;
}

.header-nav-box.header-nav-box-6 .appie-header-main-menu ul>li>a:hover {
  color: #9b2cfa;
}

.header-nav-box.header-nav-box-6 .appie-btn-box form {
  display: inline-block;
  margin-left: 18px;
}

@media (max-width: 767px) {
  .header-nav-box.header-nav-box-6 .appie-btn-box form {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-nav-box.header-nav-box-6 .appie-btn-box form {
    display: inline-block;
  }
}

.header-nav-box.header-nav-box-6 .appie-btn-box form .input-box {
  position: relative;
}

.header-nav-box.header-nav-box-6 .appie-btn-box form .input-box i {
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.header-nav-box.header-nav-box-6 .appie-btn-box form .input-box input {
  width: 105px;
  padding-left: 50px;
  border: 0;
  border-left: 1px solid #ddddea;
  background: transparent;
}

.header-nav-box.header-nav-box-7 .appie-header-main-menu ul>li>a {
  color: #fff;
}

.header-nav-box.header-nav-box-7 .appie-header-main-menu ul>li>a:hover {
  color: #7f13ab;
}

.header-nav-box.header-nav-box-7 .appie-header-main-menu ul>li .sub-menu li a {
  color: #545454;
}

.header-nav-box.header-nav-box-7 .appie-header-main-menu ul>li .sub-menu li a:hover {
  color: #7f13ab;
}

.header-nav-box.header-nav-box-7 .appie-btn-box .login-btn {
  color: #fff;
}

.header-nav-box.header-nav-box-7 .appie-btn-box .toggle-btn {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu {
    display: none;
  }
}

@media (max-width: 767px) {
  .appie-header-main-menu {
    display: none;
  }
}

.appie-header-main-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

.appie-header-main-menu ul>li {
  display: inline-block;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-header-main-menu ul>li {
    margin-right: 0px;
  }
}

.appie-header-main-menu ul>li>a {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  letter-spacing: 0.7px;
}

.appie-header-main-menu ul>li>a:hover {
  color: #7f13ab;
}

.appie-header-main-menu ul>li>a i {
  padding-left: 6px;
}

.appie-header-main-menu ul>li>a i.fa-home {
  font-size: 20px;
  padding: 0;
}

.appie-header-main-menu ul>li .sub-menu {
  position: absolute;
  right: 0;
  top: 110%;
  width: auto;
  min-width: 220px;
  max-width: 220px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
  z-index: 99;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  list-style-type: none;
  margin: 0;
  padding: 15px 0;
  border-radius: 5px;
  text-align: left;
  display: inline-block;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .appie-header-main-menu ul>li .sub-menu {
    min-width: 200px;
    max-width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-header-main-menu ul>li .sub-menu {
    min-width: 200px;
    max-width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul>li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    transition: all none ease-out 0s;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .appie-header-main-menu ul>li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    transition: all none ease-out 0s;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}

.appie-header-main-menu ul>li .sub-menu>li {
  position: relative;
  margin-left: 0;
  display: block;
  padding: 5px 10px;
}

.appie-header-main-menu ul>li .sub-menu>li .sub-menu {
  margin-left: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul>li .sub-menu>li .sub-menu {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .appie-header-main-menu ul>li .sub-menu>li .sub-menu {
    margin-left: 0;
  }
}

.appie-header-main-menu ul>li .sub-menu>li .sub-nav-toggler {
  color: #000000;
  transition: all 0.3s ease-out 0s;
}

.appie-header-main-menu ul>li .sub-menu>li a {
  display: block;
  padding: 0px 15px;
  position: relative;
  color: #0b1125;
  transition: all 0.3s ease-out 0s;
  border-radius: 4px;
  margin: 0 0;
  line-height: 2.5;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .appie-header-main-menu ul>li .sub-menu>li a {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-header-main-menu ul>li .sub-menu>li a {
    padding: 0 15px;
  }
}

.appie-header-main-menu ul>li .sub-menu>li a i {
  float: right;
  font-size: 16px;
  margin-top: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul>li .sub-menu>li a i {
    display: none;
  }
}

@media (max-width: 767px) {
  .appie-header-main-menu ul>li .sub-menu>li a i {
    display: none;
  }
}

.appie-header-main-menu ul>li .sub-menu>li a .sub-nav-toggler i {
  display: inline-block;
}

.appie-header-main-menu ul>li .sub-menu>li a:hover {
  padding-left: 20px;
}

.appie-header-main-menu ul>li .sub-menu>li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul>li .sub-menu>li .sub-menu {
    padding-left: 30px;
    transition: all 0s ease-out 0s;
  }
}

@media (max-width: 767px) {
  .appie-header-main-menu ul>li .sub-menu>li .sub-menu {
    padding-left: 30px;
    transition: all 0s ease-out 0s;
  }
}

.appie-header-main-menu ul>li .sub-menu>li .sub-menu li {
  position: relative;
}

.appie-header-main-menu ul>li .sub-menu>li .sub-menu li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
}

.appie-header-main-menu ul>li .sub-menu>li .sub-menu li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}

.appie-header-main-menu ul>li .sub-menu>li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}

.appie-header-main-menu ul>li .sub-menu>li:hover .sub-nav-toggler {
  color: #f9c800;
}

.appie-header-main-menu ul>li .sub-menu>li:hover>a {
  color: #f9c800;
  border-bottom: 1px solid #f9c800;
}

.appie-header-main-menu ul>li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

@keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

.appie-header-8-area .header-nav-box .appie-btn-box .main-btn {
  background: #db0f30;
  border-color: #db0f30;
}

.appie-header-8-area .header-nav-box .appie-btn-box .main-btn:hover {
  background: #fff;
  color: #db0f30;
}

.appie-header-8-area .header-nav-box .appie-header-main-menu ul>li a:hover {
  color: #db0f30;
}

/*===========================
    3.APPIE HERO css 
===========================*/
.appie-hero-area {
  overflow: hidden;
  position: relative;
  z-index: 10;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.appie-hero-area.appie-hero-3-area {
  /* background-image: url(../images/hero-bg.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 180px;
  padding-bottom: 0px;
  overflow: visible;
  z-index: 15;
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
  color: #fff;
  font-size: 70px;
  line-height: 80px;
  padding: 0 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
    font-size: 54px;
    line-height: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 767px) {
  .appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
    font-size: 36px;
    line-height: 46px;
    padding: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
    font-size: 46px;
    line-height: 56px;
    padding: 0;
  }
}

.appie-hero-area.appie-hero-3-area .appie-hero-content p {
  color: #fff;
  opacity: 0.5;
  font-size: 18px;
  line-height: 28px;
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns {
  margin-top: 35px;
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns .main-btn {
  border-color: #ff6b58;
  color: #ff6b58;
  background: transparent;
  margin-right: 14px;
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns .appie-video-popup {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns .appie-video-popup {
    margin-top: 20px;
  }
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns .appie-video-popup i {
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 42px;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  margin-right: 10px;
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .thumb {
  margin-bottom: -210px;
}

@media (max-width: 767px) {
  .appie-hero-area.appie-hero-3-area .appie-hero-content .thumb {
    display: none;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-hero-area.appie-hero-3-area .appie-hero-content .thumb {
    display: block;
    margin-bottom: -210px;
  }
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area {
  background-color: #eef1f6;
  /* background-image: url(../images/hero-bg-2.png); */
  padding-top: 280px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-hero-area.appie-hero-3-area.appie-hero-5-area {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-hero-area.appie-hero-3-area.appie-hero-5-area {
    padding-top: 160px;
  }
}

@media (max-width: 767px) {
  .appie-hero-area.appie-hero-3-area.appie-hero-5-area {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-hero-area.appie-hero-3-area.appie-hero-5-area {
    padding-top: 120px;
    padding-bottom: 0px;
  }
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .thumb {
  margin-bottom: -110px;
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .appie-title {
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .appie-title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .appie-title {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 767px) {
  .appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .appie-title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .appie-title {
    font-size: 36px;
    line-height: 46px;
  }
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .hero-btns .appie-video-popup i {
  border-color: rgba(255, 255, 255, 0.2);
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .hero-btns .main-btn {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .hero-btns .main-btn:hover {
  background: #f84a6e;
  color: #fff;
}

.appie-hero-area .hero-shape-1 {
  position: absolute;
  left: 0;
  top: 250px;
  z-index: -1;
  -webkit-animation: linear 20s animationFramesOne infinite;
          animation: linear 20s animationFramesOne infinite;
}

.appie-hero-area .hero-shape-2 {
  position: absolute;
  left: 80px;
  z-index: -1;
  top: 480px;
  -webkit-animation: linear 25s animationFramesThree infinite;
          animation: linear 25s animationFramesThree infinite;
}

.appie-hero-area .hero-shape-3 {
  position: absolute;
  bottom: 120px;
  right: 320px;
  z-index: -1;
  -webkit-animation: linear 25s animationFramesFive infinite;
          animation: linear 25s animationFramesFive infinite;
}

.appie-hero-area.appie-hero-4-area {
  background: #f9f9f9;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 6% 100%;
  padding-top: 140px;
  padding-bottom: 60px;
}

.appie-hero-area.appie-hero-8-area {
  position: relative;
  z-index: 10;
}

.appie-hero-area.appie-hero-8-area .home-8-shape-1 {
  position: absolute;
  left: 0;
  bottom: 100px;
  z-index: -1;
}

.appie-hero-area.appie-hero-8-area .home-8-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.appie-hero-area.appie-hero-8-area .home-8-shape-3 {
  position: absolute;
  top: 400px;
  right: 290px;
  z-index: -1;
}

.appie-hero-area.appie-hero-8-area .home-8-shape-4 {
  position: absolute;
  bottom: 195px;
  left: 195px;
  z-index: -1;
}

.appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
  background: linear-gradient(90deg, #fb2d4e 25%, #4e88ff 75%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
    font-size: 56px;
    line-height: 66px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
    font-size: 42px;
    line-height: 66px;
  }
}

@media (max-width: 767px) {
  .appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
    font-size: 32px;
    line-height: 44px;
  }
}

.appie-hero-thumb-6 .thumb {
  position: relative;
  z-index: 10;
}

.appie-hero-thumb-6 .thumb .back-image {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  z-index: -1;
}

.appie-hero-area.homeAbout {
  padding: 150px 0px;
}

.hero-bg {
  background-color: #0b1125;
  border-radius: 30px;
}

.appie-hero-content {
  padding: 60px;
}

.appie-hero-content>span {
  color: #f9c800;
}

.appie-hero-content .appie-title {
  font-size: 50px;
  margin-bottom: 20px;
  color: #ffffff;
}

.appie-hero-content p {
  color: #c8cbd3;
}

.appie-hero-thumb .img-box {
  margin: -50px 60px -50px 0px;
  border-radius: 30px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .appie-hero-area.homeAbout {
    padding: 50px 0px 100px;
  }

  .appie-hero-content {
    padding: 35px;
  }

  .appie-hero-content .appie-title {
    font-size: 38px;
  }

  .appie-hero-thumb .img-box {
    margin: 0px 25px -60px 25px;
  }
}

.appie-hero-content .appie-rate {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #ff3e66;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.appie-hero-content .appie-rate span {
  font-size: 45px;
  line-height: 55px;
  font-weight: 700;
  color: #707070;
  margin-left: 15px;
  text-decoration: line-through;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-hero-content .appie-rate {
    font-size: 48px;
    line-height: 58px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-hero-content .appie-rate {
    font-size: 48px;
    line-height: 58px;
  }
}

@media (max-width: 767px) {
  .appie-hero-content .appie-rate {
    font-size: 28px;
    line-height: 38px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-hero-content .appie-rate {
    font-size: 36px;
    line-height: 46px;
  }
}

.appie-hero-content .appie-offer {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appie-hero-content .appie-offer i {
  margin-right: 10px;
  font-size: 26px;
}

.appie-hero-content .appie-offer span {
  color: #ff3e66;
  margin-left: 10px;
}

.appie-hero-content ul {
  margin: 30px 0 0;
  padding: 0;
  list-style-type: none;
}

.appie-hero-content ul li {
  display: inline-block;
}

@media (max-width: 767px) {
  .appie-hero-content ul li {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-hero-content ul li {
    margin-bottom: 0px;
  }
}

.appie-hero-content ul li a {
  position: relative;
  background: #000000;
  color: #fff;
  line-height: 40px;
  padding: 0 60px 0 25px;
  border-radius: 6px;
  border: 1px solid #000000;
  font-weight: 900;
}

.appie-hero-content ul li a i {
  position: absolute;
  background: #7f13ab;
  color: #ffffff;
  height: 42px;
  width: 42px;
  line-height: 42px;
  text-align: center;
  top: -1px;
  right: -1px;
  border-radius: 5px;
}

.appie-hero-content ul li a:hover {
  background: transparent;
  color: #7f13ab;
  border-color: #7f13ab;
}

.appie-hero-content ul li a:hover i {
  color: #ffffff;
}

.appie-hero-content ul li a.item-2 {
  background: transparent;
  color: #000000;
}

.appie-hero-content ul li a.item-2:hover {
  background: #000000;
  color: #fff;
}

.appie-hero-content.appie-hero-content-4 {
  padding-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-hero-content.appie-hero-content-4 {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .appie-hero-content.appie-hero-content-4 {
    padding-right: 0;
  }
}

.appie-hero-content.appie-hero-content-4>span {
  color: #801f82;
}

.appie-hero-content.appie-hero-content-4 .appie-title {
  font-size: 70px;
  font-weight: 700;
  line-height: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-hero-content.appie-hero-content-4 .appie-title {
    font-size: 60px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-hero-content.appie-hero-content-4 .appie-title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 767px) {
  .appie-hero-content.appie-hero-content-4 .appie-title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-hero-content.appie-hero-content-4 .appie-title {
    font-size: 40px;
    line-height: 50px;
  }
}

.appie-hero-content.appie-hero-content-4 a.main-btn {
  border: 2px solid #801f82;
  color: #801f82;
  background: #fff;
  line-height: 55px;
  padding: 0 40px;
  font-size: 16px;
  margin-top: 40px;
}

.appie-hero-content.appie-hero-content-4 a.main-btn:hover {
  background: #801f82;
  color: #fff;
}

.appie-hero-content.appie-hero-content-6>span {
  color: #9b2cfa;
}

.appie-hero-content.appie-hero-content-6 .appie-title {
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-hero-content.appie-hero-content-6 .appie-title {
    font-size: 60px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-hero-content.appie-hero-content-6 .appie-title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 767px) {
  .appie-hero-content.appie-hero-content-6 .appie-title {
    font-size: 36px;
    line-height: 46px;
  }
}

.appie-hero-content.appie-hero-content-6 ul li a {
  background: #9b2cfa;
  border-color: #9b2cfa;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}

.appie-hero-content.appie-hero-content-6 ul li a span {
  line-height: 20px;
}

.appie-hero-content.appie-hero-content-6 ul li a span span {
  font-size: 16px;
  font-weight: 700;
  display: block;
  line-height: 20px;
}

.appie-hero-content.appie-hero-content-6 ul li a i {
  font-size: 30px;
}

.appie-hero-content.appie-hero-content-6 ul li a:hover {
  background: #fff;
  color: #000000;
  border-color: #fff;
}

.appie-hero-content.appie-hero-content-6 ul li a.item-2 {
  background: #fff;
  border-color: #fff;
}

.appie-hero-content.appie-hero-content-6 ul li a.item-2:hover {
  background: #9b2cfa;
  border-color: #9b2cfa;
  color: #fff;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 .appie-title {
  color: #fff;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 p {
  color: #fff;
  opacity: 0.5;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 ul li a {
  background: #fff;
  color: #000000;
  border-color: #fff;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 ul li a:hover {
  background: transparent;
  border-color: #54386a;
  color: #fff;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 ul li a.item-2 {
  background: transparent;
  color: #fff;
  border-color: #54386a;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 ul li a.item-2:hover {
  border-color: #fff;
  background: #fff;
  color: #000000;
}

.appie-hero-thumb {
  position: relative;
}

.appie-hero-thumb .slick-slide img {
  display: inline;
}


.appie-hero-thumb .slick-prev {
  position: absolute;
  left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 30px;
  height: 30px;
  z-index: 99;
}

.appie-hero-thumb .slick-next {
  position: absolute;
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 30px;
  height: 30px;
  z-index: 99;
}

.appie-hero-thumb .slick-prev:before {
  content: "\F053";
  font-family: "font awesome 5 pro";
  font-size: 35px;
  line-height: 1;
  opacity: 0.8;
  color: #0b1125;
}

.appie-hero-thumb .slick-next:before {
  content: "\F054";
  font-family: "font awesome 5 pro";
  font-size: 35px;
  line-height: 1;
  opacity: 0.8;
  color: #0b1125;
}

.appie-hero-thumb .slick-prev:hover:before,
.appie-hero-thumb .slick-next:hover:before {
  opacity: 1;
}

.form-area {
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 10%;
  right: 4%;
  height: 80%;
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

@media only screen and (max-width: 1700px) {
  .form-area {
    top: 3%;
    height: 94%;
  }
}

.form-area h2 {
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 10px;
}

.form-area form {
  width: 100%;
}

.form-area input,
.form-area select {
  width: 100%;
  background: #ffffff;
  padding: 15px 30px;
  border: none;
  border-radius: 6px;
  margin-top: 30px;
}

.form-area input[type="submit"] {
  background: #f9c800;
  color: #0b1125;
  font-weight: 600;
  width: auto;
  margin-top: 40px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;

}

.form-area input[type="submit"]:hover {
  background: #0b1125;
  color: #ffffff;
  font-weight: 600;
  width: auto;
  margin-top: 40px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .form-area {
    position: relative;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 30px 20px 40px;
    background: #0b1125;
  }

  .form-area h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .form-area input,
  .form-area select {
    margin-top: 15px;
  }
}

.appie-hero-thumb.appie-hero-thumb-4::before {
  height: 160px;
  width: 160px;
  top: 200px;
  left: 70px;
  background: #801f82;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
}

.appie-hero-thumb.appie-hero-thumb-4 .hero-dot {
  position: absolute;
  right: 70px;
  bottom: 115px;
  z-index: -1;
}

.appie-hero-area-2 {
  padding-top: 290px;
  padding-bottom: 170px;
  background: #eef1f6;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-hero-area-2 {
    padding-top: 190px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-hero-area-2 {
    padding-top: 190px;
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  .appie-hero-area-2 {
    padding-top: 190px;
    padding-bottom: 150px;
  }
}

.appie-hero-area-2 .appie-hero-thumb-3 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 48%;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .appie-hero-area-2 .appie-hero-thumb-3 {
    width: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-hero-area-2 .appie-hero-thumb-3 {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .appie-hero-area-2 .appie-hero-thumb-3 {
    width: 36%;
  }
}

.appie-hero-area-2 .hero-shape-1 {
  position: absolute;
  top: 250px;
  left: 130px;
  -webkit-animation: linear 20s animationFramesOne infinite;
          animation: linear 20s animationFramesOne infinite;
  z-index: -1;
}

.appie-hero-area-2 .hero-shape-2 {
  position: absolute;
  bottom: 290px;
  left: 40px;
  -webkit-animation: linear 20s animationFramesFour infinite;
          animation: linear 20s animationFramesFour infinite;
  z-index: -1;
}

.appie-hero-area-2 .hero-shape-3 {
  position: absolute;
  top: 480px;
  right: 240px;
  -webkit-animation: linear 20s animationFramesFive infinite;
          animation: linear 20s animationFramesFive infinite;
  z-index: -1;
}

.appie-hero-area-2 .hero-shape-4 {
  position: absolute;
  top: 210px;
  right: 0px;
  -webkit-animation: linear 20s animationFramesThree infinite;
          animation: linear 20s animationFramesThree infinite;
  z-index: -1;
}

.appie-hero-content-2>span {
  font-size: 16px;
  font-weight: 700;
  color: #ff3e66;
  position: relative;
}

.appie-hero-content-2>span::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 130px;
  height: 70px;
  /* background-image: url(../images/hero-line.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.appie-hero-content-2 .appie-title {
  font-size: 64px;
  line-height: 70px;
  font-weight: 400;
  margin-top: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-hero-content-2 .appie-title {
    font-size: 50px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-hero-content-2 .appie-title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 767px) {
  .appie-hero-content-2 .appie-title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-hero-content-2 .appie-title {
    font-size: 40px;
    line-height: 50px;
  }
}

.appie-hero-content-2 .appie-title span {
  font-weight: 900;
}

.appie-hero-content-2 p {
  font-size: 18px;
  color: #000000;
  margin-top: 22px;
}

.appie-hero-content-2 .input-box {
  position: relative;
  margin-top: 40px;
  width: 400px;
}

@media (max-width: 767px) {
  .appie-hero-content-2 .input-box {
    width: 100%;
  }
}

.appie-hero-content-2 .input-box>i {
  position: absolute;
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #c3c3cc;
}

.appie-hero-content-2 .input-box input {
  width: 100%;
  height: 60px;
  border-radius: 40px;
  border: 0;
  border-left: 3px solid #ff3e66;
  padding-left: 55px;
  box-shadow: -3px 0px 0px 0px rgba(255, 62, 102, 0.004),
    0px 30px 30px 0px rgba(14, 17, 51, 0.1);
}

.appie-hero-content-2 .input-box button {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 0;
  color: #ff3e66;
}

.appie-hero-content-2 .hero-users {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.appie-hero-content-2 .hero-users>span {
  margin-left: 20px;
  font-size: 26px;
  font-weight: 900;
  color: #000000;
  width: 60px;
  display: inline-block;
  line-height: 16px;
}

.appie-hero-content-2 .hero-users>span span {
  font-size: 13px;
  font-weight: 700;
  color: #545454;
  display: inline-block;
  line-height: 13px;
}

.appie-page-title-area {
  background: #7f13ab;
  padding: 120px 0px 0px;
}

.appie-page-title-item {
  position: relative;
}

.appie-page-title-item .title {
  color: #fff;
  font-size: 100px;
  line-height: 75px;
  font-weight: 900;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .appie-page-title-area {
    padding: 80px 0px 0px;
  }

  .appie-page-title-item .title {
    font-size: 80px;
    line-height: 60px;
  }
}

.appie-page-title-area.appie-page-service-title-area {
  height: 650px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-page-title-area.appie-page-service-title-area {
    padding: 80px 0px;
  }
}

@media (max-width: 767px) {
  .appie-page-title-area.appie-page-service-title-area {
    padding: 50px 0px;
  }
}

.appie-page-title-area.appie-page-service-title-area .appie-page-title-item .thumb {
  right: 110px;
  width: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-page-title-area.appie-page-service-title-area .appie-page-title-item .thumb {
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-page-title-area.appie-page-service-title-area .appie-page-title-item .thumb {
    display: none;
  }
}

@media (max-width: 767px) {
  .appie-page-title-area.appie-page-service-title-area .appie-page-title-item .thumb {
    display: none;
  }
}

.appie-page-title-item .thumb {
  position: absolute;
  right: 0;
  bottom: -100px;
  width: 160px;
}

@media (max-width: 767px) {
  .appie-page-title-item .thumb {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-page-title-item .thumb {
    display: block;
  }
}

.appie-page-title-item span {
  color: #fff;
  font-weight: 700;
  opacity: 0.7;
  padding-bottom: 14px;
}

.appie-page-title-item nav {
  display: inline-block;
  margin-top: 20px;
}

.appie-page-title-item nav ol {
  background: transparent;
  padding: 0;
}

.appie-page-title-item nav ol li a {
  color: #fff;
  opacity: 0.8;
}

.appie-page-title-item nav ol li.active {
  color: #fff;
  opacity: 0.8;
}

/*===========================
    4.APPIE SERVOCE css 
===========================*/
.appie-service-area {
  padding: 40px 0 10px;
  background: #f5f5f5;
  position: relative;
  background-repeat: no-repeat;
  background-size: auto;
  /* margin-top: 20px; */
}

@media (max-width: 767px) {
  .appie-service-area {
    padding: 40px 0 30px;
  }
}

.appie-service-area.appie-service-3-area {
  background: none;
}

@media (max-width: 767px) {
  .appie-service-area.appie-service-3-area {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-service-area.appie-service-3-area {
    padding-top: 190px;
  }
}

.appie-single-service {
  transition: all linear 0.3s;
}

.appie-single-service .icon {
  display: inline-block;
  border-radius: 20px;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  background: linear-gradient(135deg, #84bcfa 0%, #2b70fa 100%);
  box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
  position: relative;
}

.appie-single-service .icon>span {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  background: #7f13ab;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 3px 6px 0px rgba(14, 17, 51, 0.1);
  line-height: 28px;
  color: #fff;
}

.appie-single-service .appie-title {
  font-size: 20px;
  font-weight: 700;
  padding-top: 33px;
  padding-bottom: 10px;
}

.appie-single-service:hover {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}

.appie-single-service.item-2 .icon {
  background: linear-gradient(135deg, #f7b8af 0%, #f8452d 100%);
}

.appie-single-service.item-2 .icon span {
  background: #f8452d;
}

.appie-single-service.item-3 .icon {
  background: linear-gradient(135deg, #a0eac2 0%, #31c369 100%);
}

.appie-single-service.item-3 .icon span {
  background: #31c369;
}

.appie-single-service.item-4 .icon {
  background: linear-gradient(135deg, #d8aaff 0%, #9b2cfa 100%);
}

.appie-single-service.item-4 .icon span {
  background: #9b2cfa;
}

.appie-single-service.appie-single-services-3 {
  background: transparent;
}

.appie-single-service.appie-single-services-3 .icon {
  border-radius: 50%;
  background-image: linear-gradient(110deg, #ff5f4a 0%, #ff9e91 100%);
}

.appie-single-service.appie-single-services-3 a {
  color: #545454;
  font-size: 14px;
  font-weight: 700;
  margin-top: 25px;
}

.appie-single-service.appie-single-services-3 a:hover {
  color: #ff6b58;
}

.appie-single-service.appie-single-services-3.item-2 {
  background: linear-gradient(0deg,
      rgba(39, 153, 246, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 100%);
}

.appie-single-service.appie-single-services-3.item-2 .icon {
  background-image: linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
}

.appie-single-service.appie-single-services-3.item-3 .icon {
  background-image: linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
}

.appie-single-service.appie-single-services-3.item-4 {
  background: linear-gradient(0deg,
      rgba(119, 89, 237, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 100%);
}

.appie-single-service.appie-single-services-3.item-4 .icon {
  background-image: linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
}

.appie-single-service.appie-single-services-3.appie-single-services-5 a:hover {
  color: #801f82;
}

.appie-single-service .imgBox {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.appie-single-service a {
  display: block;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 15px;
  text-align: center;
  padding: 15px 0px;
  background: linear-gradient(0deg, #d8aaff -30%, #9b2cfa 100%);
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
}

.appie-single-service.Link2 a {
  background: linear-gradient(0deg, #84bcfa -30%, #2b70fa 100%);
}

.appie-single-service.Link3 a {
  background: linear-gradient(0deg, #a0eac2 -30%, #31c369 100%);
}

@media (max-width: 767px) {
  .appie-services-2-area .appie-section-title {
    text-align: center !important;
  }
}

.appie-single-service-2 {
  border: 1px solid #eef0f4;
  border-radius: 10px;
  padding: 48px;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 767px) {
  .appie-single-service-2 {
    text-align: center;
    padding: 20px;
  }
}

.appie-single-service-2 .icon i {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #2eacfc;
  color: #fff;
  border-radius: 50%;
}

.appie-single-service-2 .title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
}

.appie-single-service-2 a {
  font-size: 14px;
  font-weight: 700;
  color: #545454;
  margin-top: 23px;
}

.appie-single-service-2 a:hover {
  color: #ff3e66;
}

.appie-single-service-2:hover {
  box-shadow: 0px -3px 0px 0px rgba(234, 76, 137, 0.004),
    0px 40px 40px 0px rgba(14, 17, 51, 0.1);
  border-top: 3px solid #2eacfc;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.appie-single-service-2.item-2 .icon i {
  background: #ea4c89;
}

.appie-single-service-2.item-2:hover {
  border-top-color: #ea4c89;
}

.appie-single-service-2.item-3 .icon i {
  background: #3578ff;
}

.appie-single-service-2.item-3:hover {
  border-top-color: #3578ff;
}

.appie-single-service-2.item-4 .icon i {
  background: #f67741;
}

.appie-single-service-2.item-4:hover {
  border-top-color: #f67741;
}

.appie-single-service-2.item-5 .icon i {
  background: #6a88f7;
}

.appie-single-service-2.item-5:hover {
  border-top-color: #6a88f7;
}

.appie-single-service-2.item-6 .icon i {
  background: #3ab094;
}

.appie-single-service-2.item-6:hover {
  border-top-color: #3ab094;
}

.appie-single-service-2.appie-single-service-about {
  border-radius: 0;
  padding: 0 90px 30px 0;
  border: 0;
  transition: all linear 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-single-service-2.appie-single-service-about {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .appie-single-service-2.appie-single-service-about {
    padding-right: 50px;
    padding-left: 50px;
  }
}

.appie-single-service-2.appie-single-service-about .icon i {
  background-color: #f1f7fe;
  color: #7f13ab;
  border-radius: 35% 65% 50% 50% / 59% 50% 50% 41%;
  font-size: 20px;
}

.appie-single-service-2.appie-single-service-about:hover {
  box-shadow: none;
  margin-top: 20px;
}

.appie-single-service-2.appie-single-service-about.item-2 .icon i {
  color: #db0f30;
  background-color: #fef3f5;
  border-radius: 50% 50% 50% 50% / 50% 75% 25% 50%;
}

.appie-single-service-2.appie-single-service-about.item-3 .icon i {
  color: #9b2cfa;
  background-color: #f7eeff;
  border-radius: 25% 75% 50% 50% / 50% 50% 50% 50%;
}

.appie-single-service-2.appie-single-service-about.item-4 .icon i {
  color: #f8452d;
  background-color: #fef0ee;
  border-radius: 40% 60% 40% 60% / 40% 60% 40% 60%;
}

.appie-single-service-2.appie-single-service-about.item-5 .icon i {
  color: #31c369;
  background-color: #f4fcf7;
  border-radius: 33% 67% 50% 50% / 61% 72% 28% 39%;
}

.appie-single-service-2.appie-single-service-about.item-6 .icon i {
  color: #f6ac0e;
  background-color: #fef7e7;
  border-radius: 21% 79% 58% 42% / 50% 46% 54% 50%;
}

.appie-services-6-area .appie-section-title .main-btn:hover {
  background: #9b2cfa;
  border-color: #9b2cfa;
}

.appie-services-6-area .appie-single-service-2 a:hover {
  color: #9b2cfa;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .service-details-sidebar {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .service-details-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.service-details-sidebar .service-category-widget {
  border-radius: 6px;
  box-shadow: 0px 30px 50px 0px rgba(14, 17, 51, 0.1);
  padding: 30px;
  margin-bottom: 30px;
}

.service-details-sidebar .service-category-widget ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.service-details-sidebar .service-category-widget ul li {
  margin-bottom: 20px;
}

.service-details-sidebar .service-category-widget ul li i {
  color: #7f13ab;
  width: 15px;
  margin-right: 15px;
}

.service-details-sidebar .service-category-widget ul li:last-child {
  margin-bottom: 0;
}

.service-details-sidebar .service-download-widget {
  border-radius: 6px;
  margin-top: 10px;
  box-shadow: 0px 0px 50px 0px rgba(14, 17, 51, 0.1);
}

.service-details-sidebar .service-download-widget a {
  padding: 13px 30px;
  color: #545454;
}

.service-details-sidebar .service-download-widget a:hover {
  color: #7f13ab;
}

.service-details-sidebar .service-download-widget i {
  color: #7f13ab;
  padding-right: 15px;
}

.service-details-content .thumb {
  border-radius: 10px;
  overflow: hidden;
}

.service-details-content .content {
  padding-top: 34px;
}

.service-details-content .content .title {
  font-size: 30px;
  margin-bottom: 18px;
}

.service-details-content .content span {
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  padding-top: 34px;
  padding-bottom: 26px;
}

.appie-services-8-area .service-thumb {
  margin-left: -50px;
  margin-right: -100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-services-8-area .service-thumb {
    display: none;
  }
}

@media (max-width: 767px) {
  .appie-services-8-area .service-thumb {
    display: none;
  }
}

/*===========================
    5.APPIE FEATURES css 
===========================*/

.appie-features-area {
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding: 0px 0 50px;
}

.appie-features-area.institute {
  padding: 80px 0 0px;
}

@media (max-width: 767px) {
  .appie-features-area.institute {
    padding: 40px 0 0px;
  }
}

@media only screen and (max-width: 1700px) {
  .appie-features-area .container-fluid {
    padding: 0px 15px;
  }
}

@media (max-width: 767px) {
  .appie-features-area .container-fluid {
    padding: 0px 15px;
  }
}

.appie-features-area .features-shape-1 {
  position: absolute;
  left: 560px;
  top: 130px;
  -webkit-animation: animationFramesOne 20s linear infinite;
          animation: animationFramesOne 20s linear infinite;
  z-index: -1;
}

.appie-features-area .features-shape-2 {
  position: absolute;
  left: 500px;
  bottom: 0px;
  -webkit-animation: animationFramesThree 20s linear infinite;
          animation: animationFramesThree 20s linear infinite;
  z-index: -1;
}

.appie-features-area .features-shape-3 {
  position: absolute;
  right: 270px;
  bottom: 0;
  -webkit-animation: animationFramesFive 20s linear infinite;
          animation: animationFramesFive 20s linear infinite;
  z-index: -1;
}

.appie-features-tabs-btn .nav a {
  border-radius: 0;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 700;
  color: #000000;
  background: #f0f0f0;
  padding: 10px 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-features-tabs-btn .nav a {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 767px) {
  .appie-features-tabs-btn .nav a {
    margin-right: 3px;
    margin-bottom: 3px;
    padding: 8px 16px;
  }
}

.appie-features-tabs-btn .nav a:hover {
  background: #7f13ab;
  color: #ffffff;
}

.appie-features-tabs-btn .nav a.active {
  background: #7f13ab;
  color: #ffffff;
}

.appie-features-content-old {
  background: #fff;
  border-radius: 10px;
  /* border-top: 3px solid #ff3e66; */
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 10px 15px;
  transition: all 0.3s ease-out 0s;
  border: 2px solid #f5f5f5;
}

.appie-features-content {
  position: relative;
  margin: 10px;
  transition: auto;
  border: 1px solid #ecebff;
  border-radius: 10px;
  overflow: hidden;
}

.hideOnDesktop {
  display: none !important;
}

@media (max-width: 767px) {
  .hideOnMobile {
    display: none !important;
  }

  .hideOnDesktop {
    display: block !important;
  }

  .appie-features-content {
    /* margin: 40px 15px 30px 15px; */
    margin: 5px;
  }
}

/* .appie-features-content:hover {
  margin-top: 35px;
} */

.appie-features-content .imgBox {
  position: relative;
  overflow: hidden;
}

.appie-features-content .imgBox span {
  position: absolute;
  bottom: 10px;
  right: 1px;
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  padding: 2px 5px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  font-size: 10px;
}

.appie-features-content .imgBox a.btnLive {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  color: #000000;
  padding: 3px 0px 3px 15px;
  border: 2px solid #b42323;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 700;
}

.appie-features-content .imgBox a.btnLive i {
  color: #b42323;
  margin: 0px 8px;
}

.appie-features-content .imgBox a.btnLive b {
  background: #b42323;
  color: #ffffff;
  padding: 6px 15px;
  border-radius: 50px;
  font-weight: 700;
}

.appie-features-content .logoBox {
  position: absolute;
  top: 27.5%;
  left: 20px;
  padding: 8px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 10px;
}

.appie-features-content .contentBox {
  padding: 20px;
}

.appie-features-content .contentBox span {
  font-size: 12px;
  font-weight: 600;
  background: #ecebff;
  margin-right: 6px;
  padding: 2px 10px;
  border-radius: 5px;
}

.appie-features-content .contentBox span:last-child {
  margin-right: 0px;
}

.appie-features-content .contentBox span.bgOne {
  background: #d6efff;
}

.appie-features-content .contentBox span.bgTwo {
  background: #ece8ff;
}

.appie-features-content .contentBox span.bgThree {
  background: #fff1c2;
}

.appie-features-content .contentBox h6 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.appie-features-content .contentBox h6 a {
  color: #0b1125;
}

.appie-features-content .contentBox h6 a:hover {
  color: #f9c800;
}

.appie-features-content .contentBox p {
  font-size: 14px;
  margin-bottom: 10px;
}

.appie-features-content .contentBox .rate-box {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
}

.appie-features-content .contentBox .rate-box span {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  margin-left: 6px;
  padding: 0px 5px;
  background: #f7f8fd;
  border-radius: 0;
}

.appie-features-content .contentBox .book-btn {
  background: #0b1125;
  color: #ffffff;
  border-radius: 6px;
  padding: 6px 20px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.4s ease-out 0s;
}

.appie-features-content .contentBox .book-btn:hover {
  background: #f9c800;
  color: #ffffff;
}

.appie-features-content .contentBox .instructorBox {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.appie-features-content .contentBox .instructorBox a {
  color: #000000;
}

.appie-features-content .contentBox .instructorBox a:hover {
  color: #7f13ab;
}

.appie-features-content .contentBox .instructorBox img {
  width: 36px;
  margin-right: 12px;
  border-radius: 36px;
  HEIGHT: 36PX;
  OBJECT-FIT: COVER;
}

.appie-features-content .contentBox .ratingBox {
  /* color: #ffcc00; */
  color: #b4690e;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.appie-features-content .contentBox .ratingBox b {
  color: #000000;
  /* margin-left: 10px; */
}

.appie-features-content .contentBox .locationBox {
  margin-bottom: 10px;
}

.appie-features-content .contentBox .locationBox a {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.appie-features-content .contentBox .locationBox a:hover {
  color: #7f13ab;
}

.appie-features-content .contentBox .locationBox i {
  font-weight: 400;
  margin-right: 6px;
}

.appie-features-content .contentBox .linkBox {
  margin-bottom: 15px;
}

.appie-features-content .contentBox .linkBox a {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  margin-right: 10px;
}

.appie-features-content .contentBox .linkBox a:hover {
  color: #7f13ab;
  text-decoration: none;
}

.appie-features-content .contentBox .textBox {
  margin-bottom: 15px;
}

.appie-features-content .contentBox .textBox p {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: .5px;
}

.appie-features-content .contentBox .textBox a {
  color: #7f13ab;
  text-decoration: underline;
  float: right;
}

.appie-features-content .contentBox .textBox a:hover {
  color: #000000;
  text-decoration: none;
}

.appie-features-content .contentBox .rateBox {
  font-size: 30px;
  font-weight: 700;
  color: #000000;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.appie-features-content .contentBox .rateBox i {
  font-size: 20px;
  font-weight: 700;
  color: #707070;
  margin-left: 12px;
  text-decoration: line-through;
  font-style: normal;
}

.appie-features-content .contentBox .tagsBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.tagsBox b {
  /* .appie-features-content .contentBox .tagsBox b  */
  background: #ffec61;
  display: inline-block;
  padding: 2px 7px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 2px;
  margin-right: 5px;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 600px) {
  .tagsBox b {
    padding: 2px 2px;
    font-size: 8px;
    font-weight: 400;
    margin-right: 3px;
    letter-spacing: 0px;
    border-radius: 4px;
  }
}

.tagsBox b.tag-highestrated {
  background: #17a2b8;
  color: #fff;
}

.tagsBox b.tag-bestseller {
  background: #ffc107;
  color: #fff;
}

.tagsBox b.tag-newadded {
  background: #dc3545;
  color: #fff;
}

.appie-features-content .contentBox .tagsBox b a {
  color: #000000;
}

.appie-features-content .contentBox .tagsBox b a:hover {
  color: #ff3e66;
}

.appie-features-content .contentBox .tagsBox .shareBox a {
  color: #ff3e66;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 0px;
  cursor: pointer;
}

.appie-features-content .contentBox .hLine hr {
  margin-top: 0px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid #dddddd;
}

.appie-features-content .contentBox .courseDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.appie-features-content .contentBox .courseDetails .durationBox i {
  color: #ff3e66;
  margin-right: 5px;
}

.appie-features-content .contentBox .courseDetails .sessionsBox i {
  color: #2a6df5;
  margin-right: 5px;
}

.appie-features-content .hoverContent {
  position: absolute;
  top: 50%;
  left: 100%;
  width: 100%;
  z-index: 999;
  /* box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 5px 20px 0px rgb(0 0 0);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  display: none;
}

.appie-features-content:hover .hoverContent {
  opacity: 1;
  display: block;
}

@media (max-width: 767px) {
  .appie-features-content:hover .hoverContent {
    opacity: 0;
    display: none;
  }
}

.appie-features-content .hoverContent:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  width: 20px;
  height: 20px;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: -1;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

.appie-features-content .hoverContent h6 {
  background: #fff;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  padding: 15px;
  padding-bottom: 1px;
}

.appie-features-content .hoverContent .textBox {
  position: relative;
  background: #ffffff;
  padding: 20px;
  font-size: 14px;
}

.appie-features-content .hoverContent .textBox span {
  position: absolute;
  top: -18px;
  left: 50%;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 50px;
  padding: 5px 15px 5px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.appie-features-content .hoverContent .textBox p {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.appie-features-content .hoverContent .textBox .tagBox {
  background: #ffec61;
  display: inline-block;
  padding: 2px 12px;
  margin-bottom: 10px;
}

.appie-features-content .hoverContent .textBox p.hours {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 400;
}

.appie-features-content .hoverContent .textBox p.learn {
  color: #7f13ab;
  margin-bottom: 5px;
}

.appie-features-content .hoverContent .textBox ul {
  list-style-type: square;
  margin-left: 20px;
}

.appie-features-content .hoverContent .textBox ul li {
  margin-bottom: 5px;
}

.appie-features-content .hoverContent .textBox ul li:last-child {
  margin-bottom: 0px;
}

.appie-features-content .hoverContent .textBox .shopBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.appie-features-content .hoverContent .textBox .shopBox a.icon {
  color: #343a40;
  font-size: 20px;
}

.appie-features-content .hoverContent .textBox .shopBox a.main-btn {
  line-height: 30px;
  width: 100%;
  margin: 0px 15px;
}

.appie-features-area.university {
  padding: 70px 0 60px;
}

@media (max-width: 767px) {
  .appie-features-area.university {
    padding: 40px 0 30px;
  }
}

.appie-features-area.university a.viewLink {
  position: absolute;
  top: -3px;
  right: 90px;
  font-size: 16px;
  color: #000000;
  z-index: 99;
}

.appie-features-area.university a.viewLink:hover {
  color: #7f13ab;
}

.appie-features-area.university .appie-features-content {
  display: flex;
}

.appie-features-area.university .appie-features-content .imgBox {
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  width: 350px;
}

.appie-features-area.university .appie-features-content .contentBox h6 {
  margin-bottom: 0px;
}

.appie-features-area.university .appie-features-content .hoverContent {
  width: 60%;
}

@media (max-width: 767px) {
  .appie-features-area.university .appie-features-content {
    display: block;
  }

  .appie-features-area.university .appie-features-content .imgBox {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    width: 100%;
  }
}

.appie-features-area.qBanko {
  padding: 70px 0 60px;
}

@media (max-width: 767px) {
  .appie-features-area.qBanko {
    padding: 40px 0 30px;
  }
}

.appie-features-area.qBanko .appie-features-content {
  display: flex;
}

.appie-features-area.qBanko .appie-features-content .imgBox {
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  width: 90%;
}

.appie-features-area.qBanko .appie-features-content .contentBox h6 {
  margin-bottom: 0px;
}

.appie-features-area.qBanko .appie-features-content .contentBox .instructorBox img {
  width: 70px;
}

.appie-features-area.qBanko .appie-features-content .contentBox .instructorBox p {
  margin-bottom: 0;
  line-height: 20px;
}

.appie-features-area.qBanko .appie-features-content .contentBox .btnBox .main-btn {
  padding: 0 20px;
  line-height: 30px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 500;
}

.appie-features-area.qBanko .appie-features-content .contentBox .btnBox .main-btn.two {
  background-color: #14214D;
  border-color: #14214D;
}

.appie-features-area.qBanko .appie-features-content .contentBox .btnBox .main-btn.two:hover {
  background-color: transparent;
  color: #14214D;
}

@media (max-width: 767px) {
  .appie-features-area.qBanko .appie-features-content {
    display: block;
  }

  .appie-features-area.qBanko .appie-features-content .imgBox {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    width: 100%;
  }

  .appie-features-area.qBanko .appie-features-content .contentBox .tagsBox {
    flex-direction: column;
  }

  .appie-features-area.qBanko .appie-features-content .contentBox .btnBox {
    margin-bottom: 15px;
  }
}

.appie-features-area.examOriented {
  padding: 80px 0 50px;
}

@media (max-width: 767px) {
  .appie-features-area.examOriented {
    padding: 10px 0;
  }
}

.appie-features-area.examOriented .appie-showcase-slider h3.courseHead span {
  display: inline;
  color: #7f13ab;
}

@media (max-width: 767px) {
  .appie-features-area.examOriented .appie-showcase-slider h3.courseHead {
    position: relative;
    top: 0px;
    left: 15px;
  }
}

/* .appie-features-area.examOriented .appie-features-content {
  margin-top: 50px;
} */

/* .appie-features-area.examOriented .appie-features-content:hover {
  margin-top: 45px;
} */

.appie-features-area.examOriented .appie-features-content .imgBox img {
  margin: 0 auto;
}

.appie-features-area.topUniversity {
  padding: 70px 0 60px;
}

@media (max-width: 767px) {
  .appie-features-area.topUniversity {
    padding: 40px 0;
  }
}

.appie-features-area.instructors {
  padding: 70px 0 0px;
}

@media (max-width: 767px) {
  .appie-features-area.instructors {
    padding: 40px 0 0px;
  }
}

.appie-features-area.instructors .appie-features-content .contentBox h6 {
  margin-bottom: 0px;
}

.appie-features-area.instructors .appie-features-content .contentBox p {
  margin-bottom: 5px;
}

.appie-features-area.upcoming {
  padding: 70px 0;
}

@media (max-width: 767px) {
  .appie-features-area.upcoming {
    padding: 40px 0;
  }
}

.appie-features-area.media {
  padding: 70px 0 50px;
}

@media (max-width: 767px) {
  .appie-features-area.media {
    padding: 40px 0 30px;
  }
}

.appie-features-area.coursesStarted {
  padding: 50px 0 0px;
}

.appie-features-area.coursesStarted .appie-features-tabs-btn .nav a {
  border-radius: 0;
  font-size: 15px;
  font-weight: 700;
  color: #000000;
  background: transparent;
  padding: 0px 30px 0px 0px;
}

.appie-features-area.coursesStarted .appie-features-tabs-btn .nav a.active {
  color: #7f13ab;
}

.appie-features-area.completeCourselist .appie-section-title .btnBox {
  margin-top: 15px;
}

.appie-features-area.completeCourselist .filter-accrodion {
  margin-top: 15px;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion {
  position: relative;
  transition: all 0.4s ease;
  overflow: hidden;
}


.appie-features-area.completeCourselist .filter-accrodion .accrodion.active {
  border-bottom: 1px solid #dddddd;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion+.accrodion {
  margin-top: 20px;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion .accrodion-inner {
  position: relative;
  transition: all 0.4s ease;
  overflow: hidden;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion .accrodion-title {
  cursor: pointer;
  position: relative;
  padding: 0px 20px 15px 20px;
  border-bottom: 1px solid #dddddd;
  transition: all 0.4s ease;
  padding-left: 0px;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion.active .accrodion-title {
  padding-bottom: 0;
  border: 0;
  padding-left: 0px;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion-content .inner {
  padding: 15px 20px;
  padding-left: 0px;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion-content .inner .ratingBox {
  color: #ffcc00;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion-content .inner .ratingBox:last-child {
  margin-bottom: 0px;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion-content .inner .ratingBox b {
  color: #000000;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 700;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion-content .inner p {
  margin-bottom: 10px;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion-content .inner p:last-child {
  margin-bottom: 0px;
}

.appie-features-area.completeCourselist .filter-accrodion .accrodion-content .inner p input {
  margin-right: 10px;
}

.appie-features-area.completeCourselist .completeCourselistBox {
  display: flex;
  flex-wrap: wrap;
}

/* .appie-features-area.completeCourselist .completeCourselistBox .appie-features-content {
  margin-top: 15px;
  width: 30.25%;
} */

@media (max-width: 767px) {
  .appie-features-area.completeCourselist .completeCourselistBox {
    display: block;
  }

  .appie-features-area.completeCourselist .completeCourselistBox .appie-features-content {
    width: auto;
  }

  .appie-features-area.completeCourselist .appie-section-title .btnBox {
    justify-content: center;
  }
}

.appie-features-area.advisoryBoardList {
  padding-top: 70px;
}

.appie-features-area.advisoryBoardList .advisoryBoardBox {
  display: flex;
  flex-wrap: wrap;
}

.appie-features-area.advisoryBoardList .advisoryBoardBox .appie-features-content {
  margin-top: 15px;
  /* width: 22.5%; */
  width: 31%;
  margin-left: 1%;
  margin-right: 1%;
}

@media (max-width: 767px) {
  .appie-features-area.advisoryBoardList .advisoryBoardBox {
    display: block;
  }

  .appie-features-area.advisoryBoardList .advisoryBoardBox .appie-features-content {
    width: auto;
  }
}

.appie-features-area-2 {
  background: #000000;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.appie-features-area-2 .features-shape-1 {
  position: absolute;
  left: 240px;
  top: 240px;
  z-index: -1;
  -webkit-animation: linear 20s animationFramesThree infinite;
          animation: linear 20s animationFramesThree infinite;
}

.appie-features-area-2 .features-shape-2 {
  position: absolute;
  right: 190px;
  top: 160px;
  z-index: -1;
  -webkit-animation: linear 20s animationFramesFive infinite;
          animation: linear 20s animationFramesFive infinite;
}

.appie-features-area-2 .features-shape-3 {
  position: absolute;
  right: 160px;
  bottom: 260px;
  z-index: -1;
  -webkit-animation: linear 20s animationFramesOne infinite;
          animation: linear 20s animationFramesOne infinite;
}

.appie-features-area-2.appie-features-area-5 {
  background: #fff;
}

.appie-features-box-item {
  border: 1px solid #202234;
  padding: 25px 30px 30px 70px;
  border-radius: 6px;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 20px;
  margin-left: 100px;
  margin-right: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-features-box-item {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-features-box-item {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media (max-width: 767px) {
  .appie-features-box-item {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 60px;
  }
}

.appie-features-box-item .title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-features-box-item .title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .appie-features-box-item .title {
    font-size: 20px;
  }
}

.appie-features-box-item .title::before {
  position: absolute;
  content: "";
  left: -40px;
  top: 0;
  height: 30px;
  width: 30px;
  background: #ff3e66;
  border: 6px solid #2a182d;
  border-radius: 50%;
}

.appie-features-box-item p {
  font-size: 14px;
  opacity: 0.6;
  color: #fff;
  margin-top: 3px;
}

.appie-features-box-item:hover {
  border-color: #ff3e66;
}

.appie-features-box-item.item-2 .title::before {
  background: #2eacfc;
  border-color: #15233d;
}

.appie-features-box-item.item-2:hover {
  border-color: #2eacfc;
}

.appie-features-box-item.item-3 .title::before {
  background: #33c956;
  border-color: #15262c;
}

.appie-features-box-item.item-3:hover {
  border-color: #33c956;
}

.appie-features-box-item.appie-features-box-5-item .title {
  color: #000000;
}

.appie-features-box-item.appie-features-box-5-item .title::before {
  border-color: rgba(9, 8, 8, 0.21);
}

.appie-features-box-item.appie-features-box-5-item p {
  color: #545454;
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-features-thumb {
    text-align: center;
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .appie-features-thumb {
    text-align: center;
    margin-top: 50px;
  }
}

.appie-features-6-area .appie-features-tabs-btn .nav a.active {
  color: #9b2cfa;
  border-right-color: #9b2cfa;
}

.appie-features-6-area .appie-features-tabs-btn .nav a.active i {
  background: #9b2cfa;
}

.appie-features-6-area .appie-features-content>span {
  color: #9b2cfa;
}

.appie-features-6-area .appie-features-content a {
  background: #9b2cfa;
  border-color: #9b2cfa;
}

.appie-features-6-area .appie-features-content a:hover {
  background: #fff;
  color: #9b2cfa;
}

/*===========================
    5.1 - APPIE TRAINING css 
===========================*/

.appie-training-area {
  padding: 80px 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.appie-training-content h3 {
  font-size: 32px;
  margin-bottom: 10px;
}

.appie-training-content h4 {
  font-size: 24px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .appie-training-area {
    padding: 40px 0 50px;
  }

  .appie-training-content h3 {
    font-size: 28px;
  }

  .appie-training-content h4 {
    font-size: 20px;
  }
}

/*===========================
    5.2 - APPIE INSTRUCTOR css 
===========================*/

.appie-instructor-area {
  /* padding: 150px 0px 80px 0px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.appie-instructor-content h3 {
  font-size: 32px;
  margin-bottom: 10px;
}

.appie-instructor-content h4 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .appie-instructor-area {
    padding: 40px 0 40px;
    background: #ecf0f4;
    background-image: none !important;
  }

  .appie-instructor-content h3 {
    font-size: 28px;
  }

  .appie-instructor-content h4 {
    font-size: 20px;
  }
}

/*===========================
    5.3 - Career Counseling css 
===========================*/

.appie-career-counseling {
  padding: 80px 0px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.appie-career-counseling h3 {
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: 15px;
}

.appie-career-counseling h4 {
  font-size: 35px;
  line-height: 35px;
  font-weight: 700;
  margin-bottom: 20px;
}

.appie-career-counseling p {
  font-size: 22px;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .appie-career-counseling {
    padding: 50px 0;
    background-position: left;
  }

  .appie-career-counseling h3 {
    font-size: 40px;
    line-height: 40px;
  }

  .appie-career-counseling h4 {
    font-size: 24px;
    line-height: 24px;
  }

  .appie-career-counseling p {
    font-size: 20px;
  }

}

/*===========================
    5.4 - Corporate Learning css 
===========================*/

.corporate-learning {
  padding: 150px 0px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.corporate-learning .headBlock {
  text-align: center;
  padding: 0px 80px;
}

.corporate-learning .headBlock h3 {
  font-size: 65px;
  line-height: 65px;
  font-weight: 900;
  margin-bottom: 40px;
  color: #ffffff;
}

.corporate-learning .headBlock h4 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #ffffff;
}

.corporate-learning .headBlock p {
  font-size: 18px;
  color: #ffffff;
}

@media (max-width: 767px) {
  .corporate-learning {
    padding: 70px 0;
    background-position: left;
  }

  .corporate-learning .headBlock {
    text-align: left;
    padding: 0px;
  }

  .corporate-learning .headBlock h3 {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 30px;
  }

  .corporate-learning .headBlock h4 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .corporate-learning .headBlock p {
    font-size: 16px;
  }
}

.corporate-content {
  padding: 70px 0px 50px;
  position: relative;
}

.corporate-content .container-fluid {
  padding: 0px 150px;
}

.corporate-content .learningBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.corporate-content .learningBlock .innerBox {
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  margin-right: 15px;
}

.corporate-content .learningBlock .innerBox .contentBox {
  padding: 10px;
  text-align: center;
}

.corporate-content .learningBlock .innerBox .contentBox h6 {
  font-size: 14px;
  font-weight: 700;
}

@media only screen and (max-width: 1700px) {
  .corporate-content .container-fluid {
    padding: 0px 15px;
  }
}

@media (max-width: 767px) {
  .corporate-content {
    padding: 50px 0px 30px;
  }

  .corporate-content .container-fluid {
    padding: 0px 15px;
  }

  .corporate-content .learningBlock {
    flex-wrap: wrap;
  }

  .corporate-content .learningBlock .innerBox {
    margin-right: 0px;
  }

  .corporate-content .learningBlock .innerBox .contentBox {
    padding: 15px;
  }

  .corporate-content .learningBlock .innerBox .contentBox h6 {
    font-size: 16px;
  }
}

.corporateBlock3 {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.corporateBlock3 .textBlock {
  padding: 5px 5px;
}

.corporateBlock3 .textBlock h3 {
  font-size: 45px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 30px;
}

.corporateBlock3 .textBlock h4 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.corporateBlock3 .textBlock p {
  font-size: 18px;
}

@media (max-width: 767px) {
  .corporateBlock3 {
    background-position: right;
  }

  .corporateBlock3 .textBlock {
    padding: 50px 0;
  }

  .corporateBlock3 .textBlock h3 {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 20px;
  }

  .corporateBlock3 .textBlock h4 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .corporateBlock3 .textBlock p {
    font-size: 16px;
  }
}

.counterBlock {
  padding: 70px 0px 48px;
  border-bottom: 2px solid #f0f0f0;
  background-image: url(/static/media/background_course.c45354fa.png);
  background-size: contain;
}

.counterBlock .innerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.counterBlock .innerBox div {
  text-align: center;
  border-radius: 6px;
  box-shadow: 0px 10px 30px 0px rgba(252, 254, 255, 0.1);
  padding: 35px 0px;
  margin-bottom: 30px;
  width: 215px;
}

.counterBlock .innerBox div h3 {
  font-size: 36px;
  color: white;
}

.counterBlock .innerBox div h3 span {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
}

@media (max-width: 767px) {
  .counterBlock {
    padding: 50px 0px 30px;
  }

  .counterBlock .innerBox {
    flex-wrap: wrap;
  }

  .counterBlock .innerBox div {
    width: 100%;
  }
}

.benefitBlock {
  padding: 70px 0px 80px;
}

.benefitBlock.borderBottom {
  border-bottom: 2px solid #f0f0f0;
}

.benefitBlock h3 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 20px;
}

.benefitBlock .innerBox {
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 30px;
  margin-top: 30px;
}

.benefitBlock .innerBox div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.benefitBlock .innerBox i {
  font-size: 40px;
  color: #7f13ab;
  margin-right: 20px;
}

.benefitBlock .innerBox h6 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.benefitBlock .innerBox p {
  font-size: 16px;
}

@media (max-width: 767px) {
  .benefitBlock {
    padding: 50px 0px 60px;
  }

  .benefitBlock h3 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0px;
  }

}


.featuresBlock {
  padding: 70px 0px 80px;
  background: #f5f8fd;
}

.featuresBlock h3 {
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.featuresBlock .innerBox {
  background: #ffffff;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 30px;
  margin-top: 30px;
  text-align: center;
}

.featuresBlock .innerBox i {
  background: #7f13ab;
  color: #ffffff;
  font-size: 30px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  margin-bottom: 20px;
  border-radius: 50%;
}

.featuresBlock .innerBox h6 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.featuresBlock .innerBox p {
  font-size: 16px;
}

@media (max-width: 767px) {
  .featuresBlock {
    padding: 50px 0px 60px;
  }

  .featuresBlock h3 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0px;
  }
}

/*===========================
    Category Area
===========================*/
.category-area {
  position: relative;
  overflow: hidden;
  z-index: 15;
  padding: 60px 0 0px;
}

@media (max-width: 767px) {
  .category-area {
    padding: 30px 0 0px;
  }
}

.category-area .categoryBox {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  margin-top: 30px;
  transition: all 0.3s ease-out 0s;
}

.category-area .categoryBox:hover {
  margin-top: 25px;
}

.category-area .categoryText {
  text-align: center;
  padding: 0px 50px;
}

.category-area .categoryText h3 {
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
}

.category-area .categoryText p {
  color: #ffffff;
}

/*===========================
    Calendar Area
===========================*/
.calendar-area {
  position: relative;
  overflow: hidden;
  z-index: 15;
  padding: 80px 0;
}

.calendar-area .calendarBox {
  background: #EDF2FF;
  border-radius: 10px;
  padding: 0px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar-area .calendarBox .calendarText h3 {
  font-size: 32px;
  margin-bottom: 20px;
}

.calendar-area .calendarBox .calendarText ul {
  float: left;
  text-align: left;
  margin-right: 40px;
}

.calendar-area .calendarBox .calendarText ul li {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.calendar-area .calendarBox .calendarText ul li i {
  color: #7f13ab;
  margin-right: 10px;
}

.calendar-area.useCalendar {
  padding: 0;
}

.calendar-area.useCalendar .calendarBox .calendarImg {
  width: 35%;
}

@media (max-width: 767px) {
  .calendar-area {
    padding: 50px 0;
  }

  .calendar-area .calendarBox {
    padding: 30px;
    flex-direction: column;
  }

  .calendar-area .calendarBox .calendarText {
    text-align: center;
    margin-bottom: 30px;
  }

  .calendar-area .calendarBox .calendarText h3 {
    font-size: 26px;
  }

  .calendar-area.useCalendar .calendarBox .calendarText ul {
    margin-right: 0px;
  }

  .calendar-area.useCalendar .calendarBox .calendarImg {
    width: 100%;
  }
}

/*===========================
    Course Calendar Area
===========================*/
.course-calendar {
  position: relative;
  overflow: hidden;
  z-index: 15;
  padding: 60px 0 70px;
}

.course-calendar .container-fluid {
  padding: 0px 250px;
}

@media only screen and (max-width: 1700px) {
  .course-calendar .container-fluid {
    padding: 0px 15px;
  }
}

@media (max-width: 767px) {
  .course-calendar .container-fluid {
    padding: 0px 15px;
  }
}

.course-calendar .leftBox {
  background: #F8F4FF;
  padding: 30px;
}

.course-calendar .leftBox .categoryBox b {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.course-calendar .leftBox .categoryBox select {
  height: 40px;
  width: 100%;
  padding: 0px 10px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 0px;
  margin-bottom: 20px;
}

.course-calendar .leftBox .categoryBox p {
  margin-bottom: 10px;
}

.course-calendar .leftBox .categoryBox input[type='checkbox'] {
  margin-right: 5px;
}

.course-calendar .rightBox .headSec {
  background: #0C0C4E;
  padding: 30px;
}

.course-calendar .rightBox .headSec span {
  display: block;
  color: #c7c7c7;
  font-size: 13px;
  margin-bottom: 5px;
}

.course-calendar .rightBox .headSec input {
  width: 100%;
  background: transparent;
  border: 0;
  color: #ffffff;
}

.course-calendar .rightBox .headSec select {
  height: 20px;
  width: 100%;
  padding: 0px;
  background: transparent;
  color: #ffffff;
  border: 0;
  border-radius: 0px;
}

.course-calendar .rightBox .headSec select option {
  color: #000000;
}

.course-calendar .rightBox .contSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dddddd;
}

.course-calendar .rightBox .contSec .dateBox {
  padding: 20px;
  border-right: 1px solid #dddddd;
}

.course-calendar .rightBox .contSec .dateBox.last {
  border-right: 0;
}

.course-calendar .rightBox .contSec .dateBox.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.course-calendar .rightBox .contSec .dateBox h3 {
  font-size: 50px;
  text-align: center;
  color: #505050;
  font-weight: 700;
  margin-bottom: 20px;
}

.course-calendar .rightBox .contSec .dateBox.active h3 {
  color: #7f13ab;
}

.course-calendar .rightBox .contSec .dateBox h3 span {
  display: block;
  font-size: 20px;
  text-transform: uppercase;
}

.course-calendar .rightBox .contSec .dateBox b {
  display: block;
  background: #F0F5FF;
  color: #3877D0;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
}

.course-calendar .rightBox .contSec .dateBox .courseBox {
  position: relative;
  margin-top: 24px;
}

.course-calendar .rightBox .contSec .dateBox .courseBox span {
  display: block;
  font-size: 12px;
  color: #8d8d8d;
}

.course-calendar .rightBox .contSec .dateBox .courseBox.actOne span {
  color: #000000;
}

.course-calendar .rightBox .contSec .dateBox .courseBox.actOne h5,
.course-calendar .rightBox .contSec .dateBox .courseBox.actOne p {
  color: #3877D0;
}

.course-calendar .rightBox .contSec .dateBox .courseBox.actOne::before {
  content: "";
  position: absolute;
  top: 0;
  left: -27px;
  background: #3877D0;
  width: 5px;
  height: 100%;
}


.course-calendar .rightBox .contSec .dateBox .courseBox.actTwo span {
  color: #000000;
}

.course-calendar .rightBox .contSec .dateBox .courseBox.actTwo h5,
.course-calendar .rightBox .contSec .dateBox .courseBox.actTwo p {
  color: #FF9300;
}

.course-calendar .rightBox .contSec .dateBox .courseBox.actTwo::before {
  content: "";
  position: absolute;
  top: 0;
  left: -27px;
  background: #FF9300;
  width: 5px;
  height: 100%;
}

@media (max-width: 767px) {
  .course-calendar {
    padding: 40px 0 30px;
  }

  .course-calendar .rightBox .headSec input {
    margin-bottom: 30px;
  }

  .course-calendar .rightBox .contSec {
    display: block;
  }

  .course-calendar .rightBox .contSec .dateBox {
    border: 0;
    border-bottom: 2px solid #dddddd;
  }

  .course-calendar .rightBox .contSec .dateBox .courseBox.actOne::before,
  .course-calendar .rightBox .contSec .dateBox .courseBox.actTwo::before {
    left: -20px;
  }
}

/*===========================
    Approvals Area
===========================*/
.approvals-area {
  position: relative;
  overflow: hidden;
  z-index: 15;
  padding: 0px
}

.approvals-area .approvals-thumb {
  position: relative;
}

.approvals-area .approvals-thumb .slick-slide img {
  margin: 0 auto;
}

/*===========================
    Date and Venue Area
===========================*/
.date-venue-area {
  position: relative;
  overflow: hidden;
  z-index: 15;
  padding: 70px 0;
}

.date-venue-area .venueBox {
  background: #FFE8F8;
  border-radius: 10px;
  padding: 0px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-venue-area .venueBox .venueText h3 {
  font-size: 32px;
  margin-bottom: 10px;
}

.date-venue-area .venueBox .venueText h4 {
  font-size: 28px;
  margin-bottom: 20px;
}

.date-venue-area .venueBox .venueText .main-btn {
  background-color: #0F0E0F;
  border-color: #0F0E0F;
}

.date-venue-area .venueBox .venueText .main-btn:hover {
  background-color: transparent;
  color: #0F0E0F;
}

@media (max-width: 767px) {
  .date-venue-area {
    padding: 50px 0;
  }

  .date-venue-area .venueBox {
    padding: 30px;
    flex-direction: column;
  }

  .date-venue-area .venueBox .venueImg {
    margin-bottom: 30px;
  }

  .date-venue-area .venueBox .venueText {
    text-align: center;
    margin-bottom: 30px;
  }

  .date-venue-area .venueBox .venueText h3 {
    font-size: 26px;
  }

  .date-venue-area .venueBox .venueText h4 {
    font-size: 22px;
  }
}

/*===========================
    Banner Area
===========================*/
.big-banner-area {
  position: relative;
  overflow: hidden;
  z-index: 15;
}

.big-banner-area .container-fluid {
  padding: 0px 250px;
}

@media only screen and (max-width: 1700px) {
  .big-banner-area .container-fluid {
    padding: 0px 15px;
  }
}

@media (max-width: 767px) {
  .big-banner-area .container-fluid {
    padding: 0px 15px;
  }
}

/*===========================
    Our Clients Area
===========================*/
.clients-area {
  position: relative;
  overflow: hidden;
  z-index: 15;
  padding: 50px 0px 40px;
  border-top: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
}

@media (max-width: 767px) {
  .clients-area {
    padding: 30px 0px 20px;
  }
}

.clients-area .clients-thumb {
  position: relative;
}

.clients-area .clients-thumb .slick-slide img {
  margin: 0 auto;
}

/*===========================
    Home About Area
===========================*/
.home-about-area {
  position: relative;
  overflow: hidden;
  z-index: 15;
  padding: 80px 0;
}

.home-about-area .aboutText h2 {
  font-size: 50px;
  font-weight: 700;
}

.home-about-area .aboutText h3 {
  color: #7f13ab;
  font-weight: 700;
  margin-bottom: 25px;
}

.home-about-area .aboutText h4 {
  font-weight: 700;
  margin-bottom: 25px;
}

.home-about-area .aboutText p {
  margin-bottom: 20px;
}

.home-about-area .aboutText p:last-child {
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .home-about-area {
    padding: 40px 0 50px;
  }

  .home-about-area .aboutText {
    margin-bottom: 40px;
  }

  .home-about-area .aboutText h2 {
    font-size: 40px;
  }

  .home-about-area .aboutText h3 {
    font-size: 26px;
  }

  .home-about-area .aboutText h4 {
    font-size: 20px;
  }
}

/*===========================
    6.APPIE TRAFFICE css 
===========================*/
.appie-traffic-area {
  position: relative;
  overflow: hidden;
  z-index: 15;
  padding: 100px 0px 90px;
}

@media (max-width: 767px) {
  .appie-traffic-area {
    padding: 60px 0px;
  }
}

.appie-traffic-area .traffic-thumb {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 42%;
}

@media (max-width: 767px) {
  .appie-traffic-area .traffic-thumb {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-traffic-area .traffic-thumb {
    display: none;
  }
}

.appie-traffic-area .bannerBoxOne {
  background: #fff4de;
  border-radius: 10px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 20px;
}

.appie-traffic-area .bannerBoxOne .bannerText {
  padding: 0px 40px;
}

.appie-traffic-area .bannerBoxOne .bannerText h3 {
  font-size: 32px;
  margin-bottom: 10px;
}

.appie-traffic-area .bannerBoxOne .bannerText p {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .appie-traffic-area .bannerBoxOne {
    flex-direction: column;
  }

  .appie-traffic-area .bannerBoxOne .bannerText {
    padding: 20px 30px;
  }

  .appie-traffic-area .bannerBoxOne .bannerImg {
    padding: 0px 15px;
  }
}

.appie-traffic-area .bannerBoxOne.bgTwo {
  background: #d3eaff;
  margin-bottom: 30px;
}

.appie-traffic-area .bannerBoxOne.bgTwo .main-btn {
  background-color: #2b70fa;
  border-color: #2b70fa;
}

.appie-traffic-area .bannerBoxOne.bgTwo .main-btn:hover {
  background-color: transparent;
  color: #2b70fa;
}

.appie-traffic-title {
  padding-right: 220px;
  margin-bottom: 43px;
}

@media (max-width: 767px) {
  .appie-traffic-title {
    padding-right: 0;
  }
}

.appie-traffic-title span {
  font-size: 14px;
  font-weight: 700;
  color: #7f13ab;
}

.appie-traffic-title .title {
  font-size: 44px;
  line-height: 54px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-traffic-title .title {
    font-size: 36px;
    line-height: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-traffic-title .title {
    font-size: 36px;
    line-height: 46px;
  }
}

@media (max-width: 767px) {
  .appie-traffic-title .title {
    font-size: 30px;
    line-height: 40px;
  }
}

.appie-traffic-service {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 5px 20px;
  transition: all 0.3s ease-out 0s;
}

.appie-traffic-service:hover {
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
  border-top: 3px solid #7f13ab;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-traffic-service {
    margin-top: 10px;
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .appie-traffic-service {
    margin-top: 10px;
    padding: 10px;
    padding-bottom: 25px;
  }
}

.appie-traffic-service .imgBox {
  margin-right: 25px;
}

@media (max-width: 767px) {
  .appie-traffic-service .imgBox img {
    max-width: 120px;
  }
}

.appie-traffic-service .textBox {
  position: relative;
}

.appie-traffic-service .textBox a {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 5px;
  color: #000000;
}

.appie-traffic-service .textBox a:hover {
  color: #7f13ab
}

.appie-traffic-service .textBox p {
  font-size: 15px;
  color: #5d5d5d;
}

.appie-traffic-service .textBox .ratingBox {
  color: #ffcc00;
}

.appie-traffic-service .textBox .ratingBox b {
  color: #000000;
  margin-left: 10px;
}

.appie-traffic-service .shareBox {
  position: absolute;
  bottom: 22px;
  right: 20px;
}

@media (max-width: 767px) {
  .appie-traffic-service .shareBox {
    right: 10px;
    bottom: 3px;
  }
}

.appie-traffic-service .shareBox a {
  color: #ff3e66;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 0px;
}

.appie-traffic-service .icon {
  position: absolute;
  left: 0;
  top: 5px;
}

.appie-traffic-service .icon i {
  height: 26px;
  width: 26px;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
  background: linear-gradient(135deg, #84bcfa 0%, #7f13ab 100%);
  color: #fff;
  font-size: 14px;
  box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
}

.appie-traffic-service .title {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 7px;
}

.appie-traffic-service p {
  font-size: 15px;
}

.appie-traffic-service.item-2 .icon i {
  background: linear-gradient(135deg, #f7b8af 0%, #f8452d 100%);
}

.appie-traffic-service.item-3 .icon i {
  background: linear-gradient(135deg, #a0eac2 0%, #31c369 100%);
}

.appie-traffic-service.item-4 .icon i {
  background: linear-gradient(135deg, #d8aaff 0%, #9b2cfa 100%);
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .traffic-btn {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .traffic-btn {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .traffic-btn {
    text-align: left;
  }
}

.traffic-btn a.main-btn {
  background: transparent;
  color: #000000;
  border: 2px solid #e7eaef;
}

.traffic-btn a.main-btn i {
  padding-left: 6px;
}

.traffic-btn a.main-btn:hover {
  background: #7f13ab;
  color: #fff;
}

/*===========================
    6.APPIE TRAFFICE css 
===========================*/
.appie-testimonial-area {
  padding: 70px 0px 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 708px; */
}

@media (max-width: 767px) {
  .appie-testimonial-area {
    padding: 40px 0 40px;
    height: auto;
  }
}

/* .appie-testimonial-area .appie-showcase-slider .appie-features-content:hover {
  margin-top: 40px;
} */

.appie-testimonial-area .appie-showcase-slider .contentBox {
  padding: 20px;
}

.appie-testimonial-area .appie-showcase-slider .slick-slide.slick-active.slick-center .appie-features-content {
  margin-top: 10px;
}

.appie-testimonial-area .appie-showcase-slider .slick-slide.slick-active.slick-center .contentBox {
  padding: 70px 40px;
}

@media (max-width: 767px) {
  .appie-testimonial-area .appie-showcase-slider .slick-slide.slick-active.slick-center .appie-features-content {
    margin-top: 0px;
  }

  .appie-testimonial-area .appie-showcase-slider .slick-slide.slick-active.slick-center .contentBox {
    padding: 30px;
  }

  .appie-testimonial-area .appie-showcase-slider .contentBox {
    padding: 30px;
  }
}

.appie-testimonial-area .appie-showcase-slider .userBox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.appie-testimonial-area .appie-showcase-slider .userBox img {
  width: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.appie-testimonial-area .appie-showcase-slider .userBox .userDetail h4 {
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 5px;
  font-weight: 700;
}

.appie-testimonial-area .appie-showcase-slider .userBox .userDetail h5 {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #7f13ab;
}

.appie-testimonial-area .appie-showcase-slider .userBox .userDetail h6 {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0;
  font-weight: 700;
}

.appie-testimonial-area .appie-showcase-slider .userBox img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 1px solid #f5f5f5;
}

.appie-testimonial-area .appie-showcase-slider .writeupBox {
  margin-bottom: 20px;
  padding-right: 10px;
  height: 150px;
  overflow: auto;
}

.appie-testimonial-area .appie-showcase-slider .writeupBox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.appie-testimonial-area .appie-showcase-slider .writeupBox::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.appie-testimonial-area .appie-showcase-slider .writeupBox::-webkit-scrollbar-thumb {
  background-color: #7f13ab;
  border-radius: 10px;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .author-info,
.appie-testimonial-slider .appie-testimonial-item .author-info {
  margin-bottom: 15px;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .author-info img,
.appie-testimonial-slider .appie-testimonial-item .author-info img {
  display: inline-block;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .author-info .title,
.appie-testimonial-slider .appie-testimonial-item .author-info .title {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  padding-top: 10px;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .author-info span,
.appie-testimonial-slider .appie-testimonial-item .author-info span {
  font-size: 14px;
  color: #fff;
  opacity: 0.5;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .text p,
.appie-testimonial-slider .appie-testimonial-item .text p {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .text ul,
.appie-testimonial-slider .appie-testimonial-item .text ul {
  margin: 10px 0 0;
  padding: 0;
  list-style-type: none;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .text ul li,
.appie-testimonial-slider .appie-testimonial-item .text ul li {
  display: inline-block;
  margin: 0 2px;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .text ul li i,
.appie-testimonial-slider .appie-testimonial-item .text ul li i {
  color: #ffb848;
}

.appie-testimonial-slider-rtl .slick-arrow,
.appie-testimonial-slider .slick-arrow {
  position: absolute;
  top: 62%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 30px;
  color: #fff;
  z-index: 11;
  cursor: pointer;
  opacity: 0.5;
}

.appie-testimonial-slider-rtl .slick-arrow.prev,
.appie-testimonial-slider .slick-arrow.prev {
  left: -80px;
}

.appie-testimonial-slider-rtl .slick-arrow.next,
.appie-testimonial-slider .slick-arrow.next {
  right: -80px;
}

.appie-testimonial-slider-rtl .slick-dots,
.appie-testimonial-slider .slick-dots {
  position: absolute;
  left: 50%;
  bottom: -65px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.appie-testimonial-slider-rtl .slick-dots li,
.appie-testimonial-slider .slick-dots li {
  display: inline-block;
}

.appie-testimonial-slider-rtl .slick-dots li button,
.appie-testimonial-slider .slick-dots li button {
  border: 0;
  background: #fff;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  font-size: 0;
  opacity: 0.5;
  margin: 0 4px;
}

.appie-testimonial-slider-rtl .slick-dots li.slick-active button,
.appie-testimonial-slider .slick-dots li.slick-active button {
  opacity: 1;
}

.appie-testimonial-2-box {
  /* background-image: url(../images/testimonial-bg-2.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-testimonial-2-box {
    padding: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-testimonial-2-box {
    padding: 50px 30px;
  }
}

@media (max-width: 767px) {
  .appie-testimonial-2-box {
    padding: 50px 30px;
  }
}

.appie-testimonial-slider-2 .slick-arrow {
  position: absolute;
  right: 0;
  bottom: 0px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  border-radius: 50%;
  z-index: 9;
  cursor: pointer;
}

.appie-testimonial-slider-2 .slick-arrow:hover {
  background: #ff3e66;
  color: #fff;
  border-color: #ff3e66;
}

.appie-testimonial-slider-2 .slick-arrow.prev {
  right: 60px;
}

.appie-testimonial-slider-2 .slick-arrow.next {
  right: 0;
}

.appie-testimonial-slider-2-item .item {
  display: flex;
  padding-right: 30px;
  padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-testimonial-slider-2-item .item {
    display: block;
  }
}

@media (max-width: 767px) {
  .appie-testimonial-slider-2-item .item {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
}

.appie-testimonial-slider-2-item .thumb {
  text-align: center;
  min-width: 140px;
  margin-right: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-testimonial-slider-2-item .thumb {
    margin-bottom: 30px;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .appie-testimonial-slider-2-item .thumb {
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.appie-testimonial-slider-2-item .thumb img {
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0px 20px 30px 0px rgba(0, 16, 49, 0.1);
  margin-bottom: 13px;
}

.appie-testimonial-slider-2-item .thumb ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.appie-testimonial-slider-2-item .thumb ul li {
  display: inline-block;
}

.appie-testimonial-slider-2-item .thumb ul li i {
  font-size: 14px;
  color: #ffb848;
}

.appie-testimonial-slider-2-item .thumb span {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  opacity: 0.7;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-testimonial-slider-2-item .content {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .appie-testimonial-slider-2-item .content {
    text-align: center;
  }
}

.appie-testimonial-slider-2-item .content p {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
}

@media (max-width: 767px) {
  .appie-testimonial-slider-2-item .content p {
    font-size: 16px;
  }
}

.appie-testimonial-slider-2-item .content .author-info {
  margin-top: 27px;
}

.appie-testimonial-slider-2-item .content .author-info .title {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.appie-testimonial-slider-2-item .content .author-info span {
  font-size: 14px;
  color: #fff;
  opacity: 0.7;
}

.appie-testimonial-about-area {
  /* background-image: url(../images/map-bg.png); */
  background-size: auto;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.appie-testimonial-about-area .appie-section-title {
  margin-bottom: 30px;
}

.appie-testimonial-about-area .appie-section-title .appie-title {
  padding: 0 30px;
}

.testimonial-about-slider-active .testimonial-box {
  padding: 60px 100px 80px;
  box-shadow: 0px 40px 60px 0px rgba(14, 17, 51, 0.1);
  text-align: center;
  background: #fff;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 80px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .testimonial-about-slider-active .testimonial-box {
    padding: 60px 50px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-about-slider-active .testimonial-box {
    margin-left: 50px;
    margin-right: 50px;
    padding: 30px 30px 50px;
  }
}

@media (max-width: 767px) {
  .testimonial-about-slider-active .testimonial-box {
    margin-left: 0px;
    margin-right: 0px;
    padding: 30px 30px 50px;
  }
}

.testimonial-about-slider-active .testimonial-box::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
}

.testimonial-about-slider-active .testimonial-box .icon i {
  font-size: 32px;
  color: #4985ff;
}

.testimonial-about-slider-active .testimonial-box .ratings-icon {
  padding-top: 25px;
  padding-bottom: 20px;
}

.testimonial-about-slider-active .testimonial-box .ratings-icon ul li {
  display: inline-block;
}

.testimonial-about-slider-active .testimonial-box .ratings-icon ul li i {
  color: #faa116;
  font-size: 14px;
}

.testimonial-about-slider-active .testimonial-box p {
  font-size: 20px;
  color: #000000;
  line-height: 30px;
}

@media (max-width: 767px) {
  .testimonial-about-slider-active .testimonial-box p {
    font-size: 16px;
    line-height: 26px;
  }
}

.testimonial-about-slider-active .slick-arrow {
  position: absolute;
  top: 43%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 36px;
  border: 2px solid #db0f30;
  color: #db0f30;
  border-radius: 50%;
  transition: all linear 0.3s;
  background: #fff;
}

.testimonial-about-slider-active .slick-arrow:hover {
  background: #db0f30;
  color: #fff;
}

.testimonial-about-slider-active .slick-arrow.prev {
  left: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-about-slider-active .slick-arrow.prev {
    left: 0;
  }
}

@media (max-width: 767px) {
  .testimonial-about-slider-active .slick-arrow.prev {
    left: -10px;
  }
}

.testimonial-about-slider-active .slick-arrow.next {
  right: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-about-slider-active .slick-arrow.next {
    right: 0;
  }
}

@media (max-width: 767px) {
  .testimonial-about-slider-active .slick-arrow.next {
    right: -10px;
  }
}

.testimonial-box-about-slider-small-active {
  margin-top: -40px;
}

.testimonial-box-about-slider-small-active .item {
  padding-top: 20px;
  padding-bottom: 50px;
}

.testimonial-box-about-slider-small-active .item .thumb {
  max-width: 70px;
  text-align: center;
}

.testimonial-box-about-slider-small-active .item .thumb img {
  display: inline-block;
  width: 70px;
}

.testimonial-box-about-slider-small-active .item .content {
  display: none;
  text-align: center;
}

.testimonial-box-about-slider-small-active .item .content .title {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
}

.testimonial-box-about-slider-small-active .item .content span {
  font-size: 13px;
  font-weight: 700;
  color: #888890;
  white-space: nowrap;
}

.testimonial-box-about-slider-small-active .item.slick-center {
  margin-top: -20px;
}

/* =========================vue custom css======================== */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .testimonial-box-about-slider-small-active .slick-center .item {
    margin-top: -10px;
  }
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item .thumb {
  max-width: 70px;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item .thumb img {
  width: 70px;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item .content {
  display: inline-block;
  margin: 10px 0 0 -24px;
}

/*===========================
  SUBSCRIBE SECTION
===========================*/
.appie-subscribe-area {
  padding: 60px 0px 70px;
}

.appie-subscribe-area .subscribeBox .input-box {
  display: flex;
}

@media (max-width: 767px) {
  .appie-subscribe-area {
    padding: 40px 0px 50px;
  }

  .appie-subscribe-area .subscribeBox .input-box {
    display: block;
  }
}

.appie-subscribe-area .subscribeBox .input-box input {
  width: 100%;
  height: 50px;
  padding-left: 30px;
  border-radius: 10px;
  border: 1px solid #dddddd;
  background: #f4f4f4;
}

.appie-subscribe-area .subscribeBox .input-box button {
  margin-left: -20px;
  padding: 0 30px;
  color: #fff;
  font-weight: 700;
  background-color: #7f13ab;
  border: 0;
  border-radius: 10px;
  line-height: 50px;
}

@media (max-width: 767px) {
  .appie-subscribe-area .subscribeBox .input-box button {
    margin-top: 12px;
    margin-left: 0;
  }
}

/* =========================vue custom css======================== */

/*===========================
    7.APPIE TEAM css 
===========================*/
.appie-team-item .thumb {
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.appie-team-item .thumb img {
  transition: all 0.3s ease-out 0s;
  width: 100%;
}

.appie-team-item .thumb::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 9;
  opacity: 0;
  transition: all 0.3s ease-out 0s;
}

.appie-team-item .thumb ul {
  position: absolute;
  left: 50%;
  bottom: 30px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin: 0;
  padding: 0;
  list-style-type: none;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
}

.appie-team-item .thumb ul li {
  display: inline-block;
  margin: 0 3px;
}

.appie-team-item .thumb ul li a {
  display: inline-block;
  height: 36px;
  width: 36px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  line-height: 36px;
  border-radius: 50px;
  color: #fff;
}

.appie-team-item .thumb ul li a:hover {
  background: #fff;
  color: #7f13ab;
  border-color: #fff;
}

.appie-team-item .content {
  padding-top: 27px;
}

.appie-team-item .content .title {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 8px;
}

.appie-team-item .content span {
  font-size: 14px;
  color: #81818d;
}

.appie-team-item:hover .thumb::before {
  opacity: 0.5;
}

.appie-team-item:hover .thumb ul {
  opacity: 1;
  visibility: visible;
}

.appie-team-item:hover .thumb img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.appie-team-item.appie-team-item-about .thumb {
  border-radius: 6px;
  overflow: hidden;
}

.appie-team-item.appie-team-item-about .thumb::before {
  background-color: #7f13ab;
}

.appie-team-item.appie-team-item-about .thumb ul {
  left: 30px;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.appie-team-item.appie-team-item-about .thumb ul li a {
  border-radius: 6px;
}

.appie-team-item.appie-team-item-about:hover .thumb::before {
  opacity: 0.9;
}

.appie-team-item.appie-team-item-about.appie-team-item-8 .thumb::before {
  background: #db0f30;
}

.appie-team-item.appie-team-item-about.appie-team-item-8 .thumb ul li a:hover {
  color: #db0f30;
}

.team-btn a {
  background: transparent;
  color: #000000;
  border: 2px solid #e7eaef;
}

.team-btn a i {
  padding-left: 6px;
}

.team-btn a:hover {
  background: #7f13ab;
  color: #fff;
}

.appie-team-about-area .team-btn a {
  border-color: #7f13ab;
  color: #7f13ab;
}

.appie-team-about-area .team-btn a:hover {
  color: #fff;
}

.appie-team-about-area.appie-team-8-area .team-btn a {
  border-color: #db0f30;
  color: #db0f30;
}

.appie-team-about-area.appie-team-8-area .team-btn a:hover {
  background: #db0f30;
  color: #fff;
}

/*===========================
    8.APPIE PRICING css 
===========================*/
.appie-pricing-area {
  background: #eef1f6;
}

.appie-pricing-area {
  position: relative;
  z-index: 10;
}

.appie-pricing-area .section-title {
  padding-bottom: 48px;
}

.appie-pricing-area .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  vertical-align: middle;
  margin: 0;
}

.appie-pricing-area .switch input {
  display: none;
}

.appie-pricing-area .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  background: #fff;
}

.appie-pricing-area .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  background-color: #7f13ab;
  transition: 0.4s;
}

.appie-pricing-area .switch.off .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.appie-pricing-area .slider.round {
  border-radius: 34px;
}

.appie-pricing-area .slider.round:before {
  border-radius: 50%;
}

.appie-pricing-area ul.switch-toggler-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.appie-pricing-area ul.switch-toggler-list li a {
  font-size: 14px;
  font-weight: 700;
  color: #545454;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
}

.appie-pricing-area ul.switch-toggler-list li.active a {
  color: #000000;
}

.pricing-one__single {
  background: #fff;
  padding: 40px;
  border-radius: 6px;
  position: relative;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-one__single {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .pricing-one__single {
    margin-bottom: 30px;
  }
}

.pricing-one__single .pricig-heading {
  border-bottom: 1px solid #e1e4ea;
  padding-bottom: 25px;
}

.pricing-one__single .pricig-heading h6 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 17px;
}

.pricing-one__single .pricig-heading .price-range {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
}

.pricing-one__single .pricig-heading .price-range sup {
  font-size: 24px;
}

.pricing-one__single .pricig-heading .price-range span {
  font-size: 44px;
  font-weight: 900;
}

.pricing-one__single .pricig-heading .price-range p {
  display: inline-block;
  font-size: 14px;
}

.pricing-one__single .pricig-heading>p {
  font-size: 14px;
}

.pricing-one__single .pricig-body {
  padding-top: 25px;
}

.pricing-one__single .pricig-body ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.pricing-one__single .pricig-body ul li {
  font-size: 15px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 10px;
}

.pricing-one__single .pricig-body ul li i {
  height: 26px;
  width: 26px;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
  background: #effaf3;
  color: #31c369;
}

.pricing-one__single .pricig-body .pricing-btn a {
  background: transparent;
  color: #000000;
  border: 2px solid #e7eaef;
}

.pricing-one__single .pricig-body .pricing-btn a:hover {
  background: #f8452d;
  color: #fff;
  border-color: #f8452d;
}

.pricing-one__single .pricing-rebon {
  position: absolute;
  right: 30px;
  top: -14px;
}

.pricing-one__single .pricing-rebon span {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background: #f8452d;
  line-height: 28px;
  padding: 0 18px;
  border-radius: 30px;
  box-shadow: 0px 10px 20px 0px rgba(183, 30, 10, 0.3);
}

.pricing-one__single.center {
  box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}

.pricing-one__single.center .pricig-body ul li i {
  color: #f8452d;
}

.pricing-one__single.center .pricig-body .pricing-btn a {
  background: #f8452d;
  border-color: #f8452d;
  color: #fff;
}

.pricing-one__single:hover {
  box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}

.pricing-one__single.pricing-one__single_2 {
  border: 1px solid #eef0f4;
}

.pricing-one__single.pricing-one__single_2 .pricig-body ul li i {
  background: #eef4fe;
  color: #3172f5;
}

.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-btn a {
  border-radius: 30px;
}

.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-btn a:hover {
  background: #ff3e66;
  border-color: #ff3e66;
}

.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-rebon {
  right: 0;
  top: 20px;
}

.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-rebon span {
  border-radius: 30px 0 0 30px;
  background: #2a6df5;
  box-shadow: none;
}

.pricing-one__single.pricing-one__single_2.active {
  border-top: 3px solid #ff3e66;
  box-shadow: 0px -3px 0px 0px rgba(255, 62, 102, 0.004),
    0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}

.pricing-one__single.pricing-one__single_2.active .pricig-body .pricing-btn a {
  background: #ff3e66;
  border-color: #ff3e66;
  color: #fff;
}

.pricing-one__single.pricing-one__single_2.item-2 .pricig-body ul li i {
  background: #effaf3;
  color: #31c369;
}

.pricing-one__single.pricing-one__single_2:hover {
  border-color: #fff;
  border-top: 3px solid #ff3e66;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.appie-pricing-2-area .appie-section-title .nav {
  display: inline-block !important;
  border: 2px solid #e7eaef;
  padding: 3px;
  border-radius: 40px;
  margin-top: 55px;
}

.appie-pricing-2-area .appie-section-title .nav li {
  display: inline-block;
}

.appie-pricing-2-area .appie-section-title .nav li a {
  border-radius: 30px;
  font-weight: 700;
  font-size: 14px;
  color: #545454;
  padding: 5px 20px;
}

.appie-pricing-2-area .appie-section-title .nav li a.active {
  color: #fff;
  background: #ff3e66;
}

.appie-pricing-6-area .pricing-one__single .pricing-rebon span {
  background: #9b2cfa;
  box-shadow: none;
}

.appie-pricing-6-area .pricing-one__single.center .pricig-body .pricing-btn a {
  background: #9b2cfa;
  border-color: #9b2cfa;
}

/*===========================
    9.APPIE FAQ css 
===========================*/
.appie-faq-area {
  background: #eef1f6;
  position: relative;
  /* background-image: url(../images/shape/faq-shape.png); */
  background-position: 94% 100%;
  background-size: auto;
  background-repeat: no-repeat;
}

.faq-accrodion {
  margin-top: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-accrodion {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .faq-accrodion {
    margin-top: 30px;
  }
}

.faq-accrodion .accrodion {
  position: relative;
  transition: all 0.4s ease;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.faq-accrodion .accrodion::before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0px;
  right: 0px;
  border-radius: 0px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  background-image: linear-gradient(90deg,
      var(--thm-primary) 0%,
      var(--thm-base) 100%);
  transition: -webkit-transform 0.4s ease;
  border-radius: 5px;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.faq-accrodion .accrodion+.accrodion {
  margin-top: 30px;
}

.faq-accrodion .accrodion.active {
  box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}

.faq-accrodion .accrodion.active .accrodion-inner {
  margin: 0 1px;
  background-color: transparent;
  border-color: #fff;
}

.faq-accrodion .accrodion.active::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.faq-accrodion .accrodion.active .accrodion-title {
  padding-bottom: 0;
}

.faq-accrodion .accrodion.active .accrodion-title::before {
  opacity: 0;
}

.faq-accrodion .accrodion.active .accrodion-title::after {
  opacity: 1;
  color: #fff;
}

.faq-accrodion .accrodion .accrodion-inner {
  background-color: #fff;
  padding: 18px 0;
  border-style: solid;
  border-width: 1px;
  border-color: #f2f4f8;
  border-radius: 0px;
  position: relative;
  transition: all 0.4s ease;
  overflow: hidden;
}

.faq-accrodion .accrodion .accrodion-title {
  padding: 0px 38px;
  cursor: pointer;
  position: relative;
  transition: all 0.4s ease;
}

@media (max-width: 767px) {
  .faq-accrodion .accrodion .accrodion-title {
    padding: 0 38px 0 20px;
  }
}

.faq-accrodion .accrodion .accrodion-title::before {
  content: "\F107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 14px;
  color: #7f13ab;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
  transition: all 500ms ease;
  height: 40px;
  width: 40px;
  background: #eef4fe;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .faq-accrodion .accrodion .accrodion-title::before {
    right: 5px;
  }
}

@media (max-width: 767px) {
  .faq-accrodion .accrodion .accrodion-title::before {
    right: 20px;
  }
}

.faq-accrodion .accrodion .accrodion-title::after {
  content: "\F106";
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  font-size: 14px;
  color: #fff;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  transition: all 500ms ease;
  background: #7f13ab;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .faq-accrodion .accrodion .accrodion-title::after {
    right: 5px;
  }
}

@media (max-width: 767px) {
  .faq-accrodion .accrodion .accrodion-title::after {
    right: 20px;
  }
}

.faq-accrodion .accrodion .accrodion-title h4 {
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
}

.faq-accrodion .accrodion .accrodion-content .inner {
  padding: 0 38px;
  padding-right: 35px;
  padding-top: 22px;
  padding-bottom: 0px;
}

@media (max-width: 767px) {
  .faq-accrodion .accrodion .accrodion-content .inner {
    padding: 0 38px 0 20px;
  }
}

.faq-accrodion .accrodion .accrodion-content p {
  margin: 0;
  color: #545454;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
}

.appie-faq-8-area {
  background: #eef1f6;
  position: relative;
}

.appie-faq-8-area .faq-accordion .accrodion .accrodion-inner .accrodion-title::before {
  background: #fcecef;
  color: #db0f30;
}

.appie-faq-8-area .faq-accordion .accrodion .accrodion-inner .accrodion-title::after {
  background: #db0f30;
  color: #fff;
}

.appie-faq-8-area .faq-play-box {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  /* background-image: url(../images/faq-play-bg.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-faq-8-area .faq-play-box {
    display: none;
  }
}

@media (max-width: 767px) {
  .appie-faq-8-area .faq-play-box {
    display: none;
  }
}

.appie-faq-8-area .faq-play-box .play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.appie-faq-8-area .faq-play-box .play-btn a {
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  background: #fff;
  border-radius: 50%;
  color: #7f13ab;
  position: relative;
}

.appie-faq-8-area .faq-play-box .play-btn a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-animation: linear infinite popup 1.5s;
          animation: linear infinite popup 1.5s;
}

.appie-faq-8-area .faq-play-box .faq-play-counter {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
}

.appie-faq-8-area .faq-play-box .faq-play-counter .box-1 {
  background: rgba(42, 109, 245, 0.9);
  padding: 50px 60px;
}

.appie-faq-8-area .faq-play-box .faq-play-counter .box-1 .title {
  font-size: 30px;
  color: #fff;
}

.appie-faq-8-area .faq-play-box .faq-play-counter .box-1 span {
  font-size: 14px;
  color: #d1dbfb;
}

.appie-faq-8-area .faq-play-box .faq-play-counter .box-1.box-2 {
  background: rgba(14, 17, 51, 0.9);
}

/*===========================
    10.APPIE BLOG css 
===========================*/
.appie-blog-item .thumb {
  overflow: hidden;
  border-radius: 6px;
}

.appie-blog-item .thumb img {
  transition: all 0.3s ease-out 0s;
  width: 100%;
}

.appie-blog-item .content {
  padding-top: 20px;
}

.appie-blog-item .content .blog-meta ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.appie-blog-item .content .blog-meta ul li {
  display: inline-block;
  font-size: 14px;
  padding-right: 22px;
  position: relative;
}

.appie-blog-item .content .blog-meta ul li a {
  font-weight: 400;
  color: #545454;
}

.appie-blog-item .content .blog-meta ul li:nth-child(1)::before {
  position: absolute;
  content: "";
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 5px;
  width: 5px;
  background: #7f13ab;
  border-radius: 50%;
}

.appie-blog-item .content .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin-top: 2px;
}

.appie-blog-item .content .title a {
  color: #000000;
}

.appie-blog-item .content .title a:hover {
  color: #7f13ab;
}

.appie-blog-item .content>a {
  font-size: 15px;
  font-weight: 700;
  color: #000000;
  margin-top: 14px;
}

.appie-blog-item .content>a i {
  padding-left: 6px;
}

.appie-blog-item .content>a:hover {
  color: #7f13ab;
}

.appie-blog-item:hover .thumb img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.appie-blog-3-area .blog-btn a {
  background: transparent;
  color: #000000;
  border: 2px solid #e7eaef;
}

.appie-blog-3-area .blog-btn a:hover {
  background: #ff6b58;
  border-color: #ff6b58;
  color: #fff;
}

.appie-blog-3-area.appie-blog-6-area .blog-btn a:hover {
  background: #9b2cfa;
  border-color: #9b2cfa;
}

.appie-blog-3-area.appie-blog-8-area {
  background: #eef1f6;
  position: relative;
  z-index: 10;
}

.appie-blog-3-area.appie-blog-8-area .appie-blog-8-shape-1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.appie-blog-3-area.appie-blog-8-area .appie-blog-8-shape-2 {
  position: absolute;
  right: 0;
  bottom: 340px;
  z-index: -1;
}

.appie-blog-3-area.appie-blog-8-area .blog-btn a {
  border-color: #db0f30;
  color: #db0f30;
}

.appie-blog-3-area.appie-blog-8-area .blog-btn a:hover {
  background: #db0f30;
  color: #fff;
}

.appie-blog-item-3 {
  box-shadow: 0px 15px 50px 0px rgba(14, 17, 51, 0.1);
  border-radius: 10px;
  padding: 35px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-blog-item-3 {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .appie-blog-item-3 {
    padding: 15px;
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-blog-item-3 {
    display: flex;
  }
}

.appie-blog-item-3 .thumb {
  max-width: 120px;
  min-width: 120px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 30px;
}

@media (max-width: 767px) {
  .appie-blog-item-3 .thumb {
    max-width: 100%;
    min-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-blog-item-3 .thumb {
    max-width: 120px;
    min-width: 120px;
  }
}

.appie-blog-item-3 .thumb img {
  width: 100%;
  transition: all linear 0.3s;
}

.appie-blog-item-3 .content {
  padding-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-blog-item-3 .content {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .appie-blog-item-3 .content {
    padding-right: 0;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-blog-item-3 .content {
    padding-right: 50px;
    margin-top: 0;
  }
}

.appie-blog-item-3 .content .title {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-blog-item-3 .content .title {
    font-size: 18px;
    line-height: 28px;
  }
}

.appie-blog-item-3 .content .title a {
  color: #000000;
}

.appie-blog-item-3 .content .title a:hover {
  color: #ff6b58;
}

.appie-blog-item-3 .content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.appie-blog-item-3 .content ul li {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #545454;
  margin-right: 22px;
}

.appie-blog-item-3 .content ul li i {
  padding-right: 6px;
}

.appie-blog-item-3 .content ul li a {
  color: #545454;
  font-size: 14px;
  font-weight: 400;
}

.appie-blog-item-3:hover .thumb img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.appie-blog-item-3.appie-blog-item-6 .content .title a:hover {
  color: #9b2cfa;
}

.appie-blog-item-3.appie-blog-item-8 {
  background: #fff;
}

.appie-blog-item-3.appie-blog-item-8 .content .title a:hover {
  color: #db0f30;
}

/*--------------------------------------------------------
/ 7. Blog Page
/---------------------------------------------------------*/
.blogpage-section {
  position: relative;
  padding: 100px 0 60px;
}

@media (max-width: 767px) {
  .blogpage-section {
    padding: 60px 0 40px;
  }
}

.bisylms-pagination {
  position: relative;
  z-index: 3;
  margin: 20px 0 30px;
  text-align: center;
}

.bisylms-pagination a,
.bisylms-pagination span {
  font-size: 14px;
  color: #7f13ab;
  font-weight: 600;
  line-height: 40px;
  background: rgba(88, 56, 252, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
  padding: 0 0;
  margin: 0 5px 0 0;
  transition: all 0.4s ease;
}

.bisylms-pagination a:hover,
.bisylms-pagination .current {
  color: #fff;
  background: #7f13ab;
}

.bisylms-pagination a.next,
.bisylms-pagination a.prev {
  width: auto;
  padding: 0 15px;
  text-transform: capitalize;
}

.bisylms-pagination a.next i {
  font-size: 18px;
  position: relative;
  top: 3px;
}

.bisylms-pagination a.prev i {
  font-size: 18px;
  position: relative;
  top: 4px;
  margin-right: 9px;
}

.bisylms-pagination .pagination {
  position: relative;
}

.bisylms-pagination .pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #7f13ab;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.bisylms-pagination .pagination .page-item:first-child .page-link,
.bisylms-pagination .pagination .page-item:last-child .page-link {
  border-radius: 0;
}

.bisylms-pagination .pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #7f13ab;
  border-color: #7f13ab;
}

/*--- Sidebar ---*/
.blog-sidebar {
  position: relative;
  margin-left: 30px;
}

@media (max-width: 767px) {
  .blog-sidebar {
    margin-left: 0px;
  }
}

.blog-sidebar .widget {
  position: relative;
  border: 1px solid #ebecf1;
  border-radius: 10px;
  margin: 0 0 30px;
  padding: 32px 30px 37px;
}

.blog-sidebar .widget .widget-title {
  color: #2c234d;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  border-bottom: 1px solid #cfd2de;
  display: inline-block;
  position: relative;
  padding-bottom: 15px;
  margin: 0 0 17px;
}

.blog-sidebar .widget.widget-search {
  padding: 0;
  border: none;
  border-radius: 0;
  background: transparent;
}

.blog-sidebar .widget.widget-search .widget-title {
  display: none;
}

.search-form {
  position: relative;
}

.search-form input[type="search"] {
  width: 100%;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  border-radius: 3px;
  font-weight: 700;
  border: none;
  background: #f1f0f5;
  color: #8f8d98;
  outline: none;
  padding: 0px 40px 0px 25px;
}

.search-form input[type="search"]::-moz-placeholder {
  color: #8f8d98;
  opacity: 1;
}

.search-form input[type="search"]::-ms-input-placeholder {
  color: #8f8d98;
  opacity: 1;
}

.search-form input[type="search"]::-webkit-input-placeholder {
  color: #8f8d98;
  opacity: 1;
}

.search-form button {
  font-size: 16px;
  line-height: 52px;
  color: #2c234d;
  background: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 17px;
  top: 0;
  z-index: 2;
  transition: all 0.4s ease;
}

.search-form button:hover {
  color: #7f13ab;
}

.blog-sidebar .widget ul {
  margin: 0;
  padding: 0;
}

.blog-sidebar .widget ul li {
  list-style: none;
  display: block;
  font-size: 14px;
  line-height: 40px;
  font-weight: 600;
  color: #5d5a67;
  position: relative;
  padding-left: 25px;
}

.blog-sidebar .widget ul li:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "\F105";
  font-family: "Font Awesome 5 Pro";
  font-size: 20px;
  line-height: 40px;
  font-weight: 300;
  color: #b2b0bc;
}

.blog-sidebar .widget ul li a {
  color: inherit;
  display: inline-block;
}

.blog-sidebar .widget ul li a:hover {
  color: #7f13ab;
}

.blog-sidebar .widget ul li span {
  color: #b2b0bc;
  margin-left: 9px;
}

.popular-post {
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0 25px;
}

.popular-post:last-child {
  margin-bottom: 0;
}

.popular-post img {
  width: 70px;
  height: 70px;
  border-radius: 3px;
}

.popular-post .textBox {
  margin-left: 15px;
}

.popular-post h5 {
  font-size: 15px;
  line-height: 20px;
  color: #2c234d;
  margin: 0 0 12px;
  font-weight: 700;
}

.popular-post h5 a {
  color: inherit;
}

.popular-post h5 a:hover {
  color: #7f13ab;
}

.popular-post span {
  display: block;
  font-size: 13px;
  line-height: 0.8;
  color: #7e7c87;
  font-weight: 600;
}

.tags {
  position: relative;
}

.tags a {
  display: inline-block;
  height: 34px;
  background: #f8f8f8;
  border-radius: 3px;
  text-align: center;
  font-size: 13px;
  color: #797781;
  line-height: 34px;
  padding: 0 15px;
  font-weight: 600;
  margin: 10px 6px 0 0;
}

.tags a:hover {
  background: #7f13ab;
  color: #fff;
}

/*--------------------------------------------------------
/ 8. Single Blog Page
/---------------------------------------------------------*/
.single-p-banner {
  padding-top: 158px;
  background-position: top center;
}

.post-cate {
  position: relative;
  z-index: 2;
  display: inline-block;
  color: #fff;
  line-height: 0.8;
  text-transform: uppercase;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.post-cate:hover {
  color: #fff;
}

.single-p-banner .banner-title {
  line-height: 60px;
  margin-bottom: 16px;
}

.single-p-banner .bp-meta {
  position: relative;
  z-index: 2;
  margin: 0;
  padding: 0;
}

.single-p-banner .bp-meta a i,
.single-p-banner .bp-meta a {
  color: #fff;
}

.single-post-area {
  position: relative;
}

.single-post-area p {
  line-height: 30px;
  margin: 0 0 30px;
}

.post-thumb {
  position: relative;
  margin-bottom: 40px;
}

.post-thumb img {
  width: auto;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.article-title {
  font-size: 30px;
  line-height: 34px;
  color: #2c234d;
  margin: 0 0 15px;
}

.sub-title {
  font-size: 22px;
  line-height: 26px;
  color: #2c234d;
  margin: 0 0 15px;
}

blockquote {
  position: relative;
  margin: 41px 0;
  padding: 67px 50px 40px;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: -4px 0px 0px 0px #5838fc;
}

blockquote:before {
  position: absolute;
  left: 50px;
  top: 68px;
  content: ",,";
  font-family: "Open Sans", sans-serif;
  font-size: 100px;
  line-height: 25px;
  color: #7f13ab;
  letter-spacing: -5px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

blockquote:after {
  position: absolute;
  right: 44px;
  bottom: 122px;
  content: ",,";
  font-family: "Open Sans", sans-serif;
  font-size: 200px;
  line-height: 25px;
  color: #f0f0f0;
  letter-spacing: -10px;
}

blockquote p {
  font-size: 20px;
  line-height: 32px;
  color: #2c234d;
  font-weight: 600;
  margin: 0 0 18px;
}

blockquote cite {
  display: block;
  font-size: 14px;
  line-height: 0.8;
  color: #797781;
  font-style: normal;
}

.single-post-area blockquote p {
  line-height: 32px;
  margin: 0 0 18px;
}

.post-tags {
  position: relative;
  display: flex;
  justify-content: flex-start;
}

.post-tags h5 {
  font-size: 14px;
  color: #2c234d;
  font-weight: 600;
  margin: 7px 20px 0 0;
}

.post-tags a {
  display: inline-block;
  height: 30px;
  background: #f8f8f8;
  border-radius: 3px;
  text-align: center;
  font-size: 13px;
  color: #797781;
  line-height: 30px;
  padding: 0 18px;
  font-weight: 600;
  margin: 0 10px 0 0;
}

.post-tags a:hover {
  background: #7f13ab;
  color: #fff;
}

.post-share {
  position: relative;
  border-top: 1px solid #ebecf2;
  padding: 30px 0 0;
  margin: 20px 0 0;
  display: flex;
  justify-content: flex-start;
}

.post-share h5 {
  font-size: 14px;
  color: #2c234d;
  font-weight: 600;
  margin: 13px 20px 0 0;
}

.post-share a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #446bb9;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  font-size: 14px;
  margin-right: 6px;
}

.post-share a.fac {
  background: #446bb9;
}

.post-share a.twi {
  background: #00aced;
}

.post-share a.goo {
  background: #ea4335;
}

.post-share a:hover {
  box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
}

.post-admin {
  position: relative;
  margin: 84px 0;
  padding: 44px 40px 55px 150px;
  background: transparent;
  border-radius: 10px;
  box-shadow: -4px 0px 0px 0px #5838fc;
}

.post-admin img {
  position: absolute;
  left: 40px;
  top: 40px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.post-admin a {
  display: inline-block;
  font-size: 18px;
  line-height: 22px;
  color: #2c234d;
  font-weight: 700;
  margin: 0 0 7px;
}

.post-admin span {
  display: block;
  font-size: 14px;
  color: #797781;
  font-weight: 400;
  line-height: 0.8;
  margin-bottom: 27px;
}

.post-admin p {
  font-size: 16px;
  line-height: 26px;
  color: #5d5a67;
  margin: 0;
}

.comment-area {
  position: relative;
  margin: 80px 0 66px;
}

.comment-area h3 {
  font-size: 20px;
  line-height: 0.8;
  color: #2c234d;
  margin: 0 0 30px;
}

.comment-list {
  margin: 0;
  padding: 0;
}

.comment-list li {
  list-style: none;
  display: block;
  margin: 0 0 0;
}

.comment-list .children {
  margin: 0 0 0;
  padding-left: 100px;
}

.single-comment {
  position: relative;
  padding-left: 100px;
  padding-top: 7px;
  padding-bottom: 30px;
}

.c-border {
  border-bottom: 1px solid #ebecf2;
  margin-top: 24px;
}

.single-comment img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.children .single-comment img {
  width: 50px;
  height: 50px;
}

.children .single-comment {
  padding-left: 80px;
}

.single-comment h5 {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 7px;
}

.single-comment h5 a {
  color: inherit;
}

.single-comment h5 a:hover {
  color: #7f13ab;
}

.single-comment span {
  display: block;
  font-size: 13px;
  line-height: 0.8;
  font-weight: 600;
  color: #797781;
  margin: 0 0 24px;
}

.comment {
  position: relative;
  margin: 0 0 14px;
}

.comment p {
  font-size: 14px;
  line-height: 24px;
  color: #686875;
  margin: 0;
}

.single-comment .comment-reply-link {
  display: inline-block;
  font-size: 12px;
  line-height: 0.8;
  color: #7f13ab;
  font-weight: 600;
}

.single-comment .comment-reply-link i {
  font-size: 16px;
  margin-right: 11px;
  font-weight: 600;
  position: relative;
  top: 2px;
}

.single-comment .comment-reply-link:hover {
  color: #2c234d;
}

.comment-form-area {
  position: relative;
  margin-bottom: 40px;
}

.comment-form-area h3 {
  color: #2c234d;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 9px;
}

.comment-form-area p {
  font-size: 14px;
  line-height: 24px;
  color: #686875;
  margin: 0 0 43px;
}

.comment-form {
  position: relative;
}

.comment-form input[type="text"],
.comment-form input[type="number"],
.comment-form input[type="url"],
.comment-form textarea,
.comment-form input[type="email"] {
  width: 100%;
  height: 56px;
  font-size: 14px;
  line-height: 56px;
  font-weight: 400;
  border-radius: 3px;
  border: none;
  background: #f5f5f7;
  color: #7e7c87;
  outline: none;
  border: 1px solid transparent;
  padding: 0 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.comment-form textarea {
  height: 180px;
  resize: none;
  line-height: 30px;
  padding-top: 14px;
}

.comment-form textarea::-moz-placeholder,
.comment-form input[type="text"]::-moz-placeholder,
.comment-form input[type="url"]::-moz-placeholder,
.comment-form input[type="number"]::-moz-placeholder,
.comment-form input[type="email"]::-moz-placeholder {
  color: #7e7c87;
  opacity: 1;
}

.comment-form textarea::-ms-input-placeholder,
.comment-form input[type="text"]::-ms-input-placeholder,
.comment-form input[type="url"]::-ms-input-placeholder,
.comment-form input[type="number"]::-ms-input-placeholder,
.comment-form input[type="email"]::-ms-input-placeholder {
  color: #7e7c87;
  opacity: 1;
}

.comment-form textarea::-webkit-input-placeholder,
.comment-form input[type="text"]::-webkit-input-placeholder,
.comment-form input[type="url"]::-webkit-input-placeholder,
.comment-form input[type="number"]::-webkit-input-placeholder,
.comment-form input[type="email"]::-webkit-input-placeholder {
  color: #7e7c87;
  opacity: 1;
}

.comment-form textarea:focus,
.comment-form input[type="url"]:focus,
.comment-form input[type="text"]:focus,
.comment-form input[type="number"]:focus,
.comment-form input[type="email"]:focus {
  background: transparent;
  border-color: #ece9f9;
  box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
}

.comment-form button,
.comment-form input[type="submit"] {
  height: 50px;
  border-radius: 3px;
  background: #7f13ab;
  color: #fff;
  text-align: center;
  border: none;
  outline: none;
  padding: 0 36px;
  font-size: 13px;
  line-height: 50px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.comment-form button:hover,
.contact-form input[type="submit"]:hover {
  box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
}

.save-comment {
  position: relative;
  margin: 5px 0 25px;
}

.save-comment input[type="checkbox"] {
  display: none;
}

.save-comment label {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  padding-left: 31px;
  position: relative;
  cursor: pointer;
  color: #686875;
  margin: 0 0 0;
}

.save-comment label:before {
  background: transparent;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  border-radius: 3px;
  top: 3px;
  width: 16px;
  border: 2px solid #ababb1;
  transition: all 0.3s ease;
}

.save-comment input:checked+label::after {
  background: transparent;
  bottom: 0;
  font-family: "ElegantIcons";
  content: "N";
  color: #7f13ab;
  font-size: 10px;
  left: 2px;
  position: absolute;
  top: -1px;
  transition: all 0.3s ease;
}

.post-item-1 {
  position: relative;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  transition: all 0.4s ease;
  box-shadow: 0px 2px 4px 0px rgba(21, 14, 56, 0.08);
}

.post-item-1 img {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
  height: 210px;
  object-fit: cover;
}

.post-item-1 a {
  display: block;
}

.b-post-details {
  position: relative;
  /* padding: 30px 35px; */
  padding: 15px;
}

.bp-meta {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 15px;
}

.bp-meta a {
  display: inline-block;
  font-size: 14px;
  line-height: 0.8;
  color: #545454;
  font-weight: 600;
  margin-right: 30px;
}

.bp-meta a i {
  color: #7f13ab;
  margin-right: 9px;
  position: relative;
  top: 1px;
}

.bp-meta a:last-child {
  margin-right: 0;
}

.bp-meta a:hover {
  color: #7f13ab;
}

.b-post-details h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.b-post-details h3 a {
  color: inherit;
}

.b-post-details h3 a:hover {
  color: #7f13ab;
}

.post-item-1:hover {
  box-shadow: 0px 30px 50px 0px rgba(11, 2, 55, 0.1);
}

.b-post-details a.read-more {
  font-size: 14px;
  font-weight: 700;
  color: #545454;
}

.b-post-details a.read-more i {
  padding-left: 8px;
}

.b-post-details a.read-more:hover {
  color: #7f13ab;
}

/*===========================
    10.APPIE PROJECT css 
===========================*/
.appie-project-area .appie-project-box {
  /* background-image: url(../images/project-bg.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 70px;
  padding-top: 75px;
  padding-bottom: 85px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .appie-project-area .appie-project-box {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-project-area .appie-project-box {
    padding-left: 70px;
  }
}

.appie-project-area .appie-project-box .appie-project-thumb {
  position: absolute;
  right: 70px;
  bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-project-area .appie-project-box .appie-project-thumb {
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-project-area .appie-project-box .appie-project-thumb {
    display: none;
  }
}

@media (max-width: 767px) {
  .appie-project-area .appie-project-box .appie-project-thumb {
    display: none;
  }
}

.appie-project-content {
  padding-right: 50px;
}

.appie-project-content .title {
  color: #fff;
  font-size: 50px;
  line-height: 60px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-project-content .title {
    font-size: 36px;
    line-height: 46px;
  }
}

@media (max-width: 767px) {
  .appie-project-content .title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-project-content .title {
    font-size: 36px;
    line-height: 46px;
  }
}

.appie-project-content p {
  color: #fff;
  opacity: 0.8;
}

.appie-project-content .input-box {
  display: flex;
}

@media (max-width: 767px) {
  .appie-project-content .input-box {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-project-content .input-box {
    display: flex;
  }
}

.appie-project-content .input-box input {
  width: 100%;
  height: 50px;
  padding-left: 30px;
  border-radius: 6px;
  border: 0;
}

.appie-project-content .input-box button {
  margin-left: 10px;
  padding: 0 30px;
  border-radius: 6px;
  padding: 0 25px;
  background: #000000;
  border: 0;
  color: #fff;
  line-height: 50px;
}

@media (max-width: 767px) {
  .appie-project-content .input-box button {
    margin-top: 20px;
    margin-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-project-content .input-box button {
    margin-top: 0px;
    margin-left: 10px;
  }
}

.appie-project-3-area.appie-project-5-area {
  background: #eef1f6;
}

.appie-project-3-box {
  /* background-image: url(../images/project-bg-2.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 55px 70px;
  box-shadow: 0px 40px 50px 0px rgba(138, 16, 0, 0.3);
  margin-bottom: -110px;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

@media (max-width: 767px) {
  .appie-project-3-box {
    text-align: center;
  }
}

.appie-project-3-box .title {
  color: #fff;
  font-size: 46px;
  line-height: 56px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-project-3-box .title {
    font-size: 36px;
    line-height: 46px;
  }
}

@media (max-width: 767px) {
  .appie-project-3-box .title {
    font-size: 35px;
    line-height: 46px;
    margin-bottom: 30px;
  }
}

.appie-project-3-box a {
  background: #fff;
  color: #000000;
  border-color: #fff;
  line-height: 55px;
  padding: 0 40px;
}

/*===========================
    10.APPIE FOOTER css 
===========================*/
.appie-footer-area {
  background: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.appie-footer-area .hLine hr {
  margin-top: 30px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid #383838;
}

.appie-footer-area.appie-footer-3-area {
  padding-top: 180px;
}

.appie-footer-area.appie-footer-3-area.appie-footer-5-area {
  background: #fff;
}

/* .appie-footer-area.appie-footer-about-area {
  padding-top: 230px;
} */

.appie-footer-area.appie-footer-about-area.appie-footer-8-area {
  background: #fff;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-about-widget .social ul li a {
  border: 2px solid #e6e7ea;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-about-widget .social ul li a:hover {
  background: #db0f30;
  border-color: #db0f30;
  color: #fff;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-navigation ul li a:hover {
  color: #db0f30;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-widget-info ul li a i {
  color: #db0f30;
}

.footer-about-widget {
  margin-top: 30px;
}

.footer-about-widget .logo {
  width: 270px;
}

@media (max-width: 767px) {
  .footer-about-widget {
    text-align: center;
  }

  .footer-about-widget .logo {
    margin: 0px 50px;
    width: auto;
  }
}

.footer-about-widget .title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: #878787;
}

.footer-about-widget .apps-download-btn ul {
  padding: 0;
  list-style-type: none;
}

.footer-about-widget .apps-download-btn ul li {
  display: block;
}

.footer-about-widget .apps-download-btn ul li a {
  background: transparent;
  color: #ffffff;
  font-size: 14px;
  line-height: 40px;
  padding: 0 23px;
  border-radius: 6px;
  border: 1px solid #878787;
  margin-bottom: 10px;
  display: block;
}

.footer-about-widget .apps-download-btn ul li a i {
  padding-right: 10px;
}

.footer-about-widget .apps-download-btn ul li a:hover {
  /* background: #ffffff;
  color: #000000; */
  border-color: #ffffff;
  text-decoration: underline;
}

.footer-about-widget .social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer-about-widget .social ul li {
  display: inline-block;
}

.footer-about-widget .social ul li a {
  height: 30px;
  width: 30px;
  text-align: center;
  background: #fff;
  color: #000000;
  border-radius: 50px;
  line-height: 30px;
  margin-right: 15px;
  font-size: 15px;
}

.footer-about-widget .social ul li a:hover {
  background: #7f13ab;
  color: #fff;
}

.footer-about-widget.footer-about-widget-2 ul li a:hover {
  background: #ff3e66;
}

.footer-about-widget.footer-about-widget-3 ul li a:hover {
  background: #ff6b58;
}

.footer-about-widget.footer-about-widget-6 ul li a:hover {
  background: #9b2cfa;
}

.footer-about-widget.footer-about-widget-5 ul li a:hover {
  background: #801f82;
}

.footer-navigation {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .footer-navigation {
    text-align: left;
  }
}

.footer-navigation .title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: #878787;
}

.footer-navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer-navigation ul li a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.footer-navigation ul li a:hover {
  color: #7f13ab;
}

.footer-navigation.footer-navigation-2 ul li a:hover {
  color: #ff3e66;
}

.footer-navigation.footer-navigation-3 ul li a:hover {
  color: #ff6b58;
}

.footer-navigation.footer-navigation-6 ul li a:hover {
  color: #9b2cfa;
}

.footer-navigation.footer-navigation-5 ul li a:hover {
  color: #801f82;
}

.footer-widget-info {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .footer-widget-info {
    text-align: center;
  }
}

.footer-widget-info .title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: #878787;
}

.footer-widget-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer-widget-info ul li a {
  color: #545454;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}

.footer-widget-info ul li a i {
  position: absolute;
  left: 0;
  top: 0;
  color: #7f13ab;
}

.footer-copyright {
  padding-top: 20px;
  margin-top: 30px;
  border-top: 1px solid #383838;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-copyright {
    display: block !important;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .footer-copyright {
    display: block !important;
    text-align: center;
  }

  .footer-copyright .copyright-text {
    margin-bottom: 10px;
  }
}

.footer-copyright .copyright-text p {
  color: #ffffff;
  font-size: 14px;
}

.footer-copyright .copyright-text a {
  color: #ffffff;
  font-size: 14px;
  margin: 0px 8px;
}

.footer-copyright .copyright-text a:hover {
  color: #7f13ab;
}

.footer-copyright .developed-text p {
  color: #ffffff;
  font-size: 14px;
}

.footer-copyright .developed-text img {
  width: 45px;
  margin-left: 6px;
}

.appie-error-area {
  height: 100vh;
  display: flex;
  align-items: center;
}

.appie-error-content a.main-btn {
  border-radius: 0px;
}

@media (max-width: 767px) {
  .appie-error-content {
    text-align: center;
  }

  .appie-error-content a.main-btn {
    margin-top: 25px;
  }
}

.appie-signup-area.appie-signup-8-area {
  background: #eef1f6;
}

.appie-signup-area.appie-signup-8-area .appie-signup-box form .input-box button {
  background: #db0f30;
}

.appie-signup-box {
  /* background-image: url(../images/signup-bg.jpg); */
  padding: 63px 100px 60px;
  border-radius: 10px;
  box-shadow: 0px 40px 50px 0px rgba(0, 24, 72, 0.3);
  overflow: hidden;
  position: relative;
  margin-bottom: -160px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-signup-box {
    padding: 30px 50px;
  }
}

@media (max-width: 767px) {
  .appie-signup-box {
    padding: 30px;
  }
}

.appie-signup-box .thumb {
  position: absolute;
  right: 70px;
  bottom: 30px;
  width: 160px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-signup-box .thumb {
    display: none;
  }
}

@media (max-width: 767px) {
  .appie-signup-box .thumb {
    display: none;
  }
}

.appie-signup-box span {
  color: #fff;
  font-weight: 700;
}

.appie-signup-box .title {
  font-size: 46px;
  color: #fff;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .appie-signup-box .title {
    font-size: 30px;
  }
}

.appie-signup-box form {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-signup-box form {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .appie-signup-box form {
    flex-direction: column;
  }
}

.appie-signup-box form .input-box {
  margin-right: 10px;
  margin-top: 10px;
}

.appie-signup-box form .input-box input {
  width: 300px;
  background: #fff;
  line-height: 55px;
  border-radius: 6px;
  padding-left: 30px;
  border: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-signup-box form .input-box input {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .appie-signup-box form .input-box input {
    width: 100%;
  }
}

.appie-signup-box form .input-box button {
  background: #7f13ab;
  line-height: 55px;
  border: 0;
  color: #fff;
  border-radius: 6px;
  padding: 0 45px;
  font-weight: 700;
}

.appie_checkbox_common {
  margin-top: 15px;
}

.appie_checkbox_common label {
  color: #fff;
  font-size: 14px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 400;
}

.appie_checkbox_common label a {
  text-decoration: underline;
  color: #b3b6bc;
}

.appie_checkbox_common input[type="checkbox"] {
  display: none;
}

.appie_checkbox_common input[type="checkbox"]+label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -1px 15px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
}

.appie_checkbox_common input[type="checkbox"]+label span:before {
  color: #fff;
  content: "\F00C";
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  position: absolute;
  text-align: center;
  left: 3px;
  top: -1px;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: 0.2s;
}

.appie_checkbox_common input[type="checkbox"]:checked+label span {
  background: #2079ff;
  border-color: #2079ff;
}

.appie_checkbox_common input[type="checkbox"]:checked+label span:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.appie_checkbox_common input[type="checkbox"]+label span,
.appie_checkbox_common input[type="checkbox"]:checked+label span {
  transition: background-color 0.2s linear;
}

.appie_checkbox_common.checkbox_style2 input[type="checkbox"]+label span {
  border-radius: 3px;
}

/*===========================
    11.APPIE ABOUT css 
===========================*/
.appie-about-box {
  background: #f0f1f7;
  padding: 70px;
  overflow: hidden;
  position: relative;
}

@media (max-width: 767px) {
  .appie-about-box {
    padding: 30px;
    text-align: center;
  }
}

.appie-about-box .about-thumb {
  position: absolute;
  left: 0;
  top: -65px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-about-box .about-thumb {
    position: static;
  }
}

@media (max-width: 767px) {
  .appie-about-box .about-thumb {
    display: none;
  }
}

.appie-about-content {
  padding-right: 50px;
}

@media (max-width: 767px) {
  .appie-about-content {
    padding-right: 0;
  }
}

.appie-about-content span {
  font-size: 14px;
  font-weight: 700;
  color: #ff3e66;
}

.appie-about-content .title {
  font-size: 44px;
  line-height: 54px;
  margin-top: 8px;
  margin-bottom: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-about-content .title {
    font-size: 32px;
    line-height: 42px;
  }
}

@media (max-width: 767px) {
  .appie-about-content .title {
    font-size: 30px;
    line-height: 40px;
  }
}

.appie-about-service {
  position: relative;
  padding-left: 40px;
}

@media (max-width: 767px) {
  .appie-about-service {
    text-align: left;
  }
}

.appie-about-service .icon {
  position: absolute;
  left: 0;
  top: 5px;
}

.appie-about-service .icon i {
  height: 26px;
  width: 26px;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
  color: #fff;
  background: #31c369;
}

.appie-about-service .title {
  font-size: 20px;
  font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-about-service .title {
    font-size: 16px;
  }
}

.appie-about-service p {
  font-size: 15px;
  margin-top: 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-about-service p {
    font-size: 14px;
  }
}

.appie-about-3-area .appie-traffic-title {
  padding-right: 100px;
}

@media (max-width: 767px) {
  .appie-about-3-area .appie-traffic-title {
    text-align: center;
    padding-right: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-about-3-area .appie-traffic-title {
    text-align: left;
    padding-right: 50px;
  }
}

.appie-about-3-area .appie-traffic-title .title {
  margin-bottom: 15px;
}

.appie-about-3-area .appie-traffic-service {
  padding-left: 0;
  padding-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-about-3-area .appie-traffic-service {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .appie-about-3-area .appie-traffic-service {
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-about-3-area .appie-traffic-service {
    text-align: left;
    padding-right: 50px;
    padding-left: 0;
  }
}

.appie-about-3-area .appie-traffic-service .icon {
  position: static;
  background-image: linear-gradient(120deg, #7f13ab 0%, #84bcfa 100%);
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50%;
  line-height: 50px;
  box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
  margin-bottom: 23px;
}

.appie-about-3-area .appie-traffic-service.item-2 .icon {
  background-image: linear-gradient(120deg, #31c369 0%, #a0eac2 100%);
}

.appie-about-3-area .appie-traffic-service.item-3 .icon {
  background-image: linear-gradient(120deg, #9b2cfa 0%, #d8aaff 100%);
}

.appie-about-3-area .appie-traffic-service.item-4 .icon {
  background-image: linear-gradient(120deg, #f8452d 0%, #f7b8af 100%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-about-thumb-3 {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .appie-about-thumb-3 {
    margin-bottom: 40px;
  }
}

.appie-about-thumb-3 img {
  width: 100%;
}

.appie-about-top-title-area {
  padding-top: 227px;
}

.appie-about-top-title {
  padding-right: 65px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-about-top-title {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .appie-about-top-title {
    padding-right: 0;
  }
}

.appie-about-top-title .title {
  font-size: 60px;
  position: relative;
  z-index: 10;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-about-top-title .title {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-about-top-title .title {
    font-size: 44px;
  }
}

@media (max-width: 767px) {
  .appie-about-top-title .title {
    font-size: 32px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-about-top-title .title {
    font-size: 40px;
  }
}

.appie-about-top-title .title::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 8px;
  width: 100%;
  height: 20px;
  background: #ff6e85;
  z-index: -1;
}

.appie-about-top-title p {
  font-size: 20px;
  color: #000000;
  padding-top: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-about-top-title p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-about-top-title p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .appie-about-top-title p {
    font-size: 16px;
  }
}

.appie-about-page-area {
  padding-top: 462px;
  padding-bottom: 100px;
  /* background-image: url(../images/about-page-bg.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 345px;
}

@media (max-width: 767px) {
  .appie-about-page-area {
    margin-top: 80px;
    padding-top: 80px;
  }
}

.appie-about-page-content {
  position: relative;
}

.appie-about-page-content .title {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}

.appie-about-page-content p {
  color: #fff;
  padding-top: 15px;
  opacity: 0.7;
}

.appie-about-page-content a {
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  line-height: 40px;
  padding: 0 30px;
  font-size: 14px;
  font-weight: 700;
  margin-top: 43px;
}

.appie-about-page-content a:hover {
  background: #fff;
  color: #7f13ab;
}

.appie-about-page-content::before {
  position: absolute;
  content: "";
  left: 0;
  top: -710px;
  height: 645px;
  width: 80vw;
  /* background-image: url(../images/about-page-thumb.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-about-page-content::before {
    width: 94vw;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-about-page-content::before {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .appie-about-page-content::before {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-about-page-content::before {
    width: 100%;
    height: 400px;
    display: block;
  }
}

.appie-how-it-work-content>.title {
  font-size: 44px;
}

.appie-how-it-work-content p {
  font-size: 18px;
  padding-top: 9px;
}

.appie-how-it-work-content .how-it-work-box {
  position: relative;
  padding-left: 70px;
  margin-top: 45px;
}

.appie-how-it-work-content .how-it-work-box span {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background: #eef4ff;
  color: #7f13ab;
  font-size: 22px;
  font-weight: 700;
}

.appie-how-it-work-content .how-it-work-box .title {
  font-size: 18px;
  font-weight: 700;
}

.appie-how-it-work-content .how-it-work-box:hover span {
  background: #7f13ab;
  box-shadow: 0px 14px 20px 0px rgba(0, 43, 130, 0.3);
  color: #fff;
}

.appie-about-8-area {
  /* background-image: url(../images/background-bg.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.appie-about-8-box {
  padding: 50px 50px 145px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
  position: relative;
  overflow: hidden;
  z-index: 10;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-about-8-box {
    padding: 50px 25px 145px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-about-8-box {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .appie-about-8-box {
    margin-bottom: 30px;
    padding: 50px 20px 145px;
  }
}

.appie-about-8-box .thumb {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.appie-about-8-box .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 14px;
}

@media (max-width: 767px) {
  .appie-about-8-box .title {
    font-size: 24px;
    line-height: 34px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-about-8-box .title {
    font-size: 30px;
    line-height: 40px;
  }
}

.appie-about-8-box a {
  border: 2px solid #db0f30;
  color: #db0f30;
  background: #fff;
  margin-top: 30px;
}

.appie-about-8-box a:hover {
  color: #fff;
  background: #db0f30;
  border-color: #db0f30;
}

/*===========================
    12.APPIE COUNTER css 
===========================*/
.appie-counter-area {
  padding: 100px 0px;
  background: #f7f8fd;
}

.appie-single-counter {
  position: relative;
  padding: 30px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #ecebff;
  transition: all 0.3s ease-in-out;

}

.appie-single-counter:hover {
  background: #0b1125;
  border-color: #0b1125;
}

.appie-single-counter .icon {
  position: relative;
  color: #0b1125;
  margin-bottom: 30px;
}

.appie-single-counter .icon i {
  position: relative;
}

.appie-single-counter .icon::before {
  position: absolute;
  content: "";
  top: -15px;
  left: -5px;
  height: 60px;
  width: 60px;
  background: #f7f8fd;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;

}

.appie-single-counter h3 {
  font-size: 16px;
  font-weight: 600;
}

.appie-single-counter h3 span {
  background: #fff8db;
  margin-top: 5px;
}

.appie-single-counter:hover .icon,
.appie-single-counter:hover h3 {
  color: #ffffff;
}

.appie-single-counter:hover h3 span {
  background: #1b2445;
}

.appie-single-counter:hover .icon::before {
  background: #1b2445;
  left: 5px;
}

@media (max-width: 767px) {
  .appie-counter-area {
    padding: 60px 0px;
  }

  .appie-single-counter {
    margin-top: 15px;
  }
}

.appie-single-counter .counter-content {
  background: #fff;
  text-align: center;
  border-radius: 6px;
  box-shadow: 0px 30px 40px 0px rgba(0, 41, 57, 0.1);
  padding-top: 37px;
  padding-bottom: 40px;
}

.appie-single-counter .counter-content i {
  font-size: 50px;
  color: #73d9ff;
  text-shadow: 3px 3px #e4f6fe;
}

.appie-single-counter .counter-content .title {
  font-size: 40px;
  font-weight: 400;
  margin-top: 15px;
}

.appie-single-counter .counter-content .title span {
  font-weight: 700;
}

.appie-single-counter .counter-content p {
  font-size: 14px;
  font-weight: 700;
}

.appie-single-counter.item-2 {
  background-image: linear-gradient(140deg, #fdead3 0%, #fff7ee 100%);
}

.appie-single-counter.item-2 .counter-content {
  box-shadow: 0px 30px 40px 0px rgba(56, 31, 0, 0.1);
}

.appie-single-counter.item-2 .counter-content i {
  color: #ffc276;
  text-shadow: 3px 3px #fef1e2;
}

.appie-single-counter.item-3 {
  background-image: linear-gradient(140deg, #dafedb 0%, #f0fcf0 100%);
}

.appie-single-counter.item-3 .counter-content {
  box-shadow: 0px 30px 40px 0px rgba(0, 74, 1, 0.1);
}

.appie-single-counter.item-3 .counter-content i {
  color: #84ff84;
  text-shadow: 3px 3px #e6fde7;
}

.appie-single-counter.item-4 {
  background-image: linear-gradient(140deg, #fedaf4 0%, #fceff7 100%);
}

.appie-single-counter.item-4 .counter-content {
  box-shadow: 0px 30px 40px 0px rgba(74, 0, 54, 0.1);
}

.appie-single-counter.item-4 .counter-content i {
  color: #ff76d6;
  text-shadow: 3px 3px #fde6f6;
}

/*===========================
    13.APPIE VIDEO PLAYER css 
===========================*/
.appie-video-player-slider .slick-arrow {
  position: absolute;
  right: 0;
  bottom: -90px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border: 2px solid #e3e5e8;
  color: #000000;
  border-radius: 50%;
  z-index: 9;
  cursor: pointer;
}

.appie-video-player-slider .slick-arrow:hover {
  background: #ff3e66;
  color: #fff;
  border-color: #ff3e66;
}

.appie-video-player-slider .slick-arrow.prev {
  right: 60px;
}

.appie-video-player-slider .slick-arrow.next {
  right: 0;
}

.appie-video-player-slider .item img {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-video-player-item {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .appie-video-player-item {
    margin-bottom: 50px;
  }
}

.appie-video-player-item .thumb {
  position: relative;
  overflow: hidden;
}

.appie-video-player-item .thumb img {
  width: 100%;
}

.appie-video-player-item .thumb .video-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.appie-video-player-item .thumb .video-popup a {
  height: 90px;
  width: 90px;
  background: #fff;
  text-align: center;
  line-height: 90px;
  color: #ff3e66;
  border-radius: 50%;
  font-size: 20px;
  position: relative;
}

.appie-video-player-item .thumb .video-popup a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-animation: linear infinite popup 1.5s;
          animation: linear infinite popup 1.5s;
}

.appie-video-player-item .content {
  padding-left: 100px;
  padding-top: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-video-player-item .content {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-video-player-item .content {
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  .appie-video-player-item .content {
    padding-left: 0px;
  }
}

.appie-video-player-item .content .title {
  font-size: 30px;
  margin-bottom: 12px;
}

@media (max-width: 767px) {
  .appie-video-player-item .content .title {
    font-size: 26px;
  }
}

.appie-video-player-item .content a {
  border-radius: 30px;
  border: 2px solid #e7eaef;
  background: #fff;
  color: #000000;
  margin-top: 33px;
}

.appie-video-player-item .content a:hover {
  background: #ff3e66;
  border-color: #fff;
  color: #fff;
}

@-webkit-keyframes popup {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }

  25% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 0.8;
  }

  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0.6;
  }

  75% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0;
  }
}

@keyframes popup {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }

  25% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 0.8;
  }

  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0.6;
  }

  75% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0;
  }
}

.appie-video-player-6-area .appie-video-player-item .content a:hover {
  background: #9b2cfa;
  border-color: #9b2cfa;
}

.appie-video-player-6-area .appie-video-player-slider .slick-arrow:hover {
  background: #9b2cfa;
  border-color: #9b2cfa;
}

/*===========================
    14.APPIE DOWNLOAD css 
===========================*/
.appie-download-area {
  padding: 20px 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

@media only screen and (max-width: 1700px) {
  .appie-download-area {
    background-position: 25%;
  }
}

@media (max-width: 767px) {
  .appie-download-area {
    padding: 40px 0px;
    background-position: center;
  }
}

.appie-download-content>span {
  font-size: 20px;
  font-weight: 700;
}

.appie-download-content>p {
  font-size: 18px;
}

.appie-download-content .title {
  font-size: 44px;
  line-height: 54px;
  margin-top: 5px;
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .appie-download-content .title {
    font-size: 30px;
    line-height: 40px;
  }
}

.appie-download-content .textBox {
  margin: 40px 0px 30px;
}

.appie-download-content .textBox ul {
  -webkit-column-count: 2;
          column-count: 2;
}


@media (max-width: 767px) {
  .appie-download-content .textBox ul {
    -webkit-column-count: 1;
            column-count: 1;
  }
}

.appie-download-content .textBox ul li {
  background: #ffffff;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 50px;
  box-shadow: 0px 0px 10px #dee8f5;
}

.appie-download-content .textBox ul li i {
  background: #000000;
  color: #ffffff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
}

.appie-download-content .textBox ul li span {
  font-weight: 600;
  font-size: 13px;
}

.appie-download-content .btnBox {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .appie-download-content .btnBox {
    display: block;
  }
}

.appie-download-content .btnBox a {
  display: flex;
  align-items: center;
  color: #fff;
  background: #000000;
  border: 2px solid #000000;
  border-radius: 6px;
  padding: 10px 40px 10px 25px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .appie-download-content .btnBox a {
    margin-right: 2px;
    margin-left: 2px;
    padding: 10px;
    margin-bottom: 0px;
  }
}

.appie-download-content .btnBox a i {
  font-size: 36px;
  padding-right: 15px;
}

.appie-download-content .btnBox a span {
  font-size: 12px;
  font-weight: 700;
}

.appie-download-content .btnBox a span span {
  display: block;
  font-size: 24px;
  line-height: 24px;
}

.appie-download-content .btnBox a:hover {
  background: #fff;
  color: #000000;
}

.appie-sponser-area {
  position: relative;
  z-index: 10;
}

.appie-sponser-area .sponser-shape {
  position: absolute;
  left: 50%;
  top: 60%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -1;
}

@media (max-width: 767px) {
  .appie-sponser-box {
    flex-wrap: wrap;
  }
}

.appie-sponser-box .sponser-item {
  background: #fff;
  height: 100px;
  width: 100px;
  text-align: center;
  line-height: 100px;
  border-radius: 6px;
  box-shadow: 0px 40px 60px 0px rgba(14, 17, 51, 0.14);
  margin: 30px 16px 0;
}

.appie-sponser-box.item-2 .sponser-item {
  margin: 30px 28px 0;
}

@media (max-width: 767px) {
  .appie-sponser-box.item-2 .sponser-item {
    margin: 30px 16px 0;
  }
}

.appie-download-3-box {
  border-radius: 20px;
  background: #ebf2fc;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-download-3-box {
    margin: 30px 0 0;
  }
}

@media (max-width: 767px) {
  .appie-download-3-box {
    margin: 30px 0 0;
  }
}

.appie-download-3-box .content {
  padding: 50px;
}

@media (max-width: 767px) {
  .appie-download-3-box .content {
    padding: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-download-3-box .content {
    padding: 50px;
  }
}

.appie-download-3-box .content .title {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 8px;
}

.appie-download-3-box .content a.main-btn {
  background: #fff;
  color: #000000;
  border-color: #fff;
  margin-top: 33px;
}

.appie-download-3-box .content a.main-btn:hover {
  background: #ff6b58;
  border-color: #ff6b58;
  color: #fff;
}

.appie-download-3-box .content a.main-btn i {
  padding-right: 6px;
}

.appie-download-3-box .content a.main-btn.main-btn-2 {
  background: #ff6b58;
  border-color: #ff6b58;
  color: #fff;
}

.appie-download-3-box .content a.main-btn.main-btn-2:hover {
  background: #fff;
  color: #000000;
  border-color: #fff;
}

.appie-download-3-box.appie-download-6-box a.main-btn:hover {
  background: #9b2cfa;
  border-color: #9b2cfa;
}

.appie-download-3-box.appie-download-6-box a.main-btn.main-btn-2 {
  background: #9b2cfa;
  border-color: #9b2cfa;
}

.appie-download-3-box.appie-download-5-box a.main-btn:hover {
  background: #801f82;
  border-color: #801f82;
}

.appie-download-3-box.appie-download-5-box a.main-btn.main-btn-2 {
  background: #801f82;
  border-color: #801f82;
}

/*===========================
    15.APPIE FUN FACT css 
===========================*/
.appie-fun-fact-box {
  /* background-image: url(../images/fun-fact-bg.jpg); */
  padding: 90px 70px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .appie-fun-fact-box {
    padding: 30px;
  }
}

.appie-fun-fact-box .appie-fun-fact-content>.title {
  font-size: 44px;
  line-height: 54px;
  color: #fff;
  padding-right: 80px;
  padding-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-fun-fact-box .appie-fun-fact-content>.title {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-content>.title {
    font-size: 28px;
    line-height: 38px;
    padding-right: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-content>.title {
    font-size: 36px;
    line-height: 46px;
    padding-right: 0;
  }
}

.appie-fun-fact-box .appie-fun-fact-content p {
  color: #fff;
  opacity: 0.8;
}

.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item {
  margin-top: 36px;
}

@media (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item {
    text-align: left;
  }
}

.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item .title {
  font-size: 30px;
  color: #fff;
}

.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item span {
  font-size: 14px;
  color: #a4a4b9;
}

.appie-fun-fact-box .appie-fun-fact-play {
  position: relative;
  z-index: 10;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-fun-fact-box .appie-fun-fact-play {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-play {
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-play {
    text-align: left;
  }
}

.appie-fun-fact-box .appie-fun-fact-play img {
  position: absolute;
  right: -40px;
  top: 0;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-fun-fact-box .appie-fun-fact-play img {
    position: static;
  }
}

@media (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-play img {
    display: none;
  }
}

.appie-fun-fact-box .appie-fun-fact-play a {
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  background: #fff;
  border-radius: 50%;
  color: #ff6b58;
  position: relative;
  z-index: 5;
}

.appie-fun-fact-box .appie-fun-fact-play a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-animation: popup 1.5s linear infinite;
          animation: popup 1.5s linear infinite;
}

/*===========================
    15.APPIE SHOWCASE css 
===========================*/
.appie-showcase-area {
  background: #eef1f6;
  padding-top: 90px;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.appie-showcase-area .showcase-shape-1 {
  position: absolute;
  left: 90px;
  top: 185px;
  z-index: -1;
  -webkit-animation: linear 25s animationFramesFive infinite;
          animation: linear 25s animationFramesFive infinite;
}

.appie-showcase-area .showcase-shape-2 {
  position: absolute;
  left: 170px;
  bottom: 95px;
  z-index: -1;
  -webkit-animation: linear 20s animationFramesOne infinite;
          animation: linear 20s animationFramesOne infinite;
}

.appie-showcase-area .showcase-shape-3 {
  position: absolute;
  top: 200px;
  right: 0;
  z-index: -1;
  -webkit-animation: linear 25s animationFramesThree infinite;
          animation: linear 25s animationFramesThree infinite;
}

.appie-showcase-area .showcase-shape-4 {
  position: absolute;
  bottom: 250px;
  right: 195px;
  z-index: -1;
  -webkit-animation: linear 25s animationFramesFour infinite;
          animation: linear 25s animationFramesFour infinite;
}

.appie-showcase-item {
  box-shadow: 0px 2px 3px 0px rgba(14, 17, 51, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease-out 0s;
  margin-top: 30px;
  margin-bottom: 50px;
}

.appie-showcase-item:hover {
  box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.appie-showcase-item a {
  display: block;
}

.appie-showcase-item img {
  width: 100%;
}

.appie-showcase-slider .slick-dots {
  position: absolute;
  left: 50%;
  bottom: -20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.appie-showcase-slider .slick-dots li {
  display: inline-block;
}

.appie-showcase-slider .slick-dots li button {
  font-size: 0;
  border: 0;
  background: #ccced9;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 3px;
}

.appie-showcase-slider .slick-dots li.slick-active button {
  background: #ff6b58;
}

.appie-showcase-slider h3.courseHead {
  position: absolute;
  top: -5px;
  left: 30px;
  font-size: 28px;
  z-index: 99;
}

@media (max-width: 767px) {
  .appie-showcase-slider h3.courseHead {
    top: -3px;
    font-size: 20px;
  }
}

.appie-showcase-slider .slick-prev {
  position: absolute;
  /* top: 0; */
  /* right: 40px; */
  /* left: auto; */
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0);
          transform: translate(0);
  z-index: 99;
}

.appie-showcase-slider .slick-next {
  position: absolute;
  /* top: 0; */
  /* right: 15px; */
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0);
          transform: translate(0);
  z-index: 99;
}

.appie-showcase-slider .slick-prev:before {
  content: "\F053";
  font-family: "font awesome 5 pro";
  font-size: 20px;
  line-height: 1;
  opacity: 0.7;
  color: #000000;
}

.appie-showcase-slider .slick-next:before {
  content: "\F054";
  font-family: "font awesome 5 pro";
  font-size: 20px;
  line-height: 1;
  opacity: 0.7;
  color: #000000;
}

/*===========================
    16.APPIE CONTACT css 
===========================*/
.contact-section {
  position: relative;
  padding: 70px 0;
}

@media (max-width: 767px) {
  .contact-section {
    padding: 40px 0;
  }
}

.contact--info-area {
  position: relative;
  margin-right: 40px;
}

.contact--info-area h3 {
  font-size: 30px;
  color: #2c234d;
  font-weight: 800;
  line-height: 0.8;
  margin-bottom: 16px;
}

.contact--info-area>p {
  color: #686875;
  line-height: 24px;
  border-bottom: 1px solid #e5e4ed;
  margin-bottom: 33px;
  padding-bottom: 25px;
}

.single-info {
  position: relative;
  margin-bottom: 37px;
}

.single-info h5 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #2c234d;
}

.single-info p {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #686875;
  margin: 0;
  padding-left: 32px;
}

.single-info p i {
  font-size: 18px;
  line-height: 30px;
  color: #7f13ab;
  position: absolute;
  left: 0;
  top: 0;
}

.ab-social h5 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #2c234d;
}

.bisylms-map {
  position: relative;
  line-height: 0.8;
  overflow: hidden;
}

.bisylms-map iframe {
  width: 100%;
  height: 480px;
  border: none;
}

.contact-form {
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 43px 50px 65px;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .contact-form {
    padding: 30px;
  }
}

.contact-form h4 {
  font-size: 24px;
  color: #2c234d;
  line-height: 30px;
  margin-bottom: 8px;
}

.contact-form p {
  font-size: 16px;
  color: #686875;
  line-height: 26px;
  position: relative;
  z-index: 3;
  margin: 0 0 41px;
}

.contact-form input[type="text"],
.contact-form input[type="number"],
.contact-form textarea,
.contact-form input[type="email"] {
  width: 100%;
  height: 56px;
  font-size: 14px;
  line-height: 56px;
  font-weight: 400;
  border-radius: 3px;
  border: none;
  background: #f5f5f7;
  color: #7e7c87;
  outline: none;
  border: 1px solid transparent;
  padding: 0 30px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.contact-form textarea {
  height: 220px;
  resize: none;
  line-height: 30px;
  padding-top: 14px;
}

.contact-form textarea::-moz-placeholder,
.contact-form input[type="text"]::-moz-placeholder,
.contact-form input[type="number"]::-moz-placeholder,
.contact-form input[type="email"]::-moz-placeholder {
  color: #7e7c87;
  opacity: 1;
}

.contact-form textarea::-ms-input-placeholder,
.contact-form input[type="text"]::-ms-input-placeholder,
.contact-form input[type="number"]::-ms-input-placeholder,
.contact-form input[type="email"]::-ms-input-placeholder {
  color: #7e7c87;
  opacity: 1;
}

.contact-form textarea::-webkit-input-placeholder,
.contact-form input[type="text"]::-webkit-input-placeholder,
.contact-form input[type="number"]::-webkit-input-placeholder,
.contact-form input[type="email"]::-webkit-input-placeholder {
  color: #7e7c87;
  opacity: 1;
}

.contact-form textarea:focus,
.contact-form input[type="text"]:focus,
.contact-form input[type="number"]:focus,
.contact-form input[type="email"]:focus {
  background: transparent;
  border-color: #ece9f9;
  box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
}

.contact-form input[type="submit"] {
  height: 50px;
  border-radius: 3px;
  background: #7f13ab;
  color: #fff;
  text-align: center;
  border: none;
  outline: none;
  padding: 0 36px;
  font-size: 13px;
  line-height: 50px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.contact-form input[type="submit"]:hover {
  box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
}

.condition-check {
  position: relative;
  margin: 10px 0 25px;
}

.condition-check input[type="checkbox"] {
  display: none;
}

.condition-check label {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  padding-left: 31px;
  position: relative;
  cursor: pointer;
  color: #686875;
  margin: 0 0 0;
}

.condition-check label a {
  color: #7f13ab;
}

.condition-check label a:hover {
  color: #686875;
}

.condition-check label:before {
  background: transparent;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  border-radius: 3px;
  top: 3px;
  width: 16px;
  border: 2px solid #ababb1;
  transition: all 0.3s ease;
}

.condition-check input:checked+label::after {
  background: transparent;
  bottom: 0;
  font-family: "Font Awesome 5 Pro";
  content: "\F00C";
  color: #7f13ab;
  font-size: 10px;
  left: 3px;
  position: absolute;
  top: -1px;
  transition: all 0.3s ease;
}

.ab-social {
  position: relative;
}

.ab-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #446bb9;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  font-size: 14px;
  margin-right: 10px;
}

.ab-social a.fac {
  background: #446bb9;
}

.ab-social a.twi {
  background: #00aced;
}

.ab-social a.you {
  background: #f73434;
}

.ab-social a.lin {
  background: #007bb6;
}

.ab-social a.inst {
  background: #bc2a8d;
}

.ab-social a:hover {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
}

/*===========================
    17.APPIE CONTACT css 
===========================*/
.appie-shop-grid-area .bisylms-pagination {
  margin-top: 50px;
  margin-bottom: 50px;
}

.appie-shop-grid-area .bisylms-pagination .current,
.appie-shop-grid-area .bisylms-pagination:hover {
  box-shadow: none;
  border-radius: 5px;
}

.appie-shop-grid-area .bisylms-pagination a {
  border: 2px solid #e7eaef;
  background: #fff;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
}

.appie-shop-grid-area .bisylms-pagination a i {
  font-size: 14px;
  padding-left: 6px;
}

.appie-shop-grid-area .bisylms-pagination a:hover {
  box-shadow: none;
  background: #7f13ab;
  border-color: #7f13ab;
  color: #fff;
}

.appie-shop-grid-area .bisylms-pagination span {
  border: 2px solid #e7eaef;
}

.appie-shop-sidebar .shop-price-filter {
  border-bottom: 1px solid #e7eaef;
  padding-bottom: 33px;
}

.appie-shop-sidebar .shop-price-filter .title {
  font-size: 16px;
  font-weight: 700;
}

.appie-shop-sidebar .shop-price-filter .price-range {
  width: 100%;
  height: 3px;
  background: #e7eaef;
  margin-top: 33px;
  margin-bottom: 24px;
  position: relative;
}

.appie-shop-sidebar .shop-price-filter .price-range::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 0;
  height: 100%;
  width: 60%;
  background: #7f13ab;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-shop-sidebar .shop-price-filter .price-range::before {
    width: 100%;
  }
}

.appie-shop-sidebar .shop-price-filter .price-range .box-1 {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  left: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #7f13ab;
  box-shadow: 0px 4px 10px 0px rgba(0, 14, 58, 0.2);
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-shop-sidebar .shop-price-filter .price-range .box-1 {
    left: 0;
  }
}

.appie-shop-sidebar .shop-price-filter .price-range .box-2 {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  right: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #7f13ab;
  box-shadow: 0px 4px 10px 0px rgba(0, 14, 58, 0.2);
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-shop-sidebar .shop-price-filter .price-range .box-2 {
    right: 0;
  }
}

.appie-shop-sidebar .shop-price-filter .main-filter-price span {
  font-size: 14px;
  color: #63636a;
}

.appie-shop-sidebar .shop-price-filter .main-filter-price a {
  font-size: 14px;
  color: #000000;
}

.appie-shop-sidebar .shop-category-widget {
  margin-top: 37px;
  border-bottom: 1px solid #e7eaef;
  padding-bottom: 33px;
}

.appie-shop-sidebar .shop-category-widget .title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 25px;
}

.appie-shop-sidebar .shop-category-widget ul li {
  margin-top: 10px;
}

.appie-shop-sidebar .shop-category-widget ul li a {
  font-size: 15px;
  color: #63636a;
}

.appie-shop-sidebar .shop-category-widget ul li a:hover {
  color: #7f13ab;
}

.appie-shop-sidebar .shop-best-product-widget {
  margin-top: 37px;
  border-bottom: 1px solid #e7eaef;
  padding-bottom: 40px;
}

.appie-shop-sidebar .shop-best-product-widget .title {
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
}

.appie-shop-sidebar .shop-best-product-widget .shop-product {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.appie-shop-sidebar .shop-best-product-widget .shop-product .thumb {
  max-width: 60px;
  margin-right: 20px;
}

.appie-shop-sidebar .shop-best-product-widget .shop-product .content a {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: #7f13ab;
  color: #000000;
}

.appie-shop-sidebar .shop-best-product-widget .shop-product .content span {
  font-size: 14px;
  font-weight: 400;
  color: #63636a;
}

.appie-shop-sidebar .shop-tags-bar {
  margin-top: 37px;
}

.appie-shop-sidebar .shop-tags-bar .title {
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
}

.appie-shop-sidebar .shop-tags-bar ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.appie-shop-sidebar .shop-tags-bar ul li {
  display: inline-block;
}

.appie-shop-sidebar .shop-tags-bar ul li a {
  border: 2px solid #e7eaef;
  line-height: 30px;
  padding: 0 13px;
  font-size: 13px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 10px;
  margin-right: 7px;
  border-radius: 3px;
}

.shop-grid-topbar {
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .shop-grid-topbar {
    display: block !important;
  }
}

.shop-grid-topbar>span {
  color: #63636a;
  font-size: 14px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .shop-grid-topbar>span {
    margin-bottom: 15px;
    display: block;
  }
}

.shop-grid-topbar>span span {
  color: #000000;
}

.shop-grid-topbar select {
  height: 30px;
  width: 170px;
  background: #eef1f6;
  border: 0;
  border-radius: 4px;
}

.single-shop-box {
  border: 1px solid #e7eaef;
  border-radius: 6px;
  padding: 20px;
  margin-top: 30px;
}

.single-shop-box .thumb {
  position: relative;
}

.single-shop-box .thumb img {
  transition: all linear 0.5s;
}

.single-shop-box .thumb .reborn {
  position: absolute;
  left: 0;
  top: 0;
}

.single-shop-box .thumb .reborn span {
  background: #7f13ab;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  line-height: 18px;
  padding: 0 12px;
  border-radius: 4px;
}

.single-shop-box .thumb .cart-list-icon {
  position: absolute;
  top: -20px;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all linear 0.5s;
}

.single-shop-box .thumb .cart-list-icon ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.single-shop-box .thumb .cart-list-icon ul li a {
  height: 36px;
  width: 36px;
  text-align: center;
  line-height: 36px;
  background: #eef1f6;
  color: #000000;
  margin-bottom: 6px;
  border-radius: 5px;
}

.single-shop-box .thumb .cart-list-icon ul li a:hover {
  background: #7f13ab;
  color: #fff;
}

.single-shop-box .content {
  padding-bottom: 10px;
}

.single-shop-box .content ul li {
  display: inline-block;
}

.single-shop-box .content ul li i {
  color: #ff9a2d;
  font-size: 12px;
}

.single-shop-box .content a {
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 5px;
}

.single-shop-box .content .pricing {
  display: flex;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}

.single-shop-box .content .pricing .regular-price {
  color: #63636a;
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 10px;
}

.single-shop-box:hover .thumb img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.single-shop-box:hover .thumb .cart-list-icon {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.shop-details-thumb .shop-details-thumb-slider-active .item {
  border-radius: 6px;
  overflow: hidden;
}

.shop-details-thumb .shop-details-thumb-slider-active .item img {
  width: 100%;
}

.shop-small-slider-active {
  max-width: 310px;
}

.shop-small-slider-active .item {
  border-radius: 5px;
  margin-right: 10px;
}

.shop-buttons .product-quantity {
  border: 2px solid #e7eaef;
  display: inline-block;
  border-radius: 6px;
}

.shop-buttons .product-quantity button {
  width: 40px;
  height: 45px;
  padding: 0;
  background-color: #fff;
  font-size: 24px;
  color: #838b97;
  border: 0;
  border-radius: 6px;
}

.shop-buttons .product-quantity button:hover,
.shop-buttons .product-quantity button:focus {
  color: #7f13ab;
}

@media (max-width: 767px) {
  .shop-buttons .product-quantity button {
    width: 40px;
    height: 40px;
  }
}

.shop-buttons .product-quantity input {
  width: 40px;
  height: 45px;
  border: 0;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 0 0;
  color: #000000;
}

@media (max-width: 767px) {
  .shop-buttons .product-quantity input {
    height: 40px;
  }
}

@media (max-width: 767px) {
  .shop-buttons a {
    margin-left: 0;
    margin-top: 15px;
    padding: 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .shop-product-details-content {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-product-details-content {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .shop-product-details-content {
    padding-left: 0;
  }
}

.shop-product-details-content>span {
  background: #eef1f6;
  line-height: 26px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  border-radius: 6px;
}

.shop-product-details-content .title {
  font-size: 40px;
  font-weight: 700;
  margin-top: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .shop-product-details-content .title {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-product-details-content .title {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .shop-product-details-content .title {
    font-size: 28px;
  }
}

.shop-product-details-content .pricing {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.shop-product-details-content .pricing .regular-price {
  font-size: 16px;
  color: #63636a;
  font-weight: 400;
  text-decoration: line-through;
}

.shop-product-details-content .review {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 17px;
}

.shop-product-details-content .review ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.shop-product-details-content .review ul li {
  display: inline-block;
}

.shop-product-details-content .review ul li i {
  color: #ff9a2d;
  font-size: 14px;
}

.shop-product-details-content .review span {
  font-size: 15px;
  color: #63636a;
  margin-left: 5px;
}

.shop-product-details-content p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}

.shop-product-details-content .details-info {
  border-top: 1px solid #e7eaef;
  margin-top: 35px;
  padding-top: 25px;
}

.shop-product-details-content .details-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.shop-product-details-content .details-info ul li {
  font-size: 15px;
  color: #63636a;
  padding-bottom: 3px;
}

.shop-product-details-content .details-info ul li span {
  font-weight: 700;
  color: #000000;
}

.shop-details-info-area {
  background: #eef1f6;
}

.shop-details-box .nav {
  border-bottom: 1px solid #d8dce3;
}

.shop-details-box .nav .nav-item .nav-link {
  margin: 0;
  padding: 0 10px;
  line-height: 45px;
  margin-right: 10px;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  border-radius: 0;
}

.shop-details-box .nav .nav-item .nav-link.active {
  background: #eef1f6;
  color: #7f13ab;
  border-bottom: 2px solid #7f13ab;
}

.shop-description p {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 24px;
}

.shop-description .title {
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
}

.review-rating-box .top-rating-result .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}

.review-rating-box .top-rating-result .rating-result-box {
  background: #fff;
  padding: 30px;
  margin-top: 16px;
  display: flex;
  border-radius: 6px;
}

@media (max-width: 767px) {
  .review-rating-box .top-rating-result .rating-result-box {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .review-rating-box .top-rating-result .rating-result-box {
    display: flex;
  }
}

.review-rating-box .top-rating-result .rating-result-box .thumb {
  max-width: 50px;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .review-rating-box .top-rating-result .rating-result-box .thumb {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .review-rating-box .top-rating-result .rating-result-box .thumb {
    margin-bottom: 0px;
  }
}

.review-rating-box .top-rating-result .rating-result-box .content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.review-rating-box .top-rating-result .rating-result-box .content ul li {
  display: inline-block;
}

.review-rating-box .top-rating-result .rating-result-box .content ul li i {
  font-size: 14px;
  color: #ff9a2d;
}

.review-rating-box .top-rating-result .rating-result-box .content .shop-meta {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

@media (max-width: 767px) {
  .review-rating-box .top-rating-result .rating-result-box .content .shop-meta {
    display: block;
  }
}

.review-rating-box .top-rating-result .rating-result-box .content .shop-meta .author-user-name a {
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  margin-right: 15px;
}

.review-rating-box .top-rating-result .rating-result-box .content .shop-meta .date {
  font-size: 14px;
  color: #63636a;
}

.review-rating-box .top-rating-result .rating-result-box .content p {
  font-size: 14px;
  line-height: 24px;
}

.review-box .review-title {
  margin-top: 75px;
  margin-bottom: 20px;
}

.review-box .review-title .title {
  font-size: 20px;
  font-weight: 700;
}

.review-box .review-title p {
  font-size: 14px;
  padding-top: 3px;
}

.review-box .add-review-star span {
  color: #000000;
  font-weight: 700;
  padding-bottom: 4px;
}

.review-box .add-review-star ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.review-box .add-review-star ul li {
  display: inline-block;
}

.review-box .add-review-star ul li i {
  color: #9c9ea4;
}

.review-box .add-review-star ul li:nth-child(1) i,
.review-box .add-review-star ul li:nth-child(2) i {
  color: #ff9a2d;
}

.rating-form-box .input-box {
  margin-top: 15px;
}

.rating-form-box .input-box label {
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}

.rating-form-box .input-box>input {
  width: 100%;
  height: 60px;
  padding-left: 25px;
  border: 2px solid #fff;
  background: #fff;
  border-radius: 6px;
}

.rating-form-box .input-box>input:focus {
  border-color: #7f13ab;
}

.rating-form-box .input-box textarea {
  width: 100%;
  height: 120px;
  padding-left: 25px;
  border: 2px solid #fff;
  background: #fff;
  border-radius: 6px;
  resize: none;
  padding-top: 10px;
}

.rating-form-box .input-box textarea:focus {
  border-color: #7f13ab;
}

.rating-form-box .input-box .condition-check label {
  font-size: 14px;
  color: #63636a;
  font-weight: 400;
}

.shop-related-product-slider-active .slick-dots {
  position: absolute;
  left: 50%;
  bottom: -70px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.shop-related-product-slider-active .slick-dots li {
  display: inline-block;
}

.shop-related-product-slider-active .slick-dots li button {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 5px;
  font-size: 0;
  border: 0;
}

.shop-related-product-slider-active .slick-dots li.slick-active button {
  background: #7f13ab;
}

/*===========================
    INNER PAGES css 
===========================*/

.course-page {
  padding: 50px 0;
  background: #000;
  position: relative;
  background-repeat: no-repeat;
  background-size: auto;
}

.course-page.categoryDetail {
  background: #091a6c;
}

.course-page .secHead h1 {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 10px;
}

.course-page .secHead .ratingBox {
  color: #b4690e;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.course-page .secHead .ratingBox b {
  color: #ffffff !important;
  margin-right: 5px;
  font-weight: 600;
}

.course-page .secHead p {
  color: #ffffff;
  font-size: 15px;
  margin-bottom: 20px;
}

.course-page .secHead .detail {
  margin-bottom: 5px;
  clear: 'both';
}

.course-page .secHead .detail span {
  color: #ffffff;
  font-size: 14px;
  margin-right: 8px;
  margin-bottom: 7px;
}

.course-page .secHead .detail span i {
  margin-right: 8px;
  font-size: 18px;
}

.course-page .secHead .detail anew {
  color: #ffffff;
  font-size: 15px;
  margin-right: 20px;
  cursor: pointer;
}

.course-page .secHead .detail a:hover {
  text-decoration: underline;
}

.course-page .secHead .btnBox .btnOne {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #476DD5;
  padding: 0 40px;
  font-size: 15px;
  font-weight: 700;
  line-height: 35px;
  border-radius: 50px;
  margin-right: 15px;
  transition: all 0.4s ease-out 0s;
}

.course-page .secHead .btnBox .btnOne:hover {
  background-color: transparent;
  color: #ffffff;
}

.course-page .secHead .btnBox .btnTwo {
  background-color: #1C3D9A;
  border: 1px solid #1C3D9A;
  color: #ffffff;
  padding: 0 40px;
  font-size: 15px;
  font-weight: 700;
  line-height: 35px;
  border-radius: 50px;
  margin-right: 15px;
  transition: all 0.4s ease-out 0s;
}

.course-page .secHead .btnBox .btnTwo:hover {
  background-color: transparent;
  border-color: #ffffff;
}

.course-page .secHead .btnBox .btnShare {
  color: #ffffff;
  font-size: 20px;
  margin-left: 15px;
}

.course-page.courseDetail {
  background: #476DD5;
  padding: 80px 0px 120px;
  margin-bottom: 60px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}

.course-page.courseDetail .secHead h1 {
  color: #ffffff;
  font-size: 70px;
  line-height: 75px;
  font-weight: 900;
  margin-bottom: 20px;
}

.course-page.courseDetail .secHead h2 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
}

.course-page.courseDetail .secHead .btnBox .btnTwo {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #979797;
  color: #ffffff;
  padding: 0 45px;
  font-size: 18px;
  font-weight: 700;
  line-height: 45px;
  border-radius: 0px;
  margin-right: 0px;
}

.course-page.courseDetail .secHead .btnBox .btnTwo:hover {
  background-color: rgba(0, 0, 0, 1);
}

.course-page.courseDetail .secHead .btnPlay {
  color: #ffffff;
  font-size: 80px;
  opacity: 0.4;
}

.course-page.courseDetail .secHead .btnPlay:hover {
  opacity: 1;
}

.course-page.courseDetail .secDatetime {
  display: flex;
  justify-content: center;
}

.course-page.courseDetail .innerBox {
  position: absolute;
  top: 75px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 100px;
}

.course-page.courseDetail .innerBox b {
  margin: 0px 30px;
  font-size: 18px;
}

.course-page.courseDetail .innerBox b span {
  color: #666666;
  font-weight: 400;
  margin-right: 10px;
}

.course-page.courseDetail .innerBox b a {
  color: #000000;
  text-decoration: underline;
}

.course-page.courseDetail .innerBox b a:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .course-page.courseDetail .secHead h1 {
    font-size: 40px;
    line-height: 45px;
  }

  .course-page.courseDetail .secHead h2 {
    font-size: 28px;
  }

  .course-page.courseDetail .innerBox {
    top: 55px;
    padding: 20px 10px;
    text-align: center;
  }

  .course-page.courseDetail .innerBox b {
    display: block;
  }
}

.courseDetailBlock {
  padding: 40px 0 60px;
}

.courseDetailBlock .courseBlock h3 {
  margin-bottom: 25px;
}

.courseDetailBlock .courseBlock h5 {
  margin-bottom: 15px;
}

.courseDetailBlock .courseBlock p {
  margin-bottom: 20px;
}

.courseDetailBlock .courseBlock .includeBox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.courseDetailBlock .courseBlock .includeBox div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 30px;
  margin-right: 15px;
  border-radius: 10px;
  width: 47%;
}

.courseDetailBlock .courseBlock .includeBox div:nth-child(2n+0) {
  margin-right: 0px;
  margin-left: 15px;
}

.courseDetailBlock .courseBlock .includeBox div img {
  margin-right: 20px;
  max-width: 70px;
}

.courseDetailBlock .courseBlock .requrBlock {
  margin-bottom: 30px;
}

.courseDetailBlock .courseBlock .requrBlock ul li {
  display: inline;
  margin-right: 20px;
  font-size: 14px;
}

.courseDetailBlock .courseBlock .requrBlock ul li i {
  margin-right: 6px;
}

.courseDetailBlock .courseBlock .detail {
  margin-bottom: 30px;
}

.courseDetailBlock .courseBlock .detail span {
  font-size: 18px;
  margin-right: 25px;
}

.courseDetailBlock .courseBlock .detail span i {
  margin-right: 8px;
}

.courseDetailBlock .courseBlock .whatLearn {
  position: relative;
  /* background: #f4f4f4; */
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid #d1d7dc;
}

.courseDetailBlock .courseBlock .whatLearn:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 80%;
  /* background: #f4f4f4; */
  z-index: -1;
}

.courseDetailBlock .courseBlock .whatLearn ul li {
  display: flex;
  margin-bottom: 15px;
}

.courseDetailBlock .courseBlock .whatLearn ul li i {
  font-size: 18px;
  line-height: 28px;
  margin-right: 10px;
}

.courseDetailBlock .courseBlock .col3Block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -80px;
  margin-bottom: 50px;
  padding: 0px 30px;
}

.courseDetailBlock .courseBlock .col3Block div {
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 60px 50px;
}

.courseDetailBlock .courseBlock .col3Block div i,
.courseDetailBlock .courseBlock div i.sj {
  color: gray;
  font-size: 32px;
  margin-bottom: 10px;
}

.courseDetailBlock .courseBlock .col3Block div h5 {
  margin-bottom: 10px;
}

.courseDetailBlock .courseBlock .col3Block div p {
  margin-bottom: 0px;
}

.courseDetailBlock .courseBlock .viewBlock {
  position: relative;
}

.courseDetailBlock .courseBlock .viewBlock .viewHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0C0C4E;
  padding: 15px 10px;
  padding: 5px 10px;
}

.courseDetailBlock .courseBlock .viewBlock .viewHead h5 {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0;
}

.courseDetailBlock .courseBlock .viewBlock .viewHead p {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 0;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner {
  background: #f7f7f7;
  padding: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .tabs-btn .nav a {
  border-radius: 0;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  background: transparent;
  padding: 0px 5px 0px 0px;
  margin-right: 30px;
  border-bottom: 2px solid transparent;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .tabs-btn .nav a.active {
  color: #7f13ab;
  border-bottom: 2px solid #7f13ab;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion {
  margin-top: 20px;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion {
  position: relative;
  transition: all 0.4s ease;
  overflow: hidden;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion+.accrodion {
  margin-top: auto;
  /* 20px */
}

.course_module_title {
  border-radius: 0px !important;
  border: 1px solid #dedede;
  border-bottom: 0px solid #505050;
  padding-right: 20px;
}

.videoPreview {
  color: #000000a3;
  padding-right: 10px;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion .accrodion-inner {
  position: relative;
  transition: all 0.4s ease;
  overflow: hidden;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion .accrodion-title,
.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodionshow .accrodion-title {
  cursor: pointer;
  position: relative;
  background: #d5d5d53d;
  font-weight: 600;
  padding: 7px 10px;
  border-radius: 0px;
  padding-right: 20px;
  transition: all 0.4s ease;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion.active .accrodion-title {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion .accrodion-title span {
  margin-right: 20px;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion .accrodion-title::before {
  content: "\F105";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  position: absolute;
  right: 25px;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion .accrodion-title::after {
  content: "\F107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  opacity: 0;
  position: absolute;
  right: 25px;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion.show .accrodion-title::before {
  opacity: 0;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion.show .accrodion-title::after {
  opacity: 1;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion-content .inner {
  background: #ffffff;
  padding: 20px 25px 20px 60px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion-content .inner ol {
  list-style-type: auto;
  margin-left: 20px;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion-content .inner ol li {
  /* padding: 20px 0px 20px 5px; */
  padding: 5px 0px;
  border-bottom: 1px solid #eeeeee;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion-content .inner ol li:last-child {
  border-bottom: 0;
}

.courseDetailBlock .courseBlock .viewBlock .viewInner .main-btn {
  font-size: 16px;
  font-weight: 500;
  padding: 0px 35px;
  line-height: 35px;
}

.courseDetailBlock .courseBlock hr {
  margin-top: 40px;
  margin-bottom: 40px;
  border: 0;
  border-top: 2px solid #dddddd;
}

.courseDetailBlock .courseBlock .instructorBox {
  position: relative;
  margin-bottom: 40px;
}

.courseDetailBlock .courseBlock .instructorBox .appie-features-content {
  margin: 0px;
  border: 0px;
  border-radius: 0;
  box-shadow: none;
}

.courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox {
  padding: 0px;
}

.courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox .instructorBox {
  margin-bottom: 20px;
}

.courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox .instructorBox img {
  width: 210px;
  margin-right: 25px;
  border-radius: 10px;
}

.courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox .instructorBox h6 {
  font-size: 18px;
  font-weight: 900;
}

.courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox .instructorBox .detail {
  margin-bottom: 10px;
}

.courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox .instructorBox .detail span {
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
  margin-bottom: 5px;
  background: transparent;
  padding: 0;
}

.courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox .instructorBox .btnBox .main-btn {
  color: #ffffff;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 30px;
  line-height: 30px;
}

.courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox .instructorBox .btnBox .main-btn:hover {
  color: #7f13ab;
}

.courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox .instructorBox .btnBox .main-btn.two {
  background-color: #1C3D9A;
  border: 1px solid #1C3D9A;
}

.courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox .instructorBox .btnBox .main-btn.two:hover {
  color: #1C3D9A;
  background-color: transparent;
}

.courseDetailBlock .courseBlock .awardBox {
  position: relative;
  display: flex;
  align-items: center;
  background: #FFF2F2;
  padding: 50px 50px 50px 0px;
}

.courseDetailBlock .courseBlock .awardBox:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 80%;
  background: #FFF2F2;
  z-index: -1;
}

.courseDetailBlock .courseBlock .awardBox ul li {
  display: flex;
  margin-bottom: 15px;
}

.courseDetailBlock .courseBlock .awardBox ul li:last-child {
  margin-bottom: 0px;
}

.courseDetailBlock .courseBlock .awardBox ul li i {
  color: #7f13ab;
  font-size: 18px;
  line-height: 28px;
  margin-right: 12px;
}

.courseDetailBlock .courseBlock .awardBox .imgBox {
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.courseDetailBlock .courseBlock .studentsBought {
  padding: 40px 0px;
}

.courseDetailBlock .courseBlock .studentsBought .listBox {
  display: flex;
}

.courseDetailBlock .courseBlock .studentsBought .listBox .appie-features-content {
  border: 0;
  margin: 0;
  margin-right: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

.courseDetailBlock .courseBlock .studentsBought .listBox .appie-features-content:last-child {
  margin-right: 0px;
}

.courseDetailBlock .courseBlock .studentsBought .listBox .appie-features-content .imgBox {
  border-radius: 0;
}

.courseDetailBlock .courseBlock .studentsBought .listBox .appie-features-content .contentBox .instructorBox {
  margin-bottom: 10px;
}

.courseDetailBlock .courseBlock .studentFeedback {
  margin-bottom: 40px;
}

.courseDetailBlock .courseBlock .studentFeedback .one {
  text-align: center;
}

.courseDetailBlock .courseBlock .studentFeedback .one h1 {
  font-size: 100px;
}

.courseDetailBlock .courseBlock .studentFeedback .one .ratingBox {
  color: #ffcc00;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.courseDetailBlock .courseBlock .studentFeedback .one .ratingBox i {
  margin: 0px 10px;
}

.courseDetailBlock .courseBlock .studentFeedback .two {
  text-align: center;
}

.courseDetailBlock .courseBlock .studentFeedback .two span {
  background: #f7f7f7;
  display: block;
  height: 20px;
  margin-top: 17px;
}

.courseDetailBlock .courseBlock .studentFeedback .three {
  text-align: center;
}

.courseDetailBlock .courseBlock .studentFeedback .three .ratingBox {
  color: #ffcc00;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
}

.courseDetailBlock .courseBlock .studentFeedback .three .ratingBox i {
  margin: 0px 6px;
}

.courseDetailBlock .courseBlock .studentFeedback .three .ratingBox b {
  font-weight: 500;
  color: #000000;
  margin-left: 6px;
}

.courseDetailBlock .courseBlock .comment-area {
  margin: 60px 0 40px;
}

.courseDetailBlock .courseSidebar .appie-features-content {
  margin: 0px 0px 40px 15px;
  border-radius: 0;
  border-color: #000000;
}

.courseDetailBlock .courseSidebar .appie-features-content .btnBox {
  padding: 30px;
  padding-bottom: 0;
  text-align: center;
}

.courseDetailBlock .courseSidebar .appie-features-content .btnBox .main-btn {
  display: block;
  margin-bottom: 10px;
  border-radius: 0;
}

.courseDetailBlock .courseSidebar .appie-features-content .btnBox .main-btn.buy {
  background-color: transparent;
  color: #000000;
  border-color: #000000;
}

.courseDetailBlock .courseSidebar .appie-features-content .btnBox .main-btn.buy:hover {
  background-color: #000000;
  color: #ffffff;
}

.courseDetailBlock .courseSidebar .appie-features-content .contentBox {
  padding: 20px 30px 30px 30px;
}

.courseDetailBlock .courseSidebar .appie-features-content .contentBox h6 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}

.courseDetailBlock .courseSidebar .appie-features-content .contentBox .twoBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.courseDetailBlock .courseSidebar .appie-features-content .contentBox .instructorBox a {
  font-size: 16px;
}

.courseDetailBlock .courseSidebar .appie-features-content .contentBox .instructorBox img {
  width: 36px;
  margin-right: 15px;
}

.courseDetailBlock .courseSidebar .appie-features-content .contentBox .courseDetails a {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
}

.courseDetailBlock .courseSidebar .appie-features-content .contentBox .courseDetails a:hover {
  text-decoration: none;
}

.courseDetailBlock .courseSidebar .appie-features-content .contentBox .shopBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.courseDetailBlock .courseSidebar .appie-features-content .contentBox .shopBox a.icon {
  color: #343a40;
  font-size: 20px;
}

.courseDetailBlock .courseSidebar .appie-features-content .contentBox .shopBox a.main-btn {
  line-height: 30px;
  width: 100%;
  margin: 0px 15px;
}

@media (max-width: 767px) {
  .courseDetailBlock {
    padding: 10px 0 10px;
  }

  .courseDetailBlock .courseBlock .includeBox div {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .courseDetailBlock .courseBlock .includeBox div:nth-child(2n+0) {
    margin-left: 0;
  }

  .courseDetailBlock .courseBlock .col3Block {
    display: block;
  }

  .courseDetailBlock .courseBlock .col3Block div {
    margin-bottom: 30px;
    padding: 30px 40px;
  }

  .courseDetailBlock .courseBlock .viewBlock .viewHead {
    flex-wrap: wrap;
  }

  /* .courseDetailBlock .courseBlock .viewBlock .viewHead h5 {
    margin-bottom: 10px;
  } */

  .courseDetailBlock .courseBlock .viewBlock .viewInner .filter-accrodion .accrodion-content .inner {
    padding: 10px 20px;
  }

  .courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox .instructorBox {
    flex-wrap: wrap;
  }

  .courseDetailBlock .courseBlock .instructorBox .appie-features-content .contentBox .instructorBox img {
    margin-bottom: 25px;
  }

  .courseDetailBlock .courseBlock .awardBox {
    flex-wrap: wrap;
    padding: 40px 15px 40px 0px;
  }

  .courseDetailBlock .courseBlock .awardBox .imgBox {
    margin-left: 0px;
    margin-top: 30px;
  }

  .courseDetailBlock .courseBlock .studentsBought .listBox {
    flex-wrap: wrap;
  }

  .courseDetailBlock .courseBlock .studentsBought .listBox .appie-features-content {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .courseDetailBlock .courseSidebar {
    margin-bottom: 60px;
  }

  .courseDetailBlock .courseSidebar .appie-features-content {
    margin: 0;
  }

}

.careerCounselingBlock {
  padding: 60px 0 40px;
}

.careerCounselingBlock h3 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 25px;
}

.careerCounselingBlock .appie-features-content {
  border: 0;
  margin: 0;
  margin-bottom: 40px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.careerCounselingBlock .appie-features-content .contentBox {
  padding: 30px;
}

.careerCounselingBlock .appie-features-content .contentBox h6 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 15px;
}

.careerCounselingBlock .appie-features-content .contentBox p {
  font-size: 17px;
  margin-bottom: 0px;
}

.careerCounselingBlock .blockTwo {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.careerCounselingBlock .blockTwo .imgBox {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin: 0px 30px 0px 10px;
  overflow-x: hidden;
  flex: 0 0 50%;
}

.careerCounselingBlock .blockTwo .contentBox h6 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 15px;
}

.careerCounselingBlock .blockTwo .contentBox p {
  font-size: 17px;
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .careerCounselingBlock {
    padding: 50px 0 20px;
  }

  .careerCounselingBlock .blockTwo {
    flex-wrap: wrap;
  }

  .careerCounselingBlock .blockTwo .imgBox {
    margin: 0px 0px 25px 0px;
    flex: 0 0 100%;
  }
}

.distinctiveBlock {
  padding: 50px 0px;
  border-top: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
}

.distinctiveBlock h3 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .distinctiveBlock {
    padding: 40px 0;
  }
}

.possibleBlock {
  padding: 60px 0px 40px;
}

.possibleBlock h3 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
}

.possibleBlock .innerBox {
  margin-bottom: 30px;
}

.possibleBlock .innerBox h6 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 10px;
}

.possibleBlock .innerBox p {
  font-size: 17px;
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .possibleBlock {
    padding: 40px 0 20px;
  }
}

.aboutBlock {
  padding: 70px 0 40px;
}

.aboutBlock .messageBox {
  margin-bottom: 40px;
  padding-right: 70px;
}

.aboutBlock .messageBox h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}

.aboutBlock .messageBox p {
  font-size: 16px;
  margin-bottom: 20px;
}

.aboutBlock .messageBox h5 {
  font-size: 20px;
  font-weight: 700;
}

.aboutBlock .imgBox {
  border-radius: 10px;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .aboutBlock {
    padding: 40px 0 50px;
  }

  .aboutBlock .messageBox {
    padding-right: 0px;
  }
}

.docstaBlock {
  padding: 0px 0 70px;
}

.docstaBlock .docBox h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 5px;
}

.docstaBlock .docBox p {
  margin-bottom: 30px;
}

.docstaBlock .textBox {
  padding-left: 20px;
}

.docstaBlock .textBox h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
}

.docstaBlock .textBox p {
  margin-bottom: 15px;
}

.docstaBlock .textBox ul li {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.docstaBlock .textBox ul li i {
  color: #7f13ab;
  font-size: 18px;
  line-height: 28px;
  margin-right: 12px;
}

@media (max-width: 767px) {
  .docstaBlock {
    padding: 0px 0 50px;
  }

  .docstaBlock .imgBox {
    margin-bottom: 30px;
  }

  .docstaBlock .textBox {
    padding-left: 0px;
  }

  .docstaBlock .textBox ul li {
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .docstaBlock .textBox ul li:last-child {
    margin-bottom: 0px;
  }
}

.awardsBlock {
  position: relative;
  overflow: hidden;
  z-index: 15;
  padding: 60px 0px;
  border-top: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
}

.awardsBlock .textBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.awardsBlock .textBox div {
  text-align: center;
  padding-right: 25px;
  border-right: 2px solid #f0f0f0;
}

.awardsBlock .textBox div:last-child {
  padding-right: 0px;
  border-right: 0;
}

.awardsBlock .textBox div b {
  font-size: 14px;
}

@media (max-width: 767px) {
  .awardsBlock {
    padding: 40px 0px;
  }

  .awardsBlock .textBox {
    flex-wrap: wrap;
    justify-content: center;
  }

  .awardsBlock .textBox div {
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    border-right: 0px;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .awardsBlock .textBox div:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-right: 20px;
  }
}

.galleryBlock {
  padding: 60px 0px 70px;
}

.galleryBlock .appie-section-title {
  padding-bottom: 50px;
}

.galleryBlock .styles_column__2r45X img,
.galleryBlock .styles_icon__1uWQb,
.galleryBlock .styles_thumbnail__19ffY {
  cursor: pointer;
}

@media (max-width: 767px) {
  .galleryBlock {
    padding: 40px 0 50px;
  }

  .galleryBlock .appie-section-title {
    padding-bottom: 30px;
  }
}

.faqBlock {
  padding: 60px 0px 70px;
}

.faqBlock .contentBox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 10px;
  margin-bottom: 20px;
}

.faqBlock .contentBox:hover {
  background: #f5f5f5;
}

.faqBlock .contentBox:last-child {
  margin-bottom: 0px;
}

.faqBlock .contentBox span {
  display: block;
  height: 75px;
  width: 75px;
  text-align: center;
  line-height: 75px;
  border-radius: 50%;
  background: #fbf0ff;
  color: #7f13ab;
  font-size: 30px;
  float: left;
  margin-right: 20px;
}

.faqBlock .contentBox .docsta_no {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  background: #fbf0ff;
  color: #7f13ab;
  font-size: 26px;
  float: left;
  margin-right: 10px;
}

.faqBlock .contentBox div {
  margin-left: 25px;
}

.faqBlock .contentBox:hover div p {
  line-height: 26px;
  letter-spacing: 0.5px;
}

.faqBlock .contentBox:hover div h5 {
  color: #7a3694;
}

.faqBlock .contentBox div h5 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 25px;
  letter-spacing: 0.7px;
}

.faqBlock .contentBox:hover span {
  background: #7f13ab;
  color: #fff;
}


@media (max-width: 767px) {
  .faqBlock {
    padding: 40px 0 50px;
  }
}

.infoBlock {
  padding: 70px 0;
  background: #f7f6fa;
}

.infoBlock .InformationPage {
  background: #ffffff;
  text-align: justify;
  padding: 30px;
}


.infoBlock .InformationPage h2,
.infoBlock .InformationPage h3 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

.infoBlock .InformationPage h5 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.infoBlock .InformationPage p {
  font-size: 16px;
  margin-bottom: 20px;
}

.infoBlock .InformationPage ul,
.infoBlock .InformationPage ol {
  margin-bottom: 15px;
}


@media (max-width: 767px) {
  .faqBlock {
    padding: 50px 0;
  }
}

.sticky-top {
  top: 180px;
  z-index: 9;
}

.styles_lightroom__1X2qE,
.styles_thumbpanel__1sa4E {
  position: fixed !important;
  z-index: 9999 !important;
}

div.CourseDescription:empty {
  display: none
}

.docsta_breadcumb {
  color: #e8b1ff;
  font-weight: 700;
}

.docsta_breadcumb:hover {
  color: #edc4ff;
}

.docsta-bottom-fixed {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  text-align: center;
  z-index: 999;
  padding: 10px;
}

.docsta_top_msg {
  background: #091a6c;
  text-align: center;
  padding: 10px;
  padding-right: 25px;
}

.clrb {
  clear: 'both',
}

.a {
  cursor: pointer;
  color: #7f13ab;
}

.showmoreWhatLearn {
  position: absolute;
  bottom: -30px;
  background: #fff;
  opacity: 0.8;
  left: 0px;
  right: 0px;
  padding: 10px;
}

.keywordSearchInput {
  position: absolute;
  background: #fff;
  left: 0px;
  right: 0px;
  max-height: 312px;
  overflow: auto;
  z-index: 999990;
  padding: 10px;
  border: 1px solid #7a3694;
}

.keywordSearchInput .single {
  padding: 5px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
}

.keywordSearchInput .details {
  cursor: unset;
}

.keywordSearchInput img {
  width: 75px;
  margin-right: 10px;
}

.keywordSearchInput .action {
  position: absolute;
  right: 10px;
  z-index: 999999;
}

.FeaturedCategoriesSlider img {
  width: 230px;
  height: auto;
  height: 170px;
  object-fit: contain;
}

/* @media (max-width: 767px) { } */
.FeaturedCategoriesSlider .slick-next {
  background: transparent;
}

.FeaturedCategoriesSlider .slick-next:before {
  color: #a476b6 !important;
  font-weight: bolder;
  opacity: 1;
  font-weight: 400;
}

.FeaturedCategoriesSlider:hover .slick-next:before {
  color: #000 !important;
}

.FeaturedCategoriesSlider .slick-prev {
  background: transparent;
}

.FeaturedCategoriesSlider .slick-prev:before {
  color: #a476b6 !important;
  font-weight: bolder;
  opacity: 1;
  font-weight: 400;
}

.FeaturedCategoriesSlider:hover .slick-prev:before {
  color: #000 !important;
}

.FeaturedCategoriesSlider .appie-features-content {
  padding-top: auto;
  margin: 0px 10px;
  border: 1px solid #dedede;
  border-radius: 10px;
}

.FeaturedCategoriesSlider .appie-features-content:hover {
  border: 1px solid #e9bdf9;
  cursor: pointer;
}

.FeaturedCategoriesSlider b {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 1.5em;
}

@media (max-width: 767px) {
  .FeaturedCategoriesSlider b {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    padding-left: 5px;
    padding-right: 5px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 3em;
  }
}

.NewCourseList .ry-prev,
.NewCourseList .ry-next {
  display: none !important;
  width: 0px !important;
  height: 0px !important;
}

@media (max-width: 767px) {
  .FeaturedCategoriesSlider .appie-features-content {
    margin: 0px 3px;
  }

  .mobile_footer_action {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 999999;
    background: #f5f5f5;
  }

  .mobile_footer_action br {
    display: none;
  }
}

.text-ry {
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.PriceDivCont small {
  display: inline-block !important;
}

.doctsa_instite_btn {
  border: 1px solid #7f13ab;
  background-color: #7f13ab;
  color: #fff;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  vertical-align: middle;
  transition: all .4s ease-out 0s;
}

.hideempty:empty {
  display: none !important;
}

.form-group {
  margin-bottom: 1rem;
}

.text-right {
  text-align: right !important;
}
.SearchFormTopCont {
  position: relative;
}
.SearchFormTopCont:hover {
  min-height: 32px;
}
.SearchFormTopCont:hover .SearchFormTopContExtra {
  display: flex;
}
.SearchFormTopContExtra:hover {
  display: flex;
}
.SearchFormTopCont:hover .SearchFormTopContOnly {
  display: none;
}
.SearchFormTopContExtra {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999999;
  background: #f5f5f5;
  border: 1px solid #bd9870;
}
.appie-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.appie-loader {
  opacity: 0;
}

.appie-loader.active {
  opacity: 100;
}

.appie-visible {
  transition: all 0.5s ease-in-out;
}

.appie-visible {
  opacity: 0;
}

.appie-visible.active {
  opacity: 100;
}

/* .appie-home-loader {
  transition: all 0.5s ease-in-out;
} */
.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20 px;
  overflow: hidden;
  transition: all linear 0.65s;
}

.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item .title span {
  font-size: 30px !important;
  color: #fff !important;
}

.home-four-project {
  background: #eef1f6;
}

.home-four-footer {
  background: #ffffff;
}

.slick-dots li button:before {
  content: "";
}

.appie-showcase-item {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px;
  }
}

.appie-services-2-area.appie-services-8-area {
  overflow: hidden;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .appie-services-8-area .service-thumb {
    margin-left: 0;
    margin-right: 0;
  }
}

.save-comment input:checked+label::after {
  font-family: "Font Awesome 5 Pro";
  content: "\F00C";
}

.appie-testimonial-area {
  overflow: hidden;
}

.testimonial-about-slider-active .testimonial-parent-item {
  display: flex !important;
  justify-content: center;
}

.testimonial-box {
  width: 770px !important;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item .thumb img {
  width: 70px;
  display: inline-block;
}

.testimonial-box-about-slider-small-active .item .thumb img {
  display: inline-block;
  width: 40px;
}

.testimonial-box-about-slider-small-active .item .thumb {
  align-items: center;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item {
  margin-top: -20px;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .testimonial-about-slider-active .testimonial-box {
    box-shadow: none;
    background: none;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1024px) {
  .testimonial-about-slider-active .slick-arrow.next {
    right: 15px;
  }

  .testimonial-about-slider-active .slick-arrow.prev {
    left: 15px;
  }
}

.navSection-sjain {
  background: #0b1125;
  padding: 5px !important;
}

.navSection-sjain ul li {
  padding: 10px;
  color: #ffffff;
}

.navSection-sjain .appie-header-main-menu ul>li>a {
  color: #ffffff;
  transition: 0.5s;
}

.navSection-sjain .appie-header-main-menu ul>li>a:hover {
  color: #f9c800;
}

.InformationPage ul {
  list-style-type: disc;
  padding-left: 50px;
}

@media only screen and (min-width: 771px) {
  .hideondesktop {
    display: none !important;
  }
}

@media only screen and (max-width: 770px) {
  .hideonmobile {
    display: none !important;
  }

  .blockonmobile {
    display: block;
  }
}

.Sample_Certificate .styles_row__2gmnz {
  -webkit-column-count: 1 !important;
          column-count: 1 !important;
}

.hide_empty_div:empty {
  display: none !important;
}

.a_class {
  cursor: pointer;
}

.docsta_color {
  color: #7f13ab !important;
}

.audidTips {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 15px 20px 0px;
  transition: all 0.3s ease-out 0s;
}

.cartCounter {
  position: absolute;
  color: white;
  top: -5px;
  right: -23px;
  display: block;
  background: #7f13ab;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 19px;
}

@media only screen and (max-width: 771px) {
  .cartCounter {
    width: 14px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    top: -1px;
    right: -5px;
  }
}

.HomeCourseCategorySlider .slick-slide {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.HomeCourseCategorySlider .slick-slide span {
  border-radius: 0;
  font-size: 15px;
  font-weight: 700;
  color: #000;
  background: #f0f0f0;
  padding: 10px 28px;
  margin-right: 15px;
  cursor: pointer;
}

.HomeCourseCategorySlider .slick-slide:hover span,
.HomeCourseCategorySlider .slick-slide span.active {
  background: #7f13ab;
  color: #fff;
}

.HomeCourseCategorySlider .slick-slide span.active {
  text-decoration: underline;
}

/* For the Audio Player */
.audioPlayTrackCont b {
  padding-top: 10px;
  display: block;
  padding-left: 10px;
  color: #7a3694;
}

.audioPlayTrackCont {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px;
  background: #f5f5f5;
  z-index: 999999;
  padding: 0px;
  border-top: 0.5px solid #000;
}

.audioPlayTrack {
  position: relative;
}

.audioPlayTrack button.ry {
  position: absolute;
  left: 20px;
  top: 15px;
  background: #f5f5f5;
  border: 0px;
}


/* Customize the appearance of the audio player */
.custom-audio {
  width: 100%;
  /* Adjust as needed */
}

/* Style the default audio controls */
.custom-audio::-webkit-media-controls-panel {
  background-color: #f8f8f8;
  /* Background color */
  color: #333;
  /* Text color */
  border-radius: 0px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-audio::-webkit-media-controls-play-button,
.custom-audio::-webkit-media-controls-pause-button {
  font-size: 20px;
  /* Adjust the icon size */
}

@media only screen and (max-width: 771px) {
  .custom-audio::-webkit-media-controls-timeline {
    width: 90px;
    /* Set the slider width to 100% */
    margin-right: -15px;
    padding-right: 0px;
  }

  /* .custom-audio::-webkit-media-controls-current-time-display, */
  .custom-audio::-webkit-media-controls-time-remaining-display,
  .custom-audio::-webkit-media-controls-mute-button,
  .custom-audio::-webkit-media-controls-volume-slider,
  .custom-audio::-webkit-media-controls-seek-back-button,
  .custom-audio::-webkit-media-controls-seek-forward-button,
  .custom-audio::-webkit-media-controls-toggle-closed-captions-button,
  .custom-audio::-webkit-media-controls-fullscreen-button {
    display: none;
    /* Hide certain controls */
  }
}

/* Style the progress bar */

/* For the Audio Player */
.topMessageClose {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media only screen and (max-width: 771px) {
  .topMessageClose {
    height: 17px;
    width: 17px;
    line-height: 15px;
    font-size: 11px;
    top: 15px;
    right: 7px;
  }
}

.fa-star,
.fa-star-half-alt {
  color: #ffbe00;
}

.viewAllMobLink {
  font-size: 14px;
  border: 1px solid rgb(0, 0, 0);
  padding: 1px 5px;
  border-radius: 50%;
}

.FiltSB label {
  margin-bottom: 0px;
}

.phoneNumberInpCont {
  position: relative;
  height: 40px;
  border: 1px solid #dedede;
  padding-left: 50px;
}

.phoneNumberInpCont input.PhoneInputInput {
  border: 0px solid #dedede;
  width: 100%;
  padding-left: 60px;
}

.phoneNumberInpCont .PhoneInputCountry {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 999;
  background: #f5f5f5;
  min-width: -webkit-max-content;
  min-width: max-content;
  padding: 0px 10px;
}

.phoneNumberInpCont .PhoneInput {
  display: block;
}

a {
  color: #7a3694;
}

a:hover {
  font-weight: bold;
  color: #7a3694;
}

.TitleOneLine {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 1.5em;
}

@media only screen and (max-width: 771px) {
  body {
    padding-bottom: 60px;
  }
}

.slick-track {
  margin-left: initial;
  margin-right: initial;
}

.btn-purple {
  background-color: #7a3694 !important;
  color: #f5f5f5;
}

.btn-purple:hover {
  color: #fff;
}

anew {
  cursor: pointer;
}

.ShowLoaderPage {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 999;
  text-align: center;
  padding-top: 20%;
  background: #fffffff7;
}

.ShowLoaderPage img {
  max-width: 60px;
}

.text-purple {
  color: #7a3694;
}

.LoadingAnimaCourse img {
  max-width: 95px;
}

.LoadingAnimaCourse {
  -webkit-animation: upDown 2s linear infinite;
          animation: upDown 2s linear infinite;
  text-align: center;
}

@-webkit-keyframes upDown {
  0% {
    padding-top: 0;
  }

  50% {
    padding-top: 50px;
  }

  100% {
    padding-top: -50px;
  }
}

@keyframes upDown {
  0% {
    padding-top: 0;
  }

  50% {
    padding-top: 50px;
  }

  100% {
    padding-top: -50px;
  }
}

.MCQsIcon {
  max-width: 20px;
  width: 20px;
  height: 20px;
}

.sjain-text p {
  color: #ffffff;
  font-size: 14px;
}

.sjain-text img {
  width: 45px;
  margin-left: 5px;
}
.text-left {
  text-align: left !important;
}
