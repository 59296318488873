.join_desc {
    text-align: left;
    padding: 20px;
    margin-top: -45px;
    padding-top: 50px;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    border-radius: 5px;
    min-height: 15rem;
}

.ry_instr_shadow {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 10px;
}

.btn-docsta-instructor {
    background: #7F13AB 0% 0% no-repeat padding-box !important;
    color: #f5f5f5 !important;
    display: block !important;
    width: 100%;
}

.ry_label {
    margin-bottom: 0px;
    font-size: 14px;
}

.ry_instr_menu i {
    padding-right: 5px;
}

.ry_instr_menu {
    padding: 7px 20px;
    display: block;
    color: #000000;
}

.ry_instr_menu:hover {
    color: #7F13AB;
    background: #f5f5f5;
}
.ry_instr_menu.active {
    color: #7F13AB;
    background: #f5f5f5;
}

.ry-pull-left {
    float: left;
}

.ry-pull-right {
    float: right;
}

.ry-table-header {
    background: #0C0F50 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}

.instr_tot_cours {
    background: #A34CC9 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 10px #7f13ab;
}

.instr_tot_cours_updt {
    background: #a8d8f2 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 10px #a8d8f2;
}

.instr_tot_cours_updt .icn {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: #11537e;
}

.instr_tot_std {
    background: #aaf7cb 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 10px #aaf7cb;
}

.icn2 {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #2a7d4d;
}

.instr_tot_webi {
    background: #d6b5ef 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 10px #d6b5ef;
}

.icn3 {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #5d2893;
}

.instr_tot_blog {
    background: #ffe4b2 0% 0% no-repeat padding-box;
    /* border-radius: 18px; */
    text-align: center;
    color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 10px #ffe4b2;
}

.icn4 {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: #8b6d29;
}

/*  student dashboard courses */

.student_dashCard{
    display: flex;
    justify-content: space-around;
}

.stu_dash_course .stu_dash_card {
    border-radius: 5px;
    box-shadow: 0px  1px 2px black;
    /* margin: 4px; */
}

.ry_instr_text {
    color: #7f13ab;
}

div.PhoneInputCountry {
    padding-top: 5px;
}

input.PhoneInputInput {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: 0px;
    width: 80%;
    z-index: 888;
}

.instr_signup_head {
    background: #F4F7FF 0% 0% no-repeat padding-box;
    border: 0.5px solid #f5f5f5;
    border-radius: 20px 20px 0px 0px;
}

.instr_signup_head .col-md-4,
.instr_signup_head .col-md-3 {
    position: relative;
}

.instr_signup_head_step {
    border: 1px solid #4C2CD4;
    border-radius: 50%;
    margin: 20px auto;
    width: 70px;
    height: 70px;
    line-height: 65px;
    text-align: center;
}

.instr_signup_head .active {
    background: #4C2CD4 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}

.instr_signup_head .col-md-4::before {
    content: "";
    position: absolute;
    background: #dddddd;
    top: 50px;
    right: -33%;
    width: 66%;
    height: 2px;
}

.instr_signup_head .col-md-4:last-child::before {
    display: none;
}

.instr_signup_head .col-md-3::before {
    content: "";
    position: absolute;
    background: #dddddd;
    top: 50px;
    right: -33%;
    width: 66%;
    height: 2px;
}

.instr_signup_head .col-md-3:last-child::before {
    display: none;
}

.instr_signup_head .col-md-2::before {
    content: "";
    position: absolute;
    background: #dddddd;
    top: 50px;
    right: -33%;
    width: 66%;
    height: 2px;
}

.instr_signup_head .col-md-2:last-child::before {
    display: none;
}

.step_1_img {
    max-width: 250px;
    display: block;
    margin: 0px auto;
    margin-top: 210px;
}

.step_2_img {
    max-width: 250px;
    display: block;
    margin: 0px auto;
    margin-top: 170px;
}

.step_3_img {
    max-width: 250px;
    display: block;
    margin: 0px auto;
    margin-top: 160px;
}

.instr_profile_pics {
    float: right;
    height: 90px;
    width: 90px;
    max-width: 50%;
    object-fit: cover;
    border-radius: 50%;
}

@media only screen and (min-width: 710px) {
    .instr_payput_type {
        display: flex;
        justify-content: space-between;
    }
}

.instr_payput_type button {
    margin-bottom: 10px;
}

.clear_both {
    clear: both;
}

.instr_webi_help {
    place-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
}

.instr_webi_help .i2 {
    margin-left: 5px;
    height: 25px;
}

.instr_webi_help .i1 {
    margin-left: 5px;
    left: 1395px;
    width: 20px;
    height: 20px;
    background: #4C2CD4 0% 0% no-repeat padding-box;
    border-radius: 50%;
    color: #fff;
    line-height: 20px;
    text-align: center;
}

.std_header_on_page {
    color: #7F13AB;
}
.std_db_counted b.text-dark {
    font-size: 12px;
}

.std_db_counted i {
    font-size: 18px;
}